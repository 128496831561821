<div class="album py-5 height-100vh">
  <div class="col-12 text-center">
    <img src="https://www.diana-klinik.de/assets/diana/img/dianaklinik_logo.svg" style="height: 50px; margin-bottom: 30px;" />
    <br>
    <hr>
    <img class="img-fluid" src="assets/img/logo.png"/>
    <div class="row">
      <div style="margin: 0 auto;">
        <span class="fatfont logotext">DIANA</span><span class="logotext">MAPS</span><br />
      </div>
    </div>
    <h4>Geographisches Informationssystem der DianaKlinik Bad Bevensen</h4>


  </div>
  <div class="container p-4">
    <div class="row">

      <div class="col-md-6" (click)="notActive()" style="cursor: pointer;">
        <div class="card mb-4 p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Startseite/dianaklinik-ganzheitlicher-ansatz-startseite-teaser-m.jpg');background-size: cover;">
            <!--<i class="iconfront fal fa-calendar"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-calendar" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Termine
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">12:00 Uhr - Gymnastik II</a></p>
            <p class="card-text text-center"><a class="someLink">13:00 Uhr - Gymnastik III</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-6" (click)="notActive()" style="cursor: pointer;">
        <div class="card mb-4 p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Startseite/dianaklinik-hilfe-zur-selbsthilfe-startseite-teaser-m.jpg');background-size: cover;">
            <!--<i class="iconfront fal fa-utensils"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-bars"></i>&nbsp;&nbsp;&nbsp;Speiseplan
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">Heute</a></p>
            <p class="card-text text-center"><a class="someLink"><b>Hauptgericht:</b> Gefüllte Hackbällchen mit Feta und Spitzpaprika</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-12" (click)="start()" style="cursor: pointer;">
        <div class="card mb-4  p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Willkommen/l02_003-dianaklinik-aufenthalt-von-a-z-header-01-m.jpg');background-size: cover;">
            <!-- <i class="iconfront fal fa-map-marked-alt"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-map"></i>&nbsp;&nbsp;&nbsp;Karte
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">Orte und Wege innerhalb der DianaKlinik</a></p>
            <p class="card-text text-center"><a class="someLink"><i class="fas fa-arrow-right"></i>&nbsp;&nbsp;&nbsp;zu DianaMaps</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-6" (click)="notActive()" style="cursor: pointer;">
        <div class="card mb-4 p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Startseite/Begleitpersonen-und-Selbstzahler/l03_013-dianaklinik-dianamedicalsport-fuer-begleitpersonen-header-01-l.jpg');background-size: cover;">
            <!--<i class="iconfront fal fa-landmark"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-clock"></i>&nbsp;&nbsp;&nbsp;Veranstaltungen
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">24.09.2022 - Skatabend</a></p>
            <p class="card-text text-center"><a class="someLink">25.09.2022 - Musik & Kuchen</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-6" (click)="notActive()" style="cursor: pointer;">
        <div class="card mb-4 p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Startseite/Aufenthalt-von-A-bis-Z/l02_003-dianaklinik-aufenthalt-von-a-z-header-01-l.jpg');background-size: cover;">
            <!--<i class="iconfront fal fa-question"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-question-circle"></i>&nbsp;&nbsp;&nbsp;Hilfe
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">Wie benutze ich die Routenfindung?</a></p>
            <p class="card-text text-center"><a class="someLink">Wo geht es zur Schwimmhalle?</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-12" (click)="login()" style="cursor: pointer;">
        <div class="card mb-4 p-0 w-100 box-shadow rounded">
          <div class="kioskimagecontainer" style="background-image: url('https://www.diana-klinik.de/files/bilder/Files/Ueber-uns/Natur/l02-011c-dianaklinik-leitbild-natur-header-01-l.jpg');background-size: cover;">
            <!--<i class="iconfront fal fa-question"></i>-->
          </div>
          <div class="text-center kioskcaption">
            <h1>
              <span class="someSpan">
                <i class="fas fa-lock"></i>&nbsp;&nbsp;&nbsp;Administration
              </span>
            </h1>
          </div>
          <div class="card-body">
            <p class="card-text text-center"><a class="someLink">Startseite</a></p>
            <p class="card-text text-center"><a class="someLink">Netzwerk</a></p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
