import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@app/core/services/config.service';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('RestService');

@Injectable()
export class RestService {

  constructor(private httpClient: HttpClient) { }

  getSearchSuggestionArray() {
    log.debug('rest api: getSearchSuggestionArray');
    const requestURL = ConfigService.restBaseUrl + '/search/suggestions.php';
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getSearchSuggestionArray data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getSearchSuggestionArray error', error);
        reject(error);
      });
    });
  }

  getSearchData(searchInput: string, filterBy: string, sortBy: string, floor: string, userrole: string) {
    log.debug('rest api: getSearchData searchInput', searchInput);
    const requestURL = ConfigService.restBaseUrl + '/search/searchengine.php?search=' + searchInput + '&filter=' + filterBy + '&sort=' + sortBy + '&floor=' + floor + '&role=' + userrole;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getSearchData data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getSearchData error', error);
        reject(error);
      });
    });
  }

  getAllRoomNameFeaturesForInputTable(input_table: object) {
    log.debug('rest api: getSearchData input_table', input_table);
    const requestURL = ConfigService.restBaseUrl + '/gis/gethouseroomtext.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, input_table).subscribe(data => {
        log.debug('rest api: getAllRoomNameFeaturesForInputTable data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getAllRoomNameFeaturesForInputTable error', error);
        reject(error);
      });
    });
  }

  getCategoriesAndColors() {
    log.debug('rest api: getCategoriesAndColors');
    const requestURL = ConfigService.restBaseUrl + '/gis/getcatandcolor.php';
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getAllRoomNameFeaturesForInputTable data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getAllRoomNameFeaturesForInputTable error', error);
        reject(error);
      });
    });
  }

  checkAmountOfChanges(postData: any) {
    log.debug('rest api: checkChanges');
    const requestURL = ConfigService.restBaseUrl + '/gis/checkchangesamount.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, postData).subscribe((data) => {
        log.debug('rest api: check changes', data);
        resolve(data);
      }, error => {
        log.debug('rest api: check changes', error);
        reject(error);
      });
    });
  }

  updateCategoriesAndColors(postData: any) {
    log.debug('rest api: updateCategoriesAndColors');
    const requestURL = ConfigService.restBaseUrl + '/gis/updatecatandcolor.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, postData).subscribe((data) => {
        log.debug('rest api: update CAT data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: update CAT error', error);
        reject(error);
      });
    });
  }

  deleteCategoriesAndColors(postData: any) {
    log.debug('rest api: updateCategoriesAndColors');
    const requestURL = ConfigService.restBaseUrl + '/gis/delcatandcolor.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, postData).subscribe((data) => {
        log.debug('rest api: delete CAT data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: delete CAT error', error);
        reject(error);
      });
    });
  }

  sendWFSTRequest(xml: any) {
    log.debug('rest api: sendWFSTRequest XML', xml);
    const requestURL = ConfigService.geoServerUrl + '/geoserver/Diana/ows?service=WFS&version=1.0.0';
    return new Promise((resolve, reject) => {
      this.httpClient.disableApiPrefix().post(requestURL, xml, {responseType: 'text'}).subscribe(data => {
        log.debug('rest api: sendWFSTRequest data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: sendWFSTRequest error', error);
        reject(error);
      });
    });
  }

  getPolyDataGeoServer(requestURL: string) {
    log.debug('rest api: getPolyDataGeoServer requestURL', requestURL);
    return new Promise((resolve, reject) => {
      this.httpClient.disableApiPrefix().get(requestURL).subscribe(data => {
        log.debug('rest api: getPolyDataGeoServer data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getPolyDataGeoServer error', error);
        reject(error);
      });
    });
  }

  updateRoomBookData(postData: object) {
    log.debug('rest api: updateRoomBookData postData', postData);
    const requestURL = ConfigService.restBaseUrl + '/gis/enterroomid.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, postData).subscribe((data) => {
        log.debug('rest api: updateRoomBookData data', data);
        resolve(data);
      }, (error) => {
        log.debug('rest api: updateRoomBookData error', error);
        reject(error);
      });
    });
  }

  updateRouteData(postIDs: object, postData: object) {
    log.debug('rest api: updateRouteData postData', postData);
    const requestURL = ConfigService.restBaseUrl + '/routing/edit_routesegment.php';
    const postObj = {};
    postObj['ids'] = postIDs;
    postObj['attribs'] = postData;
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, postObj).subscribe((data) => {
        log.debug('rest api: updateRouteData data', data);
        resolve(data);
      }, (error) => {
        log.debug('rest api: updateRouteData error', error);
        reject(error);
      });
    });
  }

  getRoomPolyData(inputTable: string) {
    const requestURL = ConfigService.restBaseUrl + '/gis/getroommultipolygon.php?table=' + inputTable;
    log.debug('rest api: getRoomPolyData requestURL', requestURL);
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getRoomPolyData data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoomPolyData error', error);
        reject(error);
      });
    });
  }

  getRoomsByDistrictOrStatus(inputData: object) {
    log.debug('rest api: getRoomsByDistrictOrStatus inputData', inputData);
    const requestURL = ConfigService.restBaseUrl + '/gis/getdistrictcleaningstatus.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, inputData).subscribe(data => {
        log.debug('rest api: getRoomsByDistrictOrStatus data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoomsByDistrictOrStatus error', error);
        reject(error);
      });
    });
  }

  setRoomCleaningStatus(dataObject: object) {
    log.debug('rest api: setRoomCleaningStatus dataObject', dataObject);
    const requestURL = ConfigService.restBaseUrl + '/gis/entercleaningstatus.php';
    return new Promise((resolve, reject) => {
      this.httpClient.post(requestURL, dataObject).subscribe(data => {
        log.debug('rest api: setRoomCleaningStatus data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: setRoomCleaningStatus error', error);
        reject(error);
      });
    });
  }

  login(loginObject: object) {
    log.debug('rest api: loginOBj', loginObject);
    const requestURL = ConfigService.restBaseUrl + '/ad/auth_login.php';
    return new Promise((resolve, reject) => {
      this.httpClient.disableApiPrefix().post(requestURL, loginObject).subscribe(data => {
        log.debug('rest api: logindata', data);
        resolve(data);
      }, error => {
        log.debug('rest api: login error', error);
        reject(error);
      });
    });
  }

  getRoombookData(sortBy: string, floor: string, onlyempty: boolean) {
    log.debug('rest api: getRoombook Data');
    const requestURL = ConfigService.restBaseUrl + '/gis/getroombookdata.php?sort=' + sortBy + '&floor=' + floor + '&empty=' + onlyempty;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getRoombook data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoombook error', error);
        reject(error);
      });
    });
  }

  getRoomByRoomNumber(roomnumber: string) {
    log.debug('rest api: getRoomByRoomNumber Data');
    const requestURL = ConfigService.restBaseUrl + '/gis/get_user_room.php?roomnumber=' + roomnumber;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getRoomByRoomNumber data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoomByRoomNumber error', error);
        reject(error);
      });
    });
  }

  getBSSIDPosition(BSSID: string) {
    log.debug('rest api: getBSSID Data');
    const requestURL = ConfigService.restBaseUrl + '/gis/get_BSSID_position.php?bssid=' + BSSID;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getBSSID data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getBSSID error', error);
        reject(error);
      });
    });
  }

  getRoute(startxcoord: any, startycoord: any, startfloor: any, endxcoord: any, endycoord: any, endfloor: any, accessible: any, route_public: any, role: any) {
    log.debug('rest api: getRoute Data');
    const requestURL = ConfigService.restBaseUrl + '/routing/get_route_simple.php?startxcoord=' + startxcoord + '&startycoord=' + startycoord + '&startfloor=' + startfloor + '&endxcoord=' + endxcoord + '&endycoord=' + endycoord + '&endfloor=' + endfloor + '&accessible=' + accessible + '&public=' + route_public + '&role=' + role;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getRoomByRoomNumber data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoomByRoomNumber error', error);
        reject(error);
      });
    });
  }

  getLiveRoute(startxcoord: any, startycoord: any, startfloor: any, endxcoord: any, endycoord: any, endfloor: any, accessible: any, route_public: any, role: any) {
    log.debug('rest api: getRoute Data');
    const requestURL = ConfigService.restBaseUrl + '/routing/get_route_live.php?startxcoord=' + startxcoord + '&startycoord=' + startycoord + '&startfloor=' + startfloor + '&endxcoord=' + endxcoord + '&endycoord=' + endycoord + '&endfloor=' + endfloor + '&accessible=' + accessible + '&public=' + route_public + '&role=' + role;
    return new Promise((resolve, reject) => {
      this.httpClient.get(requestURL).subscribe(data => {
        log.debug('rest api: getRoomByRoomNumber data', data);
        resolve(data);
      }, error => {
        log.debug('rest api: getRoomByRoomNumber error', error);
        reject(error);
      });
    });
  }

}
