import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // request = request.clone({ url: environment.serverUrl + request.url });
    const currentUser = JSON.parse(localStorage.getItem('credentials'));
    if (currentUser != null) {
      if (currentUser.token) {
        request = request.clone({
          url: environment.serverUrl + request.url,
          setHeaders: {
            token: currentUser.token
          }
        });
      }
    } else {
      request = request.clone({ url: environment.serverUrl + request.url });
    }

    return next.handle(request);
  }

}
