import { Component, OnInit } from '@angular/core';
import * as Sentry from '@sentry/browser';
import {MapService} from '@app/core/services/map.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {

  constructor(public mapService: MapService, public activeModal: NgbActiveModal, private toast: ToastrService) {}

  ngOnInit() {
  }

  SentryreportRoute() {
    Sentry.captureMessage('Falsche Route durch Benutzer gemeldet: Start=' + this.mapService.NaviStart  + ',Etage:' + this.mapService.NaviStartFloor + ' | Ende=' + this.mapService.NaviEnd + ',Etage:' + this.mapService.NaviEndFloor + ' | Barrierefrei =' +  this.mapService.show_routeAccessible);
    this.activeModal.dismiss();
    this.toast.success('Route wurde an das Support Team gemeldet', '', {
      positionClass: 'toast-bottom-left',
    });
  }
}
