import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler, Injectable} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Keyboard} from '@ionic-native/keyboard';
import {StatusBar} from '@ionic-native/status-bar';
import {SplashScreen} from '@ionic-native/splash-screen';
import {AuthenticationService, CoreModule} from '@app/core';
import {SharedModule} from '@app/shared';
import {LoginModule} from './core/pages/login/login.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MapService} from '@app/core/services/map.service';
import {RestService} from '@app/core/services/rest.service';
import {ToastrModule} from 'ngx-toastr';
import {JoyrideModule} from 'ngx-joyride';
import * as Sentry from '@sentry/browser';
import {environment} from '@env/environment';
import {gitTag} from '@env/git-commit-id';
import {MainModule} from '@app/core/pages/main/main.module';
/*
Sentry.init({
  release: 'visgato_DianaMaps@' + environment.version,
  dsn: 'https://b42e229d52524062956b5fabf72224f8@sentry.io/1507723'
});

Sentry.configureScope((scope) => {
  scope.setTag('git.commit', gitTag);
});
*/
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error: any) {
    const eventId = Sentry.captureException(error.originalError || error);
    if (!environment.production) {
      console.log(error);
    }
    Sentry.showReportDialog({eventId, title: 'Hoppla, ein Fehler ist aufgetreten'});
    throw error;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule.forRoot(),
    CoreModule,
    SharedModule,
    LoginModule,
    MainModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    JoyrideModule.forRoot(),
    ToastrModule.forRoot(
      {
        timeOut: 4000,
        positionClass: 'toast-bottom-left',
        preventDuplicates: false
      }
    )
  ],
  declarations: [AppComponent],
  providers: [
    Keyboard,
    StatusBar,
    SplashScreen,
    MapService,
    AuthenticationService,
    RestService,
    {provide: ErrorHandler, useClass: SentryErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
