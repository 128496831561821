import { NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { I18nService, AuthenticationService } from 'app/core/index';
import { Logger } from '@app/core/services/logger/logger.service';
import { ToastrService } from 'ngx-toastr';
import { Keyboard } from '@ionic-native/keyboard';
import { StatusBar } from '@ionic-native/status-bar';
import { SplashScreen } from '@ionic-native/splash-screen';
import { MapService } from '@app/core/services/map.service';
var log = new Logger('LoginComponent');
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, formBuilder, i18nService, authenticationService, toast, zone, keyboard, statusBar, splashScreen, mapService) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.i18nService = i18nService;
        this.authenticationService = authenticationService;
        this.toast = toast;
        this.zone = zone;
        this.keyboard = keyboard;
        this.statusBar = statusBar;
        this.splashScreen = splashScreen;
        this.mapService = mapService;
        this.version = environment.version;
        this.isLoading = false;
        this.isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
        this.missingIntent = false;
        this.createForm();
    }
    LoginComponent.prototype.ngOnInit = function () {
        // document.addEventListener('deviceready', () => {
        //   this.zone.run(() => this.onCordovaReady());
        // }, false);
        /* WENN SCHON EINGELOGGT UND NAVIGIEREN ZU ROOT ODER LOGIN SCHICKT WIEDER AUF MAP. ERGO UM BENUTZER ZU WECHSELN MUSS LOGOUT BETÄTIGT WERDEN
        if (this.authenticationService.isAuthenticated()) {
          log.debug('Already authenticated, redirecting...');
          this.router.navigate(['/map'], {replaceUrl: true});
        }
        */
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.authenticationService.login(this.loginForm.value, '', '', '').subscribe(function (credentials) {
            _this.toast.success('Sie wurden in DianaMaps als Benutzer ' + credentials.username + ' angemeldet.', 'Anmeldung erfolgreich:'); // toast #DK-103
            log.debug(credentials + " successfully logged in");
        }, function (error) {
            log.debug('failed to login');
            _this.toast.error('Überprüfen Sie Ihren Benutzernamen und Ihr Kennwort. Wenn Sie sich weiterhin nicht anmelden können, wenden Sie sich an einen Administrator.', 'Anmeldung fehlgeschlagen:'); // toast #DK-103
        });
    };
    LoginComponent.prototype.setLanguage = function (language) {
        this.i18nService.language = language;
    };
    Object.defineProperty(LoginComponent.prototype, "currentLanguage", {
        get: function () {
            return this.i18nService.language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "languages", {
        get: function () {
            return this.i18nService.supportedLanguages;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.createForm = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    };
    LoginComponent.prototype.onCordovaReady = function () {
        // if (window['cordova']) {
        //   log.debug('cordova device ready');
        //   this.keyboard.hideKeyboardAccessoryBar(true);
        //   // this.statusBar.styleDefault();
        //   this.statusBar.overlaysWebView(true);
        //   this.statusBar.backgroundColorByHexString('#32000000');
        //   this.splashScreen.hide();
        //
        //   // sentry die deviceid geben
        //   const device = (<any> window).device;
        //   Sentry.configureScope((scope) => {
        //     scope.setUser({'id': device.uuid});
        //     scope.setTag('device.uuid', device.uuid);
        //     scope.setTag('cordova.version', device.cordova);
        //     scope.setTag('device.platform', device.platform);
        //     scope.setTag('device.serial', device.serial);
        //     scope.setTag('device.version', device.version);
        //   });
        //
        //   // #DK-89
        //   (<any> window).plugins.intent.getCordovaIntent((intent) => {
        //     log.debug('dianamaps wurde mit intent gestartet', intent);
        //     if (intent.extras && intent.extras.username) { // type hier einfügen
        //       this.proccessIntent(intent);
        //       this.checktutorialstatus();
        //     } else {
        //       Sentry.captureException('Die Anwendung wurde auf einem mobilen Endgerät ohne den notwendigen Intent gestartet!');
        //       this.toast.error('Die App wurde ohne notwendige Intents bzw. Parameter gestartet!', 'Problem', {
        //         positionClass: 'toast-center-center',
        //       });
        //       this.missingIntent = true;
        //     }
        //   }, (error) => {
        //     log.debug('cordova-plugin-intent fehler', error);
        //   });
        //
        //   (<any> window).plugins.intent.setNewIntentHandler((intent) => {
        //     log.debug('dianamaps aktiv neu eingehender intent', intent);
        //     if (intent.extras && intent.extras.username) { // auf type stellen   typen: start , behandlungsplan, ...
        //       // this.proccessIntent(intent); //intent wird in v1.1 nicht verarbeitet , wenn app schon offen ist
        //     }
        //   });
        //
        // }
    };
    LoginComponent.prototype.proccessIntent = function (intent) {
        // log.debug('intent extras', intent.extras);
        // const paramter: DianaMapsIntentDetails = intent.extras;
        // log.debug('intent username', paramter.username);
        // log.debug('intent role', paramter.role);
        // log.debug('intent patientroom', paramter.patientroom);
        // const cordovaLoginContext = 'cordova';
        // Sentry.configureScope((scope) => {
        //   scope.setTag('login.username', paramter.username);
        //   scope.setTag('login.role', paramter.role);
        //   scope.setTag('login.patientroom', paramter.patientroom);
        // });
        // this.mapService.user_room = paramter.patientroom;
        // this.authenticationService.login(cordovaLoginContext, paramter.username, paramter.patientroom, paramter.role);
    };
    LoginComponent.prototype.checktutorialstatus = function () {
        // #DK-114 tutorial nur beim ersten starten der app
        var tutorial_done = window.localStorage.getItem('tutorial_done');
        // wenn tutorial_done nicht gesezt, zeige tutorial und setze tutorial_done direkt auf true,
        // so dass das tutorial nie wieder angezeigt wird, bis zum rücksetzen der app (neuer patient)
        if (!tutorial_done) {
            this.mapService.tutorialActive = true;
            localStorage.setItem('tutorial_done', 'true');
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
