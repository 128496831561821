
<!--<div *ngIf="poiCategory == 'Freizeit'" class="bg-freizeit poicategorycontainer"></div>-->
<!--<div *ngIf="poiCategory == 'Pflege'" class="bg-pflege poicategorycontainer"></div>-->
<!--<div *ngIf="poiCategory == 'Therapie'" class="bg-therapie poicategorycontainer"></div>-->
<!--<div *ngIf="poiCategory == 'Eingänge & Ausgänge'" class="bg-eingang-ausgang poicategorycontainer"></div>-->
<!--<div *ngIf="poiCategory == 'Treppen & Aufzüge'" class="bg-treppen-aufzug poicategorycontainer"></div>-->

<div class="roomimagecontainer"  @s_background_imageAnim>
  <img class="header-image" [src]="poiImageURL" alt="" />
</div>
<div class="roominfo-text" @s_background_textAnim>
  <div class="title-room roomtext">{{poiName}}</div>
  <div class="title-number roomtext">{{poiCategory}}</div>
</div>

<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="row no-gutters">
        <div class="col-12">
          <h5><i class="fal fa-map-marker-alt"></i> Point of Interest</h5>
        </div>
      </div>
      <form>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">Name</label>
          </div>
          <div class="col-6 mb-2">
            <input name="poiName" type="text" rows="2" class="form-control" [(ngModel)]="poiName" placeholder="{{poiName}}" value="{{poiName}}" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">Beschreibung</label>
          </div>
          <div class="col-6 mb-2">
            <input name="poiDetails" type="text" rows="2" class="form-control" [(ngModel)]="poiDetails" placeholder="{{poiDetails}}" value="{{poiDetails}}" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">Kategorie</label>
          </div>
          <div ngbDropdown ngbDropdownReposition class="d-inline-block col-6 mb-2" >
            <button class="btn btn-dropdown" id="dropdown-1" ngbDropdownToggle>{{poiCategory}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdown-1">
              <button class="dropdown-item" (click)="setCategory('Freizeit')">Freizeit</button>
              <button class="dropdown-item" (click)="setCategory('Pflege')">Pflege</button>
              <button class="dropdown-item" (click)="setCategory('Therapie')">Therapie</button>
              <button class="dropdown-item" (click)="setCategory('Eingänge & Ausgänge')">Eingänge & Ausgänge</button>
              <button class="dropdown-item" (click)="setCategory('Treppen & Aufzüge')">Treppen & Aufzüge</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingIcon }">Symbolik auf der Karte</label>
          </div>
        </div>

        <div class="input-group">
          <button class="btn rounded2 btn-icon poi-icon-bett" (click)="selectIcon('bett')" [ngClass]="{'blue-marker': poiSelectedIconName == 'bett'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-doc" (click)="selectIcon('doc')" [ngClass]="{'blue-marker': poiSelectedIconName == 'doc'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-door" (click)="selectIcon('door')" [ngClass]="{'blue-marker': poiSelectedIconName == 'door'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-drop" (click)="selectIcon('drop')" [ngClass]="{'blue-marker': poiSelectedIconName == 'drop'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-elevator" (click)="selectIcon('elevator')" [ngClass]="{'blue-marker': poiSelectedIconName == 'elevator'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-medical" (click)="selectIcon('medical')" [ngClass]="{'blue-marker': poiSelectedIconName == 'medical'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-parkplatz" (click)="selectIcon('parkplatz')" [ngClass]="{'blue-marker': poiSelectedIconName == 'parkplatz'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-ramp" (click)="selectIcon('ramp')" [ngClass]="{'blue-marker': poiSelectedIconName == 'ramp'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-toilettenm" (click)="selectIcon('toilettenm')" [ngClass]="{'blue-marker': poiSelectedIconName == 'toilettenm'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-toilettenw" (click)="selectIcon('toilettenw')" [ngClass]="{'blue-marker': poiSelectedIconName == 'toilettenw'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-treppe" (click)="selectIcon('treppe')" [ngClass]="{'blue-marker': poiSelectedIconName == 'treppe'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-tresen" (click)="selectIcon('tresen')" [ngClass]="{'blue-marker': poiSelectedIconName == 'tresen'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-food" (click)="selectIcon('food')" [ngClass]="{'blue-marker': poiSelectedIconName == 'food'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-lab" (click)="selectIcon('lab')" [ngClass]="{'blue-marker': poiSelectedIconName == 'lab'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-pills" (click)="selectIcon('pills')" [ngClass]="{'blue-marker': poiSelectedIconName == 'pills'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-swimming" (click)="selectIcon('swimming')" [ngClass]="{'blue-marker': poiSelectedIconName == 'swimming'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-syringe" (click)="selectIcon('syringe')" [ngClass]="{'blue-marker': poiSelectedIconName == 'syringe'}"
                  type="button"></button>
          <button class="btn rounded2 btn-icon poi-icon-bathtub" (click)="selectIcon('bathtub')" [ngClass]="{'blue-marker': poiSelectedIconName == 'bathtub'}"
                  type="button"></button>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <button type="button" class="w-100 btn rounded btn-sm btn-secondary" (click)="close();">Abbrechen</button>
          </div>
          <div class="col-6">
            <button type="button" class="w-100 btn rounded btn-sm btn-primary" (click)="save()">Speichern</button>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>
