import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from './services/authentication/authentication.service';
import {AuthenticationGuard} from './services/authentication/authentication.guard';
import {I18nService} from './services/i18n/i18n.service';
import {HttpService} from './services/http/http.service';
import {HttpCacheService} from './services/http/http-cache.service';
import {ApiPrefixInterceptor} from './services/http/api-prefix.interceptor';
import {ErrorHandlerInterceptor} from './services/http/error-handler.interceptor';
import {CacheInterceptor} from './services/http/cache.interceptor';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@app/shared';
import {MapComponent} from '@app/core/pages/map/map.component';
import {MapHeaderComponent} from '@app/core/pages/map/map-header/map-header.component';
import {MapLayerSettingComponent} from '@app/core/pages/map/map-info-window/map-layer-setting/map-layer-setting.component';
import {MapInfoWindowComponent} from '@app/core/pages/map/map-info-window/map-info-window.component';
import {MapPoiContextMenuComponent} from '@app/core/pages/map/map-poi-context-menu/map-poi-context-menu.component';
import {MapViewComponent} from '@app/core/pages/map/map-view/map-view.component';
import {RoomNameInfoComponent} from '@app/core/pages/map/map-info-window/room-name-info/room-name-info.component';
import {CleaningStatusWindowComponent} from '@app/core/pages/map/map-info-window/cleaning-status-window/cleaning-status-window.component';
import {MapFloorLayerSwitchComponent} from '@app/core/pages/map/map-floor-layer-switch/map-floor-layer-switch.component';
import {RoomBookInfoWindowComponent} from '@app/core/pages/map/map-info-window/room-book-info-window/room-book-info-window.component';
import {RoomBookInfoWindowAdminComponent} from '@app/core/pages/map/map-info-window/room-book-info-window-admin/room-book-info-window-admin.component';
import {PoiCategorySwitchWidgetComponent} from '@app/core/pages/map/map-info-window/poi-category-switch-widget/poi-category-switch-widget.component';
import {MapSearchResultComponent} from '@app/core/pages/map/map-info-window/map-search-result/map-search-result.component';
import {PoiInfoWindowAdminComponent} from '@app/core/pages/map/map-info-window/poi-info-window-admin/poi-info-window-admin.component';
import {PoiInfoWindowComponent} from '@app/core/pages/map/map-info-window/poi-info-window/poi-info-window.component';
import {WelcomeWidgetComponent} from '@app/core/pages/map/map-info-window/welcome-widget/welcome-widget.component';
import {WelcomeWidgetCleanerComponent} from '@app/core/pages/map/map-info-window/welcome-widget-cleaner/welcome-widget-cleaner.component';
import {CreatePoiModalComponent} from '@app/core/pages/map/modals/create-poi-modal/create-poi-modal.component';
import {ConfirmModalComponent} from '@app/core/pages/map/modals/confirm-modal/confirm-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MapLoadingComponent} from '@app/core/pages/map/map-loading/map-loading.component';
import {RoutingWidgetComponent} from '@app/core/pages/map/map-info-window/routing-widget/routing-widget.component';
import {MapRoombookWindowComponent} from '@app/core/pages/map/map-roombook-window/map-roombook-window.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {WelcomeWidgetAdminComponent} from '@app/core/pages/map/map-info-window/welcome-widget-admin/welcome-widget-admin.component';
import {PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {ToastrModule} from 'ngx-toastr';
import {MapControlsComponent} from '@app/core/pages/map/map-controls/map-controls.component';
import { SettingsInfoWindowAdminComponent } from './pages/map/map-info-window/settings-info-window-admin/settings-info-window-admin.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MapTutorialComponent } from './pages/map/map-tutorial/map-tutorial.component';
import {JoyrideModule} from 'ngx-joyride';
import { RouteEditWidgetComponent } from './pages/map/map-info-window/route-edit-widget/route-edit-widget.component';
import { PoiCategorySwitchWidgetPatientComponent } from './pages/map/poi-category-switch-widget-patient/poi-category-switch-widget-patient.component';
import { ReportModalComponent } from './pages/map/modals/report-modal/report-modal.component';
import { MapLiveNavigationComponent } from './pages/map/map-live-navigation/map-live-navigation.component';
import { CreateBeaconModalComponent } from './pages/map/modals/create-beacon-modal/create-beacon-modal.component';
import { BeaconInfoWindowAdminComponent } from './pages/map/map-info-window/beacon-info-window-admin/beacon-info-window-admin.component';
import { MapStreetviewComponent } from './pages/map/map-streetview/map-streetview.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    FormsModule,
    SharedModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    ColorPickerModule,
    ToastrModule.forRoot(),
    JoyrideModule.forRoot()
  ],
  declarations: [
    MapComponent,
    MapHeaderComponent,
    MapLayerSettingComponent,
    MapInfoWindowComponent,
    MapRoombookWindowComponent,
    MapPoiContextMenuComponent,
    MapViewComponent,
    RoomNameInfoComponent,
    CleaningStatusWindowComponent,
    MapFloorLayerSwitchComponent,
    RoomBookInfoWindowComponent,
    RoomBookInfoWindowAdminComponent,
    PoiCategorySwitchWidgetComponent,
    MapSearchResultComponent,
    PoiInfoWindowAdminComponent,
    PoiInfoWindowComponent,
    WelcomeWidgetComponent,
    WelcomeWidgetAdminComponent,
    WelcomeWidgetCleanerComponent,
    CreatePoiModalComponent,
    CreateBeaconModalComponent,
    ConfirmModalComponent,
    MapLoadingComponent,
    RoutingWidgetComponent,
    MapControlsComponent,
    SettingsInfoWindowAdminComponent,
    MapTutorialComponent,
    RouteEditWidgetComponent,
    PoiCategorySwitchWidgetPatientComponent,
    ReportModalComponent,
    MapLiveNavigationComponent,
    CreateBeaconModalComponent,
    BeaconInfoWindowAdminComponent,
    MapStreetviewComponent
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [CreateBeaconModalComponent, CreatePoiModalComponent, ConfirmModalComponent, ReportModalComponent]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
