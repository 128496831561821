/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-poi-context-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map-poi-context-menu.component";
import * as i3 from "../../../services/map.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var styles_MapPoiContextMenuComponent = [i0.styles];
var RenderType_MapPoiContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapPoiContextMenuComponent, data: {} });
export { RenderType_MapPoiContextMenuComponent as RenderType_MapPoiContextMenuComponent };
export function View_MapPoiContextMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 43, "div", [["class", "contextmenu"]], [[4, "left", "px"], [4, "top", "px"], [8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 40, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Points of Interest\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 13, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCreatePoiModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fal fa-map-marker-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" POI ERSTELLEN\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCreateBeaconModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-wifi"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ACCESSPOINT ERSTELLEN\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(22, 0, null, null, 11, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editPOI() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fal fa-pencil-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" POI BEARBEITEN\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "div", [["class", "selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePOI() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["class", "fal fa-trash-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" POI L\u00D6SCHEN\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(35, 0, null, null, 6, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(37, 0, null, null, 3, "div", [["class", "selection"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteBeacon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(39, 0, null, null, 0, "i", [["class", "fal fa-trash-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ACCESSPOINT L\u00D6SCHEN\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mapService.contextmenuLeft; var currVal_1 = _co.mapService.contextmenuTop; var currVal_2 = !_co.mapService.contextMenuOpen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.mapService.contextMenuEdit || _co.mapService.contextMenuEditBeacon); _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.mapService.contextMenuEdit; _ck(_v, 22, 0, currVal_4); var currVal_5 = !_co.mapService.contextMenuEditBeacon; _ck(_v, 35, 0, currVal_5); }); }
export function View_MapPoiContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-poi-context-menu", [], null, null, null, View_MapPoiContextMenuComponent_0, RenderType_MapPoiContextMenuComponent)), i1.ɵdid(1, 49152, null, 0, i2.MapPoiContextMenuComponent, [i3.MapService, i4.NgbModal], null, null)], null, null); }
var MapPoiContextMenuComponentNgFactory = i1.ɵccf("map-poi-context-menu", i2.MapPoiContextMenuComponent, View_MapPoiContextMenuComponent_Host_0, {}, {}, []);
export { MapPoiContextMenuComponentNgFactory as MapPoiContextMenuComponentNgFactory };
