import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        // Import guard
        if (parentModule) {
            throw new Error(parentModule + " has already been loaded. Import Core module in the AppModule only.");
        }
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };
