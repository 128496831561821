import { Component, OnInit, OnDestroy } from '@angular/core';
import WFS from 'ol/format/WFS';
import {MapService} from '@app/core/services/map.service';
import {RestService} from '@app/core/services/rest.service';
import Feature from 'ol/Feature';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'beacon-info-window-admin',
  templateUrl: './beacon-info-window-admin.component.html',
  styleUrls: ['./beacon-info-window-admin.component.scss']
})
export class BeaconInfoWindowAdminComponent implements OnInit, OnDestroy {

  beaconSubscription: any;
  beaconFeature: Feature;

  name: string;
  BSSID1: string;
  BSSID2: string;
  BSSID3: string;
  BSSID4: string;
  TXPower: string;
  inactive: boolean;

  showErrorMissingIcon: any = false;
  poiImageURL: any;

  constructor(public mapService: MapService, public restService: RestService, private toast: ToastrService) {  }

  ngOnInit() {
    this.beaconSubscription = this.mapService.clickedBeaconFeatureSubject.subscribe((feature: Feature) => {
      if (feature != null) {
        this.beaconFeature = feature;
        this.name = feature.get('name');
        this.BSSID1 = feature.get('BSSID1');
        this.BSSID2 = feature.get('BSSID2');
        this.BSSID3 = feature.get('BSSID3');
        this.BSSID4 = feature.get('BSSID4');
        this.TXPower = feature.get('TXPower');
        this.inactive = feature.get('inactive');
      }
    });
  }

  ngOnDestroy(): void {
    this.beaconSubscription.unsubscribe();
  }

  save() {
    this.beaconFeature.set('name', this.name);
    this.beaconFeature.set('BSSID1', this.BSSID1);
    this.beaconFeature.set('BSSID2', this.BSSID2);
    this.beaconFeature.set('BSSID3', this.BSSID3);
    this.beaconFeature.set('BSSID4', this.BSSID4);
    this.beaconFeature.set('TXPower', this.TXPower);
    this.beaconFeature.set('inactive', this.inactive);
    this.beaconFeature.setGeometryName('geom');
    // this.poiFeature.set('table', this.map);
    // this.poiFeature.set('rb_ebene', this.activeFloorNumber);
    this.beaconFeature.unset('type');

    console.log('upadting poi feature2', this.beaconFeature);

    const format = new WFS({});
    const node = format.writeTransaction(null, [this.beaconFeature], null, {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'beacons',
      srsName: 'EPSG:3857',
      nativeElements: []
    });

    const xmlSerializer = new XMLSerializer();
    this.restService.sendWFSTRequest(xmlSerializer.serializeToString(node)).then((data) => {
      this.mapService.reloadSearchSuggestions();
      console.log('update beacon success');
      this.mapService.redrawBeacons();
      this.toast.success('AccessPoint erfolgreich aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    }, (error) => {
      console.log('upadting beacon', error.message);
      this.toast.error('AccessPoint wurde nicht aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    });

  }

  close() {
    this.mapService.infowindowhidden = true;
    this.mapService.searchbaractive = false;
  }

}
