/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-tutorial.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/carousel/carousel.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import * as i4 from "@ng-bootstrap/ng-bootstrap/carousel/carousel-config";
import * as i5 from "./map-tutorial.component";
import * as i6 from "../../../services/map.service";
import * as i7 from "ngx-joyride";
var styles_MapTutorialComponent = [i0.styles];
var RenderType_MapTutorialComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapTutorialComponent, data: {} });
export { RenderType_MapTutorialComponent as RenderType_MapTutorialComponent };
function View_MapTutorialComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "onboarding-slide text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "onboarding-text container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Diana Maps "])), (_l()(), i1.ɵted(-1, null, ["\n          Herzlich Wilkommen im Geographischen Informationssystem."])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          \u00DCber diese Anwendung k\u00F6nnen Sie die digitale Karten der DianaKlinik einsehen und interessante Orte entdecken.\n          "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-12 text-center onboarding-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn onboarding-button col-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Weiter"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "onboarding-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["class", "img-fluid tutorial-img"], ["src", "assets/img/welcome1.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_MapTutorialComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "onboarding-slide text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "onboarding-text container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Entdecken "])), (_l()(), i1.ɵted(-1, null, ["\n            Entdecken Sie Einrichtungen, Abteilungen, R\u00E4ume oder interessante Orte der DianaKlinik.\n          "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-12 text-center onboarding-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn onboarding-button col-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Weiter"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "onboarding-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["class", "img-fluid tutorial-img"], ["src", "assets/img/entdecken1.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_MapTutorialComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "onboarding-slide text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "onboarding-text container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Finden "])), (_l()(), i1.ɵted(-1, null, ["\n          Finden Sie die k\u00FCrzesten Weg \u00FCber das gesamte Gel\u00E4nde. Steuern Sie schneller zu Ihren Behandlungsorten.\n          "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-12 text-center onboarding-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn onboarding-button col-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Weiter"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "onboarding-image "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["class", "img-fluid tutorial-img"], ["src", "assets/img/routen1.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_MapTutorialComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "onboarding-slide text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "onboarding-text container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Eigener Standort "])), (_l()(), i1.ɵted(-1, null, ["\n          Lassen Sie sich lokalisieren und sehen Ihren aktuellen Standort auf der Karte.\n          "])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "col-12 text-center onboarding-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn onboarding-button btn-lg col-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startTour() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Funktionen ansehen"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn onboarding-skip-button btn-lg col-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTour() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ich kenne mich aus"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "onboarding-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "img", [["class", "img-fluid tutorial-img"], ["src", "assets/img/posi1.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_MapTutorialComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { carousel: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "tutorial"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 15, "ngb-carousel", [["class", "carousel slide"], ["tabIndex", "0"]], [[4, "display", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "keydown.arrowLeft"], [null, "keydown.arrowRight"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("keydown.arrowLeft" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).keyPrev() !== false);
        ad = (pd_2 && ad);
    } if (("keydown.arrowRight" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).keyNext() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NgbCarousel_0, i2.RenderType_NgbCarousel)), i1.ɵdid(5, 2867200, [[1, 4], ["onboardingcarousel", 4]], 1, i3.NgbCarousel, [i4.NgbCarouselConfig], null, null), i1.ɵqud(603979776, 2, { slides: 1 }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, null, null, 1, null, View_MapTutorialComponent_1)), i1.ɵdid(9, 16384, [[2, 4]], 0, i3.NgbSlide, [i1.TemplateRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(0, null, null, 1, null, View_MapTutorialComponent_2)), i1.ɵdid(12, 16384, [[2, 4]], 0, i3.NgbSlide, [i1.TemplateRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(0, null, null, 1, null, View_MapTutorialComponent_3)), i1.ɵdid(15, 16384, [[2, 4]], 0, i3.NgbSlide, [i1.TemplateRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(0, null, null, 1, null, View_MapTutorialComponent_4)), i1.ɵdid(18, 16384, [[2, 4]], 0, i3.NgbSlide, [i1.TemplateRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = "block"; _ck(_v, 4, 0, currVal_0); }); }
export function View_MapTutorialComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "map-tutorial", [], null, null, null, View_MapTutorialComponent_0, RenderType_MapTutorialComponent)), i1.ɵprd(512, null, i4.NgbCarouselConfig, i4.NgbCarouselConfig, []), i1.ɵdid(2, 49152, null, 0, i5.MapTutorialComponent, [i6.MapService, i7.JoyrideService, i4.NgbCarouselConfig], null, null)], null, null); }
var MapTutorialComponentNgFactory = i1.ɵccf("map-tutorial", i5.MapTutorialComponent, View_MapTutorialComponent_Host_0, {}, {}, []);
export { MapTutorialComponentNgFactory as MapTutorialComponentNgFactory };
