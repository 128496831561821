<div class="contextmenu" [style.left.px]="mapService.contextmenuLeft" [style.top.px]="mapService.contextmenuTop" [hidden]="!mapService.contextMenuOpen">
  <div class="container">
    <h6>
      Points of Interest
    </h6>
    <div [hidden]="mapService.contextMenuEdit || mapService.contextMenuEditBeacon ">
      <div (click)="openCreatePoiModal()" class="selection">
        <i class="fal fa-map-marker-alt"></i> POI ERSTELLEN
      </div>
      <hr />
      <div (click)="openCreateBeaconModal()" class="selection">
        <i class="fas fa-wifi"></i> ACCESSPOINT ERSTELLEN
      </div>
    </div>
    <div [hidden]="!mapService.contextMenuEdit">
      <div (click)="editPOI()" class="selection">
        <i class="fal fa-pencil-alt"></i> POI BEARBEITEN
      </div>
      <div (click)="deletePOI()" class="selection">
        <i class="fal fa-trash-alt"></i> POI LÖSCHEN
      </div>
    </div>
    <div [hidden]="!mapService.contextMenuEditBeacon">
      <div (click)="deleteBeacon()" class="selection">
        <i class="fal fa-trash-alt"></i> ACCESSPOINT LÖSCHEN
      </div>
    </div>
  </div>
</div>
