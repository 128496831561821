<div class="tutorial">
  <!--
  <div class="container align-middle">
    <div class="row align-middle">
      <div class="col-12 text-center">
        <img class="img-fluid tutorial-img" src="assets/img/tutorial.png" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="tutorialtext">
          Herzlich Wilkommen in der DianaMaps Anwendung.<br />
          Über dieses Geographische Informationsystem können Sie Karten der Diana Klinik einsehen und interessante Orte
          entdecken.<br /><br />Um Ihnen den Einstieg zu erleichtern, haben wir eine Hilfe für Sie bereitgestellt.
        </div>
      </div>
    </div>
    <div class="row tutorialbuttons">
      <div class="col-6 text-center">
        <button class="btn btn-outline-info btn-lg col-12" (click)="cancelTour()">Ich kenne mich aus</button>
      </div>
      <div class="col-6 text-center">
        <button class="btn btn-outline-success btn-lg col-12" (click)="startTour()">Funktionen ansehen</button>
      </div>
    </div>
  </div>
  -->

  <ngb-carousel #onboardingcarousel>
    <ng-template ngbSlide>
      <div class="onboarding-slide text-center">
        <div class="onboarding-text container">
          <h3> Diana Maps </h3>
          Herzlich Wilkommen im Geographischen Informationssystem.<br />
          Über diese Anwendung können Sie die digitale Karten der DianaKlinik einsehen und interessante Orte entdecken.
          <div class="col-12 text-center onboarding-button-container">
            <button class="btn onboarding-button col-6" (click)="moveNext()">Weiter</button>
          </div>
        </div>
        <div class="onboarding-image">
          <img class="img-fluid tutorial-img" src="assets/img/welcome1.png" />
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="onboarding-slide text-center">
        <div class="onboarding-text container">
          <h3> Entdecken </h3>
            Entdecken Sie Einrichtungen, Abteilungen, Räume oder interessante Orte der DianaKlinik.
          <div class="col-12 text-center onboarding-button-container">
            <button class="btn onboarding-button col-6" (click)="moveNext()">Weiter</button>
          </div>
        </div>
        <div class="onboarding-image">
          <img class="img-fluid tutorial-img" src="assets/img/entdecken1.png" />
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="onboarding-slide text-center">
        <div class="onboarding-text container">
          <h3> Finden </h3>
          Finden Sie die kürzesten Weg über das gesamte Gelände. Steuern Sie schneller zu Ihren Behandlungsorten.
          <div class="col-12 text-center onboarding-button-container">
            <button class="btn onboarding-button col-6" (click)="moveNext()">Weiter</button>
          </div>
        </div>
        <div class="onboarding-image ">
          <img class="img-fluid tutorial-img" src="assets/img/routen1.png" />
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="onboarding-slide text-center">
        <div class="onboarding-text container">
          <h3> Eigener Standort </h3>
          Lassen Sie sich lokalisieren und sehen Ihren aktuellen Standort auf der Karte.
          <div class="col-12 text-center onboarding-button-container">
            <button class="btn onboarding-button btn-lg col-6" (click)="startTour()">Funktionen ansehen</button><br />
            <button class="btn onboarding-skip-button btn-lg col-6" (click)="cancelTour()">Ich kenne mich aus</button>
          </div>
        </div>
        <div class="onboarding-image">
          <img class="img-fluid tutorial-img" src="assets/img/posi1.png" />
        </div>
      </div>
    </ng-template>
  </ngb-carousel>

</div>

