<div class="layer-switcher" [ngClass]="{'active_roombook' : this.mapService.setting_show_roombook === true, 'cordova-layerswitcher' : this.mapService.isCordovaApp === true }" joyrideStep="layerSwitcher" title="Etagen wechseln" text="Tippen Sie hier, um in die gewünschte Etage zu springen.">
  <div class="loading-blocker" *ngIf="this.mapService.layerswitcher_loading">
    <div class="loading"></div>
  </div>
  <div class="btn-group-vertical" @layerswitcherAnim>
    <button id="og11" type="button" class="btn btn-secondary layerswitcherbutton first" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG11_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG11_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG11_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG11_POLY')}" (click)="this.mapService.switchToLayer(12)"><b>Obergeschoß 11</b></button>
    <button id="og10" type="button" class="btn btn-secondary layerswitcherbutton " [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG10_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG10_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG10_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG10_POLY')}" (click)="this.mapService.switchToLayer(11)"><b>Obergeschoß 10</b></button>
    <button id="og9" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG09_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG09_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG09_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG09_POLY')}" (click)="this.mapService.switchToLayer(10)"><b>Obergeschoß 09</b></button>
    <button id="og8" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG08_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG08_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG08_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG08_POLY')}" (click)="this.mapService.switchToLayer(9)"><b>Obergeschoß 08</b></button>
    <button id="og7" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG07_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG07_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG07_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG07_POLY')}" (click)="this.mapService.switchToLayer(8)"><b>Obergeschoß 07</b></button>
    <button id="og6" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG06_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG06_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG06_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG06_POLY')}" (click)="this.mapService.switchToLayer(7)"><b>Obergeschoß 06</b></button>
    <button id="og5" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG05_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG05_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG05_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG05_POLY')}" (click)="this.mapService.switchToLayer(6)"><b>Obergeschoß 05</b></button>
    <button id="og4" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG04_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG04_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG04_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG04_POLY')}" (click)="this.mapService.switchToLayer(5)"><b>Obergeschoß 04</b></button>
    <button id="og3" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG03_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG03_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG03_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG03_POLY')}" (click)="this.mapService.switchToLayer(4)"><b>Obergeschoß 03</b></button>
    <button id="og2" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG02_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG02_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG02_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG02_POLY')}" (click)="this.mapService.switchToLayer(3)"><b>Obergeschoß 02</b></button>
    <button id="og1" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_OG01_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_OG01_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_OG01_POLY', 'active-floor-route': getRouteFloors('public.DIANA_OG01_POLY')}" (click)="this.mapService.switchToLayer(2)"><b>Obergeschoß 01</b></button>
    <button id="eg1" type="button" class="btn btn-secondary layerswitcherbutton" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_EG01_POLY','room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_EG01_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_EG01_POLY', 'active-floor-route': getRouteFloors('public.DIANA_EG01_POLY')}" (click)="this.mapService.switchToLayer(1)"><b>Erdgeschoß 01</b></button>
    <button id="eg0" type="button" class="btn btn-secondary layerswitcherbutton last" [ngClass]="{'active_floor': this.mapService.activeRoomTable == 'public.DIANA_EG00_POLY', 'room_in_next_floor': (this.mapService.activeRoomsTable.indexOf('public.DIANA_EG00_POLY') !=-1), 'active-floor-search': this.mapService.activeRoomNextFloorTable == 'public.DIANA_EG00_POLY', 'active-floor-route': getRouteFloors('public.DIANA_EG00_POLY')}"  (click)="this.mapService.switchToLayer(0)"><b>Erdgeschoß</b></button>
  </div>
</div>
