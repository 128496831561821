<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Neuen Point of Interest erstellen</h6>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="poicategorycontainer"  *ngIf="poiCategory == 'Freizeit'" ngClass="bg-freizeit"></div>
  <div class="poicategorycontainer"  *ngIf="poiCategory == 'Pflege'" ngClass="bg-pflege"></div>
  <div class="poicategorycontainer"  *ngIf="poiCategory == 'Therapie'" ngClass="bg-therapie"></div>
  <div class="poicategorycontainer"  *ngIf="poiCategory == 'Eingänge & Ausgänge'" ngClass="bg-eingang-ausgang"></div>
  <div class="poicategorycontainer"  *ngIf="poiCategory == 'Treppen & Aufzüge'" ngClass="bg-treppen-aufzug"></div>
  <label *ngIf="poiCategory != 'Kategorie auswählen'" class="fadeout"></label>

  <form>
    <div class="form-group">

      <div class="row mb-2">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingCategory }">Kategorie:</label>
        </div>
        <div class="col-9">

          <div class="row">
            <div class="col">
              <div ngbDropdown class="d-inline-block w-100">
                <button class="btn btn-outline-primary w-100" id="dropdown-1" ngbDropdownToggle>
                  {{poiCategory}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdown-1">
                  <button class="dropdown-item" (click)="setCategory('Freizeit')">Freizeit</button>
                  <button class="dropdown-item" (click)="setCategory('Pflege')">Pflege</button>
                  <button class="dropdown-item" (click)="setCategory('Therapie')">Therapie</button>
                  <button class="dropdown-item" (click)="setCategory('Eingänge & Ausgänge')">Eingänge & Ausgänge</button>
                  <button class="dropdown-item" (click)="setCategory('Treppen & Aufzüge')">Treppen & Aufzüge</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingName }">Name:</label>
        </div>
        <div class="col-9">
          <input type="text" name="poi_name" [(ngModel)]="poiName"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label">Beschreibung:</label>
        </div>
        <div class="col-9">
          <input type="text" name="poi_description" [(ngModel)]="poiDescription"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label">Etage:</label>
        </div>
        <div class="col-9">
          <label class="col-form-label">{{mapService.activeFloorNumber}} (aktive Etage)</label>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingIcon }">Symbolik auf der Karte:</label>
        </div>
      </div>

      <div class="input-group">
        <button class="btn btn-icon poi-icon-bett" (click)="selectIcon('bett')" [ngClass]="{'blue-marker': poiSelectedIconName == 'bett'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-doc" (click)="selectIcon('doc')" [ngClass]="{'blue-marker': poiSelectedIconName == 'doc'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-door" (click)="selectIcon('door')" [ngClass]="{'blue-marker': poiSelectedIconName == 'door'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-drop" (click)="selectIcon('drop')" [ngClass]="{'blue-marker': poiSelectedIconName == 'drop'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-elevator" (click)="selectIcon('elevator')" [ngClass]="{'blue-marker': poiSelectedIconName == 'elevator'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-medical" (click)="selectIcon('medical')" [ngClass]="{'blue-marker': poiSelectedIconName == 'medical'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-parkplatz" (click)="selectIcon('parkplatz')" [ngClass]="{'blue-marker': poiSelectedIconName == 'parkplatz'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-ramp" (click)="selectIcon('ramp')" [ngClass]="{'blue-marker': poiSelectedIconName == 'ramp'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-toilettenm" (click)="selectIcon('toilettem')" [ngClass]="{'blue-marker': poiSelectedIconName == 'toilettem'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-toilettenw" (click)="selectIcon('toilettew')" [ngClass]="{'blue-marker': poiSelectedIconName == 'toilettew'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-treppe" (click)="selectIcon('treppe')" [ngClass]="{'blue-marker': poiSelectedIconName == 'treppe'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-tresen" (click)="selectIcon('tresen')" [ngClass]="{'blue-marker': poiSelectedIconName == 'tresen'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-food" (click)="selectIcon('food')" [ngClass]="{'blue-marker': poiSelectedIconName == 'food'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-lab" (click)="selectIcon('lab')" [ngClass]="{'blue-marker': poiSelectedIconName == 'lab'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-pills" (click)="selectIcon('pills')" [ngClass]="{'blue-marker': poiSelectedIconName == 'pills'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-swimming" (click)="selectIcon('swimming')" [ngClass]="{'blue-marker': poiSelectedIconName == 'swimming'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-syringe" (click)="selectIcon('syringe')" [ngClass]="{'blue-marker': poiSelectedIconName == 'syringe'}"
                type="button"></button>
        <button class="btn btn-icon poi-icon-bathtub" (click)="selectIcon('bathtub')" [ngClass]="{'blue-marker': poiSelectedIconName == 'bathtub'}"
                type="button"></button>
      </div>

      <div class="row m-t-1">
        <div class="col-12">
          <div *ngIf="showErrorMissingName">
            <span class="text-danger">Es muss ein Name angegeben werden!</span>
          </div>
          <div *ngIf="showErrorMissingCategory">
            <span class="text-danger">Es muss eine Kategorie ausgewählt werden!</span>
          </div>
          <div *ngIf="showErrorMissingIcon">
            <span class="text-danger">Es muss ein zugehöriges Icon ausgewählt werden!</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
      <button type="button" class="w-50 btn btn-secondary m-1" (click)="activeModal.dismiss();">Abbrechen</button>
      <button type="button" class="w-50 btn btn-primary m-1" (click)="save()">Erstellen</button>
</div>
