import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';

@Component({
  selector: 'app-map-controls',
  templateUrl: './map-controls.component.html',
  styleUrls: ['./map-controls.component.scss']
})
export class MapControlsComponent {

  threedon: boolean;

  constructor(public mapService: MapService) {}

  switchto3d() {
    if (!this.threedon) {
      this.threedon = true;
      this.mapService.layer_wms_eg01.setVisible(false);
      this.mapService.layer_wms_eg01_3d.setVisible(true);
    } else {
      this.threedon = false;
      this.mapService.layer_wms_eg01.setVisible(true);
      this.mapService.layer_wms_eg01_3d.setVisible(false);
    }

  }
}
