
<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="row no-gutters">
        <div class="col-12">
          <h5><i class="fal fa-map-marker-alt"></i>WLAN-Accesspoint</h5>
        </div>
      </div>
      <form>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">Name</label>
          </div>
          <div class="col-6 mb-2">
            <input name="name" type="text" rows="2" class="form-control" [(ngModel)]="name" placeholder="{{name}}" value="{{name}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">BSSID1</label>
          </div>
          <div class="col-6 mb-2">
            <input name="BSSID1" type="text" rows="2" class="form-control" [(ngModel)]="BSSID1" placeholder="{{BSSID1}}" value="{{BSSID1}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">BSSID2</label>
          </div>
          <div class="col-6 mb-2">
            <input name="BSSID2" type="text" rows="2" class="form-control" [(ngModel)]="BSSID2" placeholder="{{BSSID2}}" value="{{BSSID2}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">BSSID3</label>
          </div>
          <div class="col-6 mb-2">
            <input name="BSSID3" type="text" rows="2" class="form-control" [(ngModel)]="BSSID3" placeholder="{{BSSID3}}" value="{{BSSID3}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">BSSID4</label>
          </div>
          <div class="col-6 mb-2">
            <input name="BSSID4" type="text" rows="2" class="form-control" [(ngModel)]="BSSID4" placeholder="{{BSSID4}}" value="{{BSSID4}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">TXPower</label>
          </div>
          <div class="col-6 mb-2">
            <input name="TXPower" type="text" rows="2" class="form-control" [(ngModel)]="TXPower" placeholder="{{TXPower}}" value="{{TXPower}}" />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-2">
            <label class="col-form-label">Inaktiv</label>
          </div>
          <div class="col-1 mb-2">
            <input name="inactive" type="checkbox" rows="2" [(ngModel)]="inactive" placeholder="{{inactive}}" value="{{inactive}}" style="margin-top: 10px;" />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <button type="button" class="w-100 btn rounded btn-sm btn-secondary" (click)="close();">Abbrechen</button>
          </div>
          <div class="col-6">
            <button type="button" class="w-100 btn rounded btn-sm btn-primary" (click)="save()">Speichern</button>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>
