import { Component, OnInit } from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';

@Component({
  selector: 'poi-category-switch-widget-patient',
  templateUrl: './poi-category-switch-widget-patient.component.html',
  styleUrls: ['./poi-category-switch-widget-patient.component.scss']
})
export class PoiCategorySwitchWidgetPatientComponent implements OnInit {

  poiImageURLs: Object = {
    'Freizeit': 'assets/img/poi/freizeit.jpg',
    'Pflege': 'assets/img/poi/pflege.jpg',
    'Therapie': 'assets/img/poi/therapie.jpg',
    'Eingänge & Ausgänge': 'assets/img/poi/eingang.jpg',
    'Treppen & Aufzüge': 'assets/img/poi/treppe.jpg'
  };

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(public mapService: MapService, public authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.credentials) {
        this.mapService.show_poi = true;
        this.mapService.setting_show_poi_freizeit = false;
        this.mapService.setting_show_poi_pflege = false;
        this.mapService.setting_show_poi_therapie = false;
        this.mapService.setting_show_poi_eingaenge_ausgaenge = true;
        this.mapService.setting_show_poi_treppen_aufzuege = true;
    }
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientY, e.changedTouches[0].clientX];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[1]) < 90 // auf x-achse weniger als 90 pixel bewegt
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // vertikal genug
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          this.mapService.poi_widget_open = true;
        }
        if (swipe === 'previous') {
          this.mapService.poi_widget_open = false;
        }

      }
    }
  }

  togglePoiCategory(category: string) {
    this.mapService.togglePoiCategory(category);
  }

}
