import {Component, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
import {ReportModalComponent} from '@app/core/pages/map/modals/report-modal/report-modal.component';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
import * as Sentry from '@sentry/browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
const log = new Logger('RoutingWidgetComponent');

@Component({
  selector: 'routing-widget',
  templateUrl: './routing-widget.component.html',
  styleUrls: ['./routing-widget.component.scss'],
  animations: [
    trigger('routeWidgetAnim', [
      transition('void => *', [
        query('.card-body-2', style({ opacity: 0, transform: 'translateY(500px)'})),
        query('.card-body-2', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(-20px)',  offset: 0.7}),
            style({opacity: 1, transform: 'translateY(0px)',  offset: 1})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.card-body-2', style({ opacity: 1, transform: 'translateY(0px)'})),
        query('.card-body-2', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(-20px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(500px)',  offset: 1})
          ]))
        ])),
      ])
    ]),
    trigger('iconSwipeAnim', [
      transition('void => *', [
        query('.col-md-12', style({ opacity: 1, transform: 'translateX(-500px)' })),
        query('.col-md-12', stagger('-100ms', [
          animate('0.2s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
        ]))
      ])
    ])
  ]
})
export class RoutingWidgetComponent implements OnInit {

  isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
  ReportModalComponentReference: any;

  constructor(public mapService: MapService, public authService: AuthenticationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    log.debug('on init routing-widget');
  }

  reportRoute() {
    this.ReportModalComponentReference = this.modalService.open(ReportModalComponent);
  }



  instructionclick( instruction_feature: any) {

    const coords = instruction_feature.getGeometry().getExtent();
    const X = coords[0] + (coords[2] - coords[0]) / 2;
    const Y = coords[1] + (coords[3] - coords[1]) / 2;
    const thisref = this;
    this.mapService.switchToLayer(instruction_feature.get('floor'));
    const res = this.mapService.map.getView().getResolutionForExtent(coords);
    const zoom = this.mapService.map.getView().getZoomForResolution(res);
    this.mapService.zoomMapToCords(X, Y, zoom - 2, function () {
       thisref.mapService.flashline(instruction_feature);
    });
  }

}
