import {Component, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {RestService} from '@app/core/services/rest.service';
import WKT from 'ol/format/WKT';
import {ToastrService} from 'ngx-toastr';

const log = new Logger('MapRoombookWindowComponent');

@Component({
  selector: 'map-roombook-window',
  templateUrl: './map-roombook-window.component.html',
  styleUrls: ['./map-roombook-window.component.scss']
})
export class MapRoombookWindowComponent implements OnInit {

  search_filter_active = false;
  filter_only_current_floor = false;
  searchFilterBy: any;
  inputClicked: number;

  cleaningintervals: any = [
    'ohne Reinigung',
    '1x / Woche',
    '2x / Woche',
    '5x / Woche',
    '5,5x / Woche',
    '6x / Woche',
    '7x / Woche',
    '1x / Monat',
    '2x / Monat'
  ];

  districts: any = [
    'ohne Reinigung',
    'Frührevier Speisesaal',
    'Frührevier Fischer',
    'Spätdienst',
    'Bettenteam',
    'Revier 01 B',
    'Revier 01 C/D',
    'Revier 02 A',
    'Revier 02 B',
    'Revier 02 C/D',
    'Revier 03 A',
    'Revier 03 B',
    'Revier 03 C',
    'Revier 04 A',
    'Revier 04 B',
    'Revier 04 C',
    'Revier 05 A',
    'Revier 05 B',
    'Revier 05 C',
    'Revier 06',
    'Revier WE 07/08',
    'Revier 09+10',
    'vorher Revier 09+10',
    'Neubau EG',
    'Neubau 1. OG',
    'Neubau 2. OG'
  ];


  constructor(public mapService: MapService, public authService: AuthenticationService, public restService: RestService, private toast: ToastrService) {
  }

  ngOnInit() {
    this.mapService.getRoomsData();
    this.mapService.zoomMapToCords(1178794, 6997935, 17.7, function () {
    });
  }

  setSearchSorting(sortingType: string) {
    switch (sortingType) {
      case 'asc':
        this.mapService.roombooksearchSortBy = 'asc';
        break;
      case 'desc':
        this.mapService.roombooksearchSortBy = 'desc';
        break;
    }
    this.mapService.clicked_row = null;
    this.inputClicked = null;
    this.mapService.clickedResult = {};
    this.mapService.roomClicked = false;
    this.mapService.getRoomsData();
  }

  toggleRoomswithoutData() {
    if (this.mapService.onlyEmptyRooms === false) {
      this.mapService.onlyEmptyRooms = true;
      this.mapService.getRoomsData();
    } else {
      this.mapService.onlyEmptyRooms = false;
      this.mapService.getRoomsData();
    }
  }

  setFilterOnlyCurrentFloor() {
    this.filter_only_current_floor = !this.filter_only_current_floor;
    this.mapService.roombookfiltered =  !this.mapService.roombookfiltered;
    this.setSearchFilter();
  }

  resetFilter() {
    this.filter_only_current_floor = false;
    this.search_filter_active = false;
    this.mapService.onlyEmptyRooms = false;
    this.mapService.roombooksearchSortBy = 'asc';
    this.mapService.roombookfiltered = false;
    this.mapService.roombookFilterdFloor = 'all';
    this.mapService.getRoomsData();
  }

  setSearchFilter() {
    if (this.filter_only_current_floor) {
      this.mapService.roombookFilterdFloor = this.mapService.activeRoomTable;
      this.search_filter_active = true;
    } else {
      this.mapService.roombookFilterdFloor = 'all';
      this.search_filter_active = false;
    }
    this.mapService.getRoomsData();
  }

  clickOnRow(ID: number) {
    if (this.mapService.clicked_row !== ID) {
      this.mapService.clicked_row = ID;
      this.mapService.clickedResult = this.mapService.rooms_data[ID];
      this.mapService.roomClicked = true;
      this.mapService.roomTable = this.mapService.clickedResult.table;
      const format = new WKT();
      let multiPolygon: any;
      let x: any;
      let y: any;
      this.mapService.layer_selected_room.getSource().clear();
      if (this.mapService.clickedResult.table !== this.mapService.activeRoomTable) {
        const floorNumber = parseFloat(this.mapService.clickedResult.rb_ebene);
        this.mapService.switchToLayer(floorNumber);
      }
      multiPolygon = this.mapService.clickedResult.st_astext;
      x = parseFloat(this.mapService.clickedResult.st_x);
      y = parseFloat(this.mapService.clickedResult.st_y);

      const feature = format.readFeatures(multiPolygon);
      this.mapService.activeRoomNextFloorTable = null;
      const thisref = this;
      this.mapService.layer_selected_room.setVisible(true);
      this.mapService.layer_selected_room.getSource().addFeatures(feature);
      this.mapService.zoomMapToCords(x, y - 30, 20, function () {
        thisref.mapService.flashpoly(multiPolygon);
      });
    }
  }

  onScrollDown(ev: any) {
    const start = this.mapService.sum;
    this.mapService.sum += 20;
    this.mapService.appendItems(start, this.mapService.sum);
  }

  onScrollUp(ev: any) {
    const end = this.mapService.sum;
    this.mapService.sum -= 20;
    this.mapService.prependItems(this.mapService.sum, end);
  }

  inputEditToggle(index: number) {
      this.inputClicked = index;
      this.clickOnRow(index);
  }

 setInputData(event: any) {
    this.mapService.clickedResult[event.target.name] = event.target.value;
    this.updateRoombookData();
  }

  setCleaningStatus(status: string) {
    this.mapService.clickedResult.rb_status = status;
    this.updateRoombookData();
  }

  setCleaningInterval(interval: string) {
    this.mapService.clickedResult.rb_turnus = interval;
    this.updateRoombookData();
  }

  setCleaningDistrict(district: string) {
    this.mapService.clickedResult.rb_revier = district;
    this.updateRoombookData();
  }

  setCategory(category: string) {
    this.mapService.clickedResult.r_cat = category;
    switch (category) {
      case 'Keine Kategorie':
        this.mapService.clickedResult.rb_color = null;
        break;
      case 'Patientenzimmer':
        this.mapService.clickedResult.rb_color = '#B8CCE4';
        break;
      case 'Diagnostik':
        this.mapService.clickedResult.rb_color = '#FFFF00';
        break;
      case 'Pflegestützpunkt/Büro':
        this.mapService.clickedResult.rb_color = '#92D050';
        break;
      case 'Arztzimmer':
        this.mapService.clickedResult.rb_color = '#F79646';
        break;
      case 'Gruppenraum':
        this.mapService.clickedResult.rb_color = '#76933C';
        break;
      case 'Therapie':
        this.mapService.clickedResult.rb_color = '#996633';
        break;
      case 'Lager':
        this.mapService.clickedResult.rb_color = '#404040';
        break;
    }
    this.updateRoombookData();
    this.mapService.clickedResult.r_public = !this.mapService.clickedResult.r_public;
  }

  setVisibility() {
    this.mapService.clickedResult.r_public = !this.mapService.clickedResult.r_public;
    this.updateRoombookData();
  }

  updateRoombookData() {
    this.restService.updateRoomBookData(this.mapService.clickedResult).then(() => {
      this.toast.success('Raum erfolgreich aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  closeRoombook() {
    this.mapService.clickedResult = {};
    this.mapService.clicked_row = null;
    this.mapService.setting_show_roombook = false;
  }
}
