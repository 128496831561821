import { Component, OnInit } from '@angular/core';
import { MapService} from '@app/core/services/map.service';
import {animate, query, stagger, style, transition, trigger, keyframes} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('WelcomeWidgetComponent');
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
import {JoyrideService} from 'ngx-joyride';

@Component({
  selector: 'welcome-widget',
  templateUrl: './welcome-widget.component.html',
  styleUrls: ['./welcome-widget.component.scss'],
  animations: [
    trigger('componentAnim', [
      transition('* => *', [
        query('.component', style({ opacity: 0, transform: 'translateY(-40px)' })),

        query('.component', stagger('300ms', [
          animate('400ms 0.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ])),


      ])
    ]),
    trigger('s_background_imageAnim', [
      transition('* => *', [
        query('.sidebar-background', style({ opacity: 0, filter: 'blur(5px)'})),

        query('.sidebar-background', stagger('200ms', [
          animate('0.6s ease-out', keyframes([
            style({opacity: 1, filter: 'blur(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    /*
    trigger('s_background_textAnim', [
      transition('* => *', [
        query('.logotext', style({ opacity: 0, transform: 'translateX(-500px)' })),

        query('.logotext', stagger('200ms', [
          animate('0.4s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(20px)',  offset: 0.5}),
            style({opacity: 1, transform: 'translateX(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ]) */
  ]
})
export class WelcomeWidgetComponent implements OnInit {

  constructor(public mapService: MapService, private authenticationService: AuthenticationService, private readonly joyrideService: JoyrideService) {  }

  ngOnInit() {

  }

  startTour () {
    this.mapService.tutorialActive = false;
    this.joyrideService.startTour(
      {
        themeColor: '#000000',
        steps: [
          'homeButton',
          'suchFeld@map',
          'suchButton@map',
          'recordButton@map',
          'poiCategorys@map',
          'sidebarCloseButton@map',
          'meinraumButton@map',
          'rotationButton@map',
          'zoominButton@map',
          'zoomoutButton@map',
          'layerSwitcher@map'
        ]
      }
    ).subscribe(
      (step) => {
        // je nachdem welcher step aufgerufen wird, kann man aktionen ausführen
        // wenn elemte aufgerufen werden sollen, die bei appstart mit *ngIf verdeckt sind,
        // muss möglicherweise der joyride array neu geladen werden  this.joyrideService.refresh();
        if (step.name === 'meinraumButton') {
          this.mapService.infowindowhidden = true;
        }
      }
    );
  }

  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }

}
