import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {environment} from 'environments/environment';
import {I18nService, AuthenticationService} from 'app/core/index';
import {Logger} from '@app/core/services/logger/logger.service';
import {ToastrService} from 'ngx-toastr';
import {Keyboard} from '@ionic-native/keyboard';
import {StatusBar} from '@ionic-native/status-bar';
import {SplashScreen} from '@ionic-native/splash-screen';
import {MapService} from '@app/core/services/map.service';
import * as Sentry from '@sentry/browser';

const log = new Logger('LoginComponent');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  version: string = environment.version;
  error: string;
  loginForm: FormGroup;
  isLoading = false;
  isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
  missingIntent = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private i18nService: I18nService,
              private authenticationService: AuthenticationService,
              private toast: ToastrService,
              private zone: NgZone,
              private keyboard: Keyboard,
              private statusBar: StatusBar,
              private splashScreen: SplashScreen,
              private mapService: MapService) {
    this.createForm();
  }

  ngOnInit() {
    // document.addEventListener('deviceready', () => {
    //   this.zone.run(() => this.onCordovaReady());
    // }, false);

    /* WENN SCHON EINGELOGGT UND NAVIGIEREN ZU ROOT ODER LOGIN SCHICKT WIEDER AUF MAP. ERGO UM BENUTZER ZU WECHSELN MUSS LOGOUT BETÄTIGT WERDEN
    if (this.authenticationService.isAuthenticated()) {
      log.debug('Already authenticated, redirecting...');
      this.router.navigate(['/map'], {replaceUrl: true});
    }
    */
  }

  login() {
    this.authenticationService.login(this.loginForm.value, '', '', '').subscribe((credentials) => {
      this.toast.success('Sie wurden in DianaMaps als Benutzer ' + credentials.username + ' angemeldet.', 'Anmeldung erfolgreich:'); // toast #DK-103
      log.debug(`${credentials} successfully logged in`);
    }, (error) => {
      log.debug('failed to login');
      this.toast.error('Überprüfen Sie Ihren Benutzernamen und Ihr Kennwort. Wenn Sie sich weiterhin nicht anmelden können, wenden Sie sich an einen Administrator.', 'Anmeldung fehlgeschlagen:'); // toast #DK-103
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }

  private onCordovaReady() {
    // if (window['cordova']) {
    //   log.debug('cordova device ready');
    //   this.keyboard.hideKeyboardAccessoryBar(true);
    //   // this.statusBar.styleDefault();
    //   this.statusBar.overlaysWebView(true);
    //   this.statusBar.backgroundColorByHexString('#32000000');
    //   this.splashScreen.hide();
    //
    //   // sentry die deviceid geben
    //   const device = (<any> window).device;
    //   Sentry.configureScope((scope) => {
    //     scope.setUser({'id': device.uuid});
    //     scope.setTag('device.uuid', device.uuid);
    //     scope.setTag('cordova.version', device.cordova);
    //     scope.setTag('device.platform', device.platform);
    //     scope.setTag('device.serial', device.serial);
    //     scope.setTag('device.version', device.version);
    //   });
    //
    //   // #DK-89
    //   (<any> window).plugins.intent.getCordovaIntent((intent) => {
    //     log.debug('dianamaps wurde mit intent gestartet', intent);
    //     if (intent.extras && intent.extras.username) { // type hier einfügen
    //       this.proccessIntent(intent);
    //       this.checktutorialstatus();
    //     } else {
    //       Sentry.captureException('Die Anwendung wurde auf einem mobilen Endgerät ohne den notwendigen Intent gestartet!');
    //       this.toast.error('Die App wurde ohne notwendige Intents bzw. Parameter gestartet!', 'Problem', {
    //         positionClass: 'toast-center-center',
    //       });
    //       this.missingIntent = true;
    //     }
    //   }, (error) => {
    //     log.debug('cordova-plugin-intent fehler', error);
    //   });
    //
    //   (<any> window).plugins.intent.setNewIntentHandler((intent) => {
    //     log.debug('dianamaps aktiv neu eingehender intent', intent);
    //     if (intent.extras && intent.extras.username) { // auf type stellen   typen: start , behandlungsplan, ...
    //       // this.proccessIntent(intent); //intent wird in v1.1 nicht verarbeitet , wenn app schon offen ist
    //     }
    //   });
    //
    // }
  }

  private proccessIntent(intent: any) {
    // log.debug('intent extras', intent.extras);
    // const paramter: DianaMapsIntentDetails = intent.extras;
    // log.debug('intent username', paramter.username);
    // log.debug('intent role', paramter.role);
    // log.debug('intent patientroom', paramter.patientroom);
    // const cordovaLoginContext = 'cordova';
    // Sentry.configureScope((scope) => {
    //   scope.setTag('login.username', paramter.username);
    //   scope.setTag('login.role', paramter.role);
    //   scope.setTag('login.patientroom', paramter.patientroom);
    // });
    // this.mapService.user_room = paramter.patientroom;
    // this.authenticationService.login(cordovaLoginContext, paramter.username, paramter.patientroom, paramter.role);
  }

  private checktutorialstatus() {
    // #DK-114 tutorial nur beim ersten starten der app
    const tutorial_done = window.localStorage.getItem('tutorial_done');
    // wenn tutorial_done nicht gesezt, zeige tutorial und setze tutorial_done direkt auf true,
    // so dass das tutorial nie wieder angezeigt wird, bis zum rücksetzen der app (neuer patient)
    if (!tutorial_done) {
      this.mapService.tutorialActive = true;
      localStorage.setItem('tutorial_done', 'true');
    }
  }

}

interface DianaMapsIntentDetails {
  username: string;
  role: string;
  patientroom: string;
  type: string;
}
