/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-live-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./map-live-navigation.component";
import * as i4 from "../../../services/map.service";
var styles_MapLiveNavigationComponent = [i0.styles];
var RenderType_MapLiveNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapLiveNavigationComponent, data: {} });
export { RenderType_MapLiveNavigationComponent as RenderType_MapLiveNavigationComponent };
export function View_MapLiveNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "live-navi"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-2 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "instructionicon"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-3 text-center instructiondistance"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "dist"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " m"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "col-6 text-center instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["\n      ", "\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "col-1 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn instructionkill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.mapService.navigationMode = false;
        _co.mapService.infowindowhidden = false;
        var pd_0 = (_co.mapService.layer_user_position.getSource().clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "instructionicon"; var currVal_1 = _co.mapService.NaviRouteSegments[1].get("icon"); _ck(_v, 7, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.mapService.NaviRouteSegments[1].get("cost").toFixed(2); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.mapService.NaviRouteSegments[1].get("instruction"); _ck(_v, 17, 0, currVal_3); }); }
export function View_MapLiveNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-live-navigation", [], null, null, null, View_MapLiveNavigationComponent_0, RenderType_MapLiveNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i3.MapLiveNavigationComponent, [i4.MapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapLiveNavigationComponentNgFactory = i1.ɵccf("map-live-navigation", i3.MapLiveNavigationComponent, View_MapLiveNavigationComponent_Host_0, {}, {}, []);
export { MapLiveNavigationComponentNgFactory as MapLiveNavigationComponentNgFactory };
