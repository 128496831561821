import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import {
  animate, animateChild, query, state, style, transition, trigger, keyframes, group,
  stagger
} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
import {ConfigService} from '@app/core/services/config.service';
import {environment} from '@env/environment';

const log = new Logger('MapComponent');

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        group([
        animate('0.4s ease-out', keyframes([
          style({opacity: 0, transform: 'translateX(-150%)',  offset: 0}),
          style({opacity: 1, transform: 'translateX(20px)',  offset: 0.5}),
          style({opacity: 1, transform: 'translateX(0)',    offset: 1.0})
        ])),
          query('@explainerAnim', [
            animateChild(),
          ], { optional: true , delay: 200}),
          query('@componentAnim', [
            animateChild(),
          ], { optional: true }),
          query('@roombookAnim', [
            animateChild(),
          ], { optional: true, delay: 200  }),
          query('@popUp', [
            animateChild(),
          ], { optional: true }),
          query('@s_background_imageAnim', [
            animateChild(),
          ], { optional: true, delay: 200 }),
          query('@s_background_textAnim', [
            animateChild(),
          ], { optional: true, delay: 400 }),
          query('@layerswitcherAnim', [
            animateChild(),
          ], { optional: true, delay: 1000 }),
          query('@button_to_sidebar_anim', [
            animateChild(),
          ], { optional: true, delay: 1000 }),
          query('@text_iconAnim', [
            animateChild(),
          ], { optional: true, delay: 500 }),
          query('@routeWidgetAnim', [
            animateChild(),
          ], { optional: true, delay: 500 }),
          query('@iconSwipeAnim', [
            animateChild(),
          ], { optional: true , delay: 200})
        ])
      ]),
      // marcus: die alte sidebar-aufplopp animation ist noch drin, sie liegt nur unter der button_to_sidebar animation
      transition('* => void', [
        animate('0.4s 0.4s ease-out', keyframes([
          style({opacity: 1, transform: 'translateX(0)',     offset: 0}),
          style({opacity: 1, transform: 'translateX(20px)', offset: 0.5}),
          style({opacity: 0, transform: 'translateX(-150%)',    offset: 1.0})
        ]))
      ])
    ]),
    trigger('closeButtonAnim', [
      transition('void => *', [
        query('.btn', style({ opacity: 1, transform: 'translateX(0)' })),
        query('.btn', stagger('0ms', [
          animate('0.3s 0.3s ease-out', keyframes([
            style({opacity: 0, transform: 'translateX(-250%)',  offset: 0}),
            style({opacity: 1, transform: 'translateX(30px)',  offset: 0.5}),
            style({opacity: 1, transform: 'translateX(0)',    offset: 1.0})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.btn', style({ opacity: 1, transform: 'translateX(0px)' })),
        query('.btn', stagger('0ms', [
          animate('0.3s 0.3s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(0)',     offset: 0}),
            style({opacity: 1, transform: 'translateX(30px)', offset: 0.5}),
            style({opacity: 0, transform: 'translateX(-250%)',    offset: 0.8})
          ]))
        ])),
      ])
    ]),
    trigger('naviButtonAnim', [
      transition('void => *', [
        query('.btn', style({ opacity: 1, transform: 'translateX(-500px)' })),
        query('.btn', stagger('0ms', [
          animate('0.5s 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 0, transform: ' scaleX(2) scaleY(2) translateX(-500px)',     offset: 0}),
            style({opacity: 1, transform: 'scaleX(1.5) scaleY(0.5)', offset: 0.5}),
            style({opacity: 1, transform: ' scaleX(1.2) scaleY(0.8)',  offset: 0.7}),
            style({opacity: 1, transform: ' scaleX(1) scaleY(1) translateX(0px)',    offset: 1.0})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.btn', style({ opacity: 1, transform: 'translateX(0px)' })),
        query('.btn', stagger('0ms', [
          animate('0.5s 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 1, transform: ' scaleX(1) scaleY(1)',     offset: 0}),
            style({opacity: 1, transform: 'scaleX(1.2) scaleY(0.8)', offset: 0.5}),
            style({opacity: 0, transform: ' scaleX(1.5) scaleY(0.5)',  offset: 0.7}),
            style({opacity: 0, transform: ' scaleX(2) scaleY(2)',    offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('loadingAnim1', [
      transition('* => void', [
        query('.anim', style({ opacity: 1})),
        query('.anim',
          animate('2.8s 2.8s ease-out', keyframes([
            style({opacity: 0, transform: 'scale(500)',    offset: 1.0})
          ]))
        ),
      ])
    ]),
    trigger('loadingAnim2', [
      transition('* => void', [
        query('.anim2', style({ opacity: 1 })),
        query('.anim2',
          animate('2.8s 2.8s ease-out', keyframes([
            style({opacity: 0,    offset: 1.0})
          ]))
        ),
      ])
    ]),
    trigger('aliveAnim', [
      transition('void => *', [
        query('.alivecheck', style({ opacity: 0, transform: 'translateY(500px)'})),
        query('.alivecheck',
          animate('0.4s 0s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0px)',    offset: 1.0})
          ]))
        ),
      ]),
      transition('* => void', [
        query('.alivecheck', style({ opacity: 1, transform: 'translateY(0px)'})),
        query('.alivecheck',
          animate('0.8s 0s ease-out', keyframes([
            style({opacity: 0, transform: 'translateY(500px)',    offset: 1.0})
          ]))
        ),
      ])
    ]),
  ],
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  minimumDownlink = environment.minimumDownlink;

  poiImageURLs: Object = {
    'Freizeit': 'assets/img/poi/freizeit.jpg',
    'Pflege': 'assets/img/poi/pflege.jpg',
    'Therapie': 'assets/img/poi/therapie.jpg',
    'Eingänge & Ausgänge': 'assets/img/poi/eingang.jpg',
    'Treppen & Aufzüge': 'assets/img/poi/treppe.jpg'
  };

  constructor(public mapService: MapService, public authenticationService: AuthenticationService) {
    log.debug('map constructor');
  }

  loadingScreenActive() {
    return ConfigService.loadingScreenActive;
  }

  onLine() {
    // weitere tests nötig, simuliert im browser mit 'offline' oder im handy mit wlan ausmachen funktioniert es
    // ausserdem sollte wahrscheinlich noch eine funktion geadded werden die einen angular change feuert

    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
      return navigator.onLine;
    } else { return true; }
  }

  downlink() {
    // internetgeschwindigkleit in mbit
    // aktuell wird die app mit dem downlink ab 0.5 mbit unbenutzbar langsam,
    // daher die überlegung schon bei solchen geschwindigkeiten offline anzuzeigen
    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
      return  (<any> navigator).connection.downlink;
    } else { return 10000; }

  }
}

