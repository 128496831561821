/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./map-view.component";
import * as i4 from "../../../services/map.service";
import * as i5 from "../../../services/authentication/authentication.service";
import * as i6 from "../../../services/rest.service";
var styles_MapViewComponent = [i0.styles];
var RenderType_MapViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapViewComponent, data: {} });
export { RenderType_MapViewComponent as RenderType_MapViewComponent };
export function View_MapViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "navigation-view-container": 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "map"], ["id", "map"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (_co.onRightClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "navigation-view": 0 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.mapService.navigationMode); _ck(_v, 1, 0, currVal_0); var currVal_1 = "map"; var currVal_2 = _ck(_v, 6, 0, _co.mapService.navigationMode); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_MapViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-view", [], null, null, null, View_MapViewComponent_0, RenderType_MapViewComponent)), i1.ɵdid(1, 4308992, null, 0, i3.MapViewComponent, [i4.MapService, i5.AuthenticationService, i6.RestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapViewComponentNgFactory = i1.ɵccf("map-view", i3.MapViewComponent, View_MapViewComponent_Host_0, {}, {}, []);
export { MapViewComponentNgFactory as MapViewComponentNgFactory };
