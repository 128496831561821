<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Neuen Access Point erstellen</h6>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <form>
    <div class="form-group">

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingName }">Name:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconBSSID1" [(ngModel)]="beaconName"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingBSSID }">BSSID 1:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconBSSID1" [(ngModel)]="beaconBSSID1"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingBSSID }">BSSID 2:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconBSSID2" [(ngModel)]="beaconBSSID2"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingBSSID }">BSSID 3:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconBSSID3" [(ngModel)]="beaconBSSID3"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingBSSID }">BSSID 4:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconBSSID4" [(ngModel)]="beaconBSSID4"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label" [ngClass]="{'text-danger': showErrorMissingTXPower }">TXPower:</label>
        </div>
        <div class="col-9">
          <input type="text" name="beaconTXPower" [(ngModel)]="beaconTXPower"/>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label">Etage:</label>
        </div>
        <div class="col-9">
          <label class="col-form-label">{{mapService.activeFloorNumber}} (aktive Etage)</label>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label class="col-form-label">Inaktiv:</label>
        </div>
        <div class="col-1" style="margin-top: 10px;">
          <input type="checkbox" name="beaconInactive" [(ngModel)]="beaconInactive"/>
        </div>
      </div>

      <div class="row m-t-1">
        <div class="col-12">
          <div *ngIf="showErrorMissingBSSID">
            <span class="text-danger">Es muss eine BSSID eingegeben werden!</span>
          </div>
          <div *ngIf="showErrorMissingTXPower">
            <span class="text-danger">Es muss eine Übertragungsstärke eingegeben werden!</span>
          </div>
          <div *ngIf="showErrorMissingName">
            <span class="text-danger">Es muss ein Name für den Accesspoint angeben werden!</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="w-50 btn btn-secondary m-1" (click)="activeModal.dismiss();">Abbrechen</button>
  <button type="button" class="w-50 btn btn-primary m-1" (click)="save()">Erstellen</button>
</div>
