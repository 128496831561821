// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: true,
  minimumDownlink: 0.04,
  version: env.npm_package_version,
  serverUrl: '',
  defaultLanguage: 'de-DE',
  supportedLanguages: [
    'en-US',
    'fr-FR',
    'de-DE'
  ]
};
