<div class="sidebar-left">
  <div class="container">
    <div class="blue-outline-container">
      <div class="white-outline-container">
        <div class="fadeout"></div>
        <div class="mb-2">
          <h5>Suchergebnisse für "{{this.mapService.searchInputString}}"</h5>
          <div class="d-flex justify-content-between">
            <div>
              <div ngbDropdown class="d-inline-block">
                <button [ngClass]="mapService.search_filter_active ? 'btn-primary' : 'btn-light'" class="btn" id="dropdownbasic1" ngbDropdownToggle><i class="fal fa-filter"></i>
                  Filter
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button class="custom-dropdown-item">
                <span class="switch switch-sm">
                  <input type="checkbox" (click)="setFilterRooms()" class="switch" [checked]="filter_rooms"
                         [ngModel]="filter_rooms" id="switch-filter-search-rooms"/>
                  <label class="mb-0" for="switch-filter-search-rooms">Räume</label>
                </span>
                  </button>
                  <button class="custom-dropdown-item">
                <span class="switch switch-sm">
                  <input type="checkbox" (click)="setFilterPOI()" class="switch" [checked]="filter_pois"
                         [ngModel]="filter_pois" id="switch-filter-search-pois"/>
                  <label class="mb-0" for="switch-filter-search-pois">POIs</label>
                </span>
                  </button>
                  <button class="custom-dropdown-item">
                <span class="switch switch-sm">
                  <input type="checkbox" class="switch" (click)="setFilterOnlyCurrentFloor()"
                         [checked]="filter_only_current_floor" id="switch-filter-only-current-floor"/>
                  <label class="mb-0" for="switch-filter-only-current-floor">nur aktuelle Etage</label>
                </span>
                  </button>
                </div>
              </div>

              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-light" id="dropdownbasic2" ngbDropdownToggle><i class="fas fa-sort"></i>
                  {{activeSortingButtonString}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button (click)="setSearchSorting('relevance')"
                          [ngClass]="{'custom-dropdown-item-active': mapService.searchSortBy === 'relevance'}"
                          class="custom-dropdown-item">
                    Relevanz
                  </button>
                  <button (click)="setSearchSorting('asc')"
                          [ngClass]="{'custom-dropdown-item-active': mapService.searchSortBy === 'asc'}"
                          class="custom-dropdown-item">
                    Aufsteigend
                  </button>
                  <button (click)="setSearchSorting('desc')"
                          [ngClass]="{'custom-dropdown-item-active': mapService.searchSortBy === 'desc'}"
                          class="custom-dropdown-item">
                    Absteigend
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-light" (click)="resetFilter()">
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="mapService.search_result_data.length == 0 && mapService.searchFilterBy !== 'none'">
          <div class="alert alert-dark mt-2 text-center no-results-message" role="alert">
            Keine Ergebnisse für Suchbegriff
          </div>
        </ng-container>
        <ng-container *ngIf="mapService.search_result_data.length == 0 && mapService.searchFilterBy == 'none'">
          <div class="alert alert-dark mt-2 text-center no-results-message" role="alert">
            Keine Ergebnisse für gewählten Filter
          </div>
        </ng-container>
        <div *ngIf="mapService.search_result_data.length > 0" class="text-center font-weight-bold mt-4 pb-3 mb-3">
          {{mapService.search_result_data.length}} Ergebniss(e) gefunden
        </div>
      </div>
    </div>
    <div class="results-container">
      <ng-container *ngFor="let item of mapService.search_result_data; let index = index">
        <ng-container *ngIf="item.search_type === 'room'">
          <div [id]="'search-element-' + index"
               (mouseenter)="hoverEnterOnSearchResult(index)"
               (mouseleave)="hoverLeaveOnSearchResult()" class="card card-search-result mb-2">
            <div class="row">
              <div *ngIf="roomImages[item.r_name]" class="col-4 pr-0"
                   [ngStyle]="{'background-image': 'url(' + roomImages[item.r_name] + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'background-origin': 'content-box'}" (click)="clickOnSearchResult(index)"></div>
              <div *ngIf="!roomImages[item.r_name]" class="col-4 pr-0"
                   [ngStyle]="{'background-image': 'url(' + roomImages['Kein Name vergeben'] + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'background-origin': 'content-box'}" (click)="clickOnSearchResult(index)"></div>
              <div class="col-6 py-2 searchresult-content" (click)="clickOnSearchResult(index)">
                <div class="row">
                  <div class="col-12">
                    <b>{{item.r_name}}</b>
                  </div>
                  <div class="col-12">
                    <span class="text-small">Raumnummer: </span><span>{{item.r_nummer}}</span>
                  </div>
                  <div class="col-12">
                    <span class="text-small">Haus: </span><span>{{item.rb_haus}}</span>
                  </div>
                  <div class="col-12">
                    <span class="text-small">Etage: </span><span>{{item.rb_ebene &gt; 1 ? 'OG' + (item.rb_ebene -1) : 'EG' + item.rb_ebene}}</span>
                  </div>
                </div>
              </div>
              <div class="col-2 searchresult-button" (click)="mapService.setNaviPoint('end',item);clickOnSearchResult(index);">
                <div class="routing-icon">
                  <i class="fal fa-location-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="item.search_type === 'poi'">
          <div [id]="'search-element-' + index" (click)="clickOnSearchResult(index)"
               (mouseenter)="hoverEnterOnSearchResult(index)"
               (mouseleave)="hoverLeaveOnSearchResult()"
               class="card card-search-result mb-2">
              <div class="row">
                <div class="col-4 poi-search-icon">
                  <i class="far fa-map-marker-alt fa-2x"></i>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12 pt-3">
                      <b>{{item.r_name}}</b>
                    </div>
                    <div class="col-12 pb-3">
                      <span class="text-small">Etage: </span><span>{{item.rb_ebene}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-2 searchresult-button" (click)="mapService.setNaviPoint('end',item);clickOnSearchResult(index);">
                  <div class="routing-icon-poi">
                    <i class="fal fa-location-circle"></i>
                  </div>
                </div>
              </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
