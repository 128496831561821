import {Component, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {RestService} from '@app/core/services/rest.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@env/environment';
const log = new Logger('RoomBookInfoWindowAdminComponent');

@Component({
  selector: 'room-book-info-window-admin',
  templateUrl: './room-book-info-window-admin.component.html',
  styleUrls: ['./room-book-info-window-admin.component.scss'],
  animations: [
    trigger('roombookAnim', [
      transition('* => *', [
        query('.item', style({ opacity: 0, transform: 'translateX(-40px)' })),

        query('.item', stagger('50ms', [
          animate('0.2s ease-out', keyframes([
            style({opacity: 0, transform: 'translateX(-109px)',     offset: 0}),
            style({opacity: 1, transform: 'translateX(0px)',     offset: 0.5}),
            style({opacity: 1, transform: 'translateX(10px)', offset: 0.75}),
            style({opacity: 1, transform: 'translateX(0)',  offset: 1.0})
          ]))
        ])),
      ])
    ])
  ]
})
export class RoomBookInfoWindowAdminComponent implements OnInit {

  roomPublicToggle: boolean;
  katdata: any;
  cleaningintervals: any = [
    'ohne Reinigung',
    '1x / Woche',
    '2x / Woche',
    '5x / Woche',
    '5,5x / Woche',
    '6x / Woche',
    '7x / Woche',
    '1x / Monat',
    '2x / Monat'
  ];

  reviere: any = [
    'ohne Reinigung',
    'Frührevier Speisesaal',
    'Frührevier Fischer',
    'Spätdienst',
    'Bettenteam',
    'Revier 01 B',
    'Revier 01 C/D',
    'Revier 02 A',
    'Revier 02 B',
    'Revier 02 C/D',
    'Revier 03 A',
    'Revier 03 B',
    'Revier 03 C',
    'Revier 04 A',
    'Revier 04 B',
    'Revier 04 C',
    'Revier 05 A',
    'Revier 05 B',
    'Revier 05 C',
    'Revier 06',
    'Revier WE 07/08',
    'Revier 09+10',
    'vorher Revier 09+10',
    'Neubau EG',
    'Neubau 1. OG',
    'Neubau 2. OG'
  ];

  constructor(public mapService: MapService, private restService: RestService, private toast: ToastrService) {
    if (this.mapService.roomPublic === 'true') {
      this.roomPublicToggle = true;
    } else {
      this.roomPublicToggle = false;
    }
  }

  ngOnInit() {
    this.getCatandColor();
    }

  getCatandColor() {
    this.restService.getCategoriesAndColors().then((data) => {
      console.log(data);
      this.katdata = data;
    });
  }

  setCategory(category: string, farbe: string) {
    this.mapService.roomCategory = category;
    this.mapService.roomColor = farbe;
    this.updateRoombookData();
  }

  setRevier(revier: string) {
    this.mapService.roomRevier = revier;
    this.updateRoombookData();
  }

  setCleaninginterval(interval: string) {
    this.mapService.roomCleaningInterval = interval;
    this.updateRoombookData();
  }

  updateRoombookData() {
    const roomBookData = {
      'r_name': this.mapService.roomName,
      'r_nummer': this.mapService.roomNumber,
      'r_size': this.mapService.roomSize,
      'r_surface': this.mapService.roomSurface,
      'r_public': this.mapService.roomPublic,
      'r_cat': this.mapService.roomCategory,
      'rb_turnus': this.mapService.roomCleaningInterval,
      'GISID': this.mapService.roomGISID,
      'table': this.mapService.activeRoomTable,
      'rb_revier': this.mapService.roomRevier,
      'rb_color': this.mapService.roomColor
    };
    this.restService.updateRoomBookData(roomBookData).then(() => {
      this.mapService.reloadSearchSuggestions();
      this.toast.success('Raum erfolgreich aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });

      /* Hack for reloading a cached tilelayer */
      /* problem: after clearing the tilecache for a layer, it will still be cached on its current zoom */
      /* problem2: timeout needed when zooming back to current level after clearing the cache for some reason*/

       const view = this.mapService.map.getView();
       const zoom = view.getZoom();
       view.setZoom(10);
       const cache = this.mapService.activeWMS.tileCache;
       while (cache.count_ > 0) {
         cache.pop().dispose();
       }
       setTimeout(() => {
         view.setZoom(zoom);
       }, 200);

    }, () => {
      // fail, toast werfen?
    });

  }

  toggleRoomPublic() {
    if (this.roomPublicToggle) {
      this.mapService.roomPublic = 'false';
      this.roomPublicToggle = false;
    } else {
      this.mapService.roomPublic = 'true';
      this.roomPublicToggle = true;
    }
  }
}
