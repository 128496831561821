import { Injectable } from '@angular/core';
import {Observable, Observer, of, Subscriber, from } from 'rxjs';
import {Logger} from '@app/core/services/logger/logger.service';
import {RestService} from '@app/core/services/rest.service';
const log = new Logger('AuthenticationService');
import { Router } from '@angular/router';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
  userrole: string;
  welcomeaccess: boolean;
  welcomeaccess_cleaner: boolean;
  poiwidgetaccess: boolean;
  poiwidgetaccess_admin: boolean;
  layersettingsaccess: boolean;
  roomcleaninginfoaccess: boolean;
  poiinfowindowadmin: boolean;
  poiinfowindow: boolean;
  roombookwindowaccess: boolean;
  welcomeaccess_admin: boolean;
  settingsaccess: boolean;
  error: string;
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

  private _credentials: Credentials | null;


  constructor(public restService: RestService, private router: Router) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  login(context: any, username: any , userroom: any, role: any): Observable<any> {
    if (context === 'cordova') {
      let data: any;
      data = {
        username: username,
        userrole: role,
        userroom:  userroom,
        roominfoaccess: true,
        poiwidgetaccess: true,
        poiwidgetaccess_admin: false,
        welcomeaccess: true,
        welcomeaccess_cleaner: false,
        poiinfowindow: true,
        onlypublicroomaccess: true,
        settingsaccess: false,
      };
      this.setCredentials(data, true);
      this.router.navigate(['/map']);
    } else {
      return Observable.create((observer: Observer<Credentials>) => {
        this.restService.login(context).then((logindata) => {
          let data: any;
          console.log(logindata);

          if (logindata['error']) {
            log.debug('ERROR WITH LOGIN');
            observer.error('error with login');
          }

          if (logindata['token']) {
            if (logindata['role'] === 'admin') {
              data = {
                username: context.username,
                token: logindata['token'],
                userrole: 'admin',
                roominfoaccess: true,
                roombookadminaccess: true,
                roomcleaninginfoaccess: true,
                rooomnotpublicacccess: true,
                layersettingsaccess: true,
                poiinfowindowadmin: true,
                poiwidgetaccess: false,
                poiwidgetaccess_admin: true,
                welcomeaccess: false,
                welcomeaccess_cleaner: false,
                welcomeaccess_admin: true,
                onlypublicroomaccess: false,
                roombookwindowaccess: true,
                settingsaccess: true
              };
            } else if (logindata['role'] === 'employee') {
              data = {
                username: context.username,
                token: logindata['token'],
                userrole: 'employee',
                roominfoaccess: true,
                roombookaccess: true,
                rooomnotpublicacccess: true,
                roomcleaninginfoaccess: false,
                layersettingsaccess: true,
                poiwidgetaccess: false,
                poiwidgetaccess_admin: true,
                poiinfowindow: true,
                welcomeaccess: false,
                welcomeaccess_cleaner: false,
                settingsaccess: false,
              };
            } else if (logindata['role'] === 'cleaner') {
              data = {
                username: context.username,
                token: logindata['token'],
                userrole: 'cleaner',
                roominfoaccess: true,
                roombookaccess: true,
                roomcleaninginfoaccess: true,
                layersettingsaccess: true,
                poiinfowindow: true,
                welcomeaccess: false,
                welcomeaccess_cleaner: true,
                settingsaccess: false,
              };
            } else if (logindata['role'] === 'patient') {
              data = {
                username: context.username,
                token: logindata['token'],
                userrole: 'patient',
                roominfoaccess: true,
                poiwidgetaccess: true,
                poiwidgetaccess_admin: false,
                welcomeaccess: true,
                welcomeaccess_cleaner: false,
                poiinfowindow: true,
                onlypublicroomaccess: true,
                settingsaccess: false,
              };
            }
            // this.setCredentials(data, context.remember);
            this.setCredentials(data, true);
            this.router.navigate(['/map']);
            observer.next(data);
            observer.complete();
          } else {
            data = {
              error: logindata['error'],
              token: 'error',
            };
            // this.setCredentials(data, context.remember);
            this.setCredentials(data, true);
            observer.next(data);
          }
        }, (error) => {
          observer.error('something when wrong');
        });
      });
    }
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.setCredentials();
    this.router.navigate(['/login'], { replaceUrl: true });
    // window.location.reload();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    log.debug('setCredentials', credentials);
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

}
