import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MapService} from '@app/core/services/map.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
import {I18nService} from '@app/core/services/i18n/i18n.service';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('MapFloorLayerSwitchComponent');

@Component({
  selector: 'map-floor-layer-switch',
  templateUrl: './map-floor-layer-switch.component.html',
  styleUrls: ['./map-floor-layer-switch.component.scss'],
  animations: [
    trigger('layerswitcherAnim', [
      transition('void => *', [
        query('.btn', style({ opacity: 0, transform: 'translateY(-50px)' })),
        query('.btn', stagger('-100ms', [
          animate('0.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ]))
      ])
    ])
  ]
})
export class MapFloorLayerSwitchComponent {

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private i18nService: I18nService, public mapService: MapService) {
  }

  getRouteFloors(floorname: any) {
    return  this.mapService.activeRoomFloors.find(element => element === floorname);
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }

}
