import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
const log = new Logger('MapSearchResultComponent');


@Component({
  selector: 'map-search-result',
  templateUrl: './map-search-result.component.html',
  styleUrls: ['./map-search-result.component.scss']
})
export class MapSearchResultComponent {

  filter_rooms = true;
  filter_pois = true;
  filter_only_current_floor = false;
  activeSortingButtonString = 'Sortieren';

  roomImages: object = {
    'Flur': 'assets/img/roominfo/flur.jpg',
    'Bad': 'assets/img/roominfo/bad.jpg',
    'Patientenraum': 'assets/img/roominfo/patientenraum.jpg',
    'Patientenzimmer': 'assets/img/roominfo/patientenraum.jpg',
    'Büro': 'assets/img/roominfo/buero.jpg',
    '1-Bettzimmer': 'assets/img/roominfo/1-bettzimmer.jpg',
    'Lager': 'assets/img/roominfo/lager.jpg',
    'Technik': 'assets/img/roominfo/technik.jpg',
    'WC': 'assets/img/roominfo/wc.jpg',
    'WC H': 'assets/img/roominfo/wc-h.jpg',
    'WC D': 'assets/img/roominfo/wc-d.jpg',
    'Abstellraum': 'assets/img/roominfo/abstellraum.jpg',
    'Arztraum': 'assets/img/roominfo/arztraum.jpg',
    'Vorraum': 'assets/img/roominfo/vorraum.jpg',
    'Lagerraum': 'assets/img/roominfo/lager.jpg',
    'Aufzug': 'assets/img/roominfo/aufzug.jpg',
    'Teeküche': 'assets/img/roominfo/teekueche.jpg',
    'TH': 'assets/img/roominfo/treppenhaus.jpg',
    'Speisesaal': 'assets/img/roominfo/speisesaal.jpg',
    'Kein Name vergeben': 'assets/img/roominfo/404.jpg'
  };

  constructor(public mapService: MapService, config: NgbDropdownConfig) {
    config.autoClose = 'outside';
  }

  setSearchSorting(sortingType: string) {
    switch (sortingType) {
      case 'relevance':
        this.mapService.searchSortBy = 'relevance';
        this.activeSortingButtonString = 'Relevanz';
        break;
      case 'asc':
        this.mapService.searchSortBy = 'asc';
        this.activeSortingButtonString = 'Aufsteigend';
        break;
      case 'desc':
        this.mapService.searchSortBy = 'desc';
        this.activeSortingButtonString = 'Absteigend';
        break;
    }
    this.mapService.refreshSearchResults();
  }

  setFilterRooms() {
    this.filter_rooms = !this.filter_rooms;
    this.setSearchFilter();
  }

  setFilterPOI() {
    this.filter_pois = !this.filter_pois;
    this.setSearchFilter();
  }

  setFilterOnlyCurrentFloor() {
    this.filter_only_current_floor = !this.filter_only_current_floor;
    this.setSearchFilter();
  }

  resetFilter() {
    this.filter_rooms = true;
    this.filter_pois = true;
    this.filter_only_current_floor = false;
    this.mapService.search_filter_active = false;
    this.mapService.searchFilterBy = 'all';
    this.mapService.searchSortBy = 'relevance';
    this.activeSortingButtonString = 'Sortieren';
    this.mapService.searchFilterdFloor = 'all';
    this.mapService.refreshSearchResults();
  }

  setSearchFilter() {
    // Filter for Rooms and Pois
    if (this.filter_rooms && this.filter_pois) {
      this.mapService.searchFilterBy = 'all';
    } else if (this.filter_rooms && !this.filter_pois) {
      this.mapService.searchFilterBy = 'rooms';
    } else if (!this.filter_rooms && this.filter_pois) {
      this.mapService.searchFilterBy = 'pois';
    } else {
      this.mapService.searchFilterBy = 'none';
    }
    // Filter for Floor Table
    if (this.filter_only_current_floor) {
      this.mapService.searchFilterdFloor = this.mapService.activeRoomTable;
    } else {
      this.mapService.searchFilterdFloor = 'all';
    }
    this.mapService.search_filter_active = true;
    this.mapService.refreshSearchResults();
  }

  clickOnSearchResult(ID: number) {
    const format = new WKT();
    const clickedResult = this.mapService.search_result_data[ID];
    let multiPolygon: any;
    let x: any;
    let y: any;
    this.mapService.layer_selected_room.getSource().clear();
    if (clickedResult.table !== this.mapService.activeRoomTable) {
      this.mapService.layer_search_results.getSource().clear();
      const floorNumber = parseFloat(clickedResult.rb_ebene);
      this.mapService.switchToLayer(floorNumber);
    }

    if (clickedResult.search_type === 'room') {
      multiPolygon = clickedResult.st_astext;

      // TODO Values swap into one Method
      this.mapService.roomName = clickedResult.r_name;
      this.mapService.roomNumber = clickedResult.r_nummer;
      this.mapService.roomSurface = clickedResult.rb_belag;
      this.mapService.roomSize = clickedResult.rb_groesse;
      this.mapService.roomCleaningInterval = clickedResult.rb_turnus;
      this.mapService.roomRevier = clickedResult.rb_revier;
      this.mapService.roomComment = clickedResult.rb_bemerk;
      this.mapService.roomPublic = clickedResult.r_public;
      this.mapService.roomCategory = clickedResult.r_cat;
      this.mapService.roomClicked = true;
      x = parseFloat(clickedResult.st_x);
      y = parseFloat(clickedResult.st_y);

      const feature = format.readFeatures(multiPolygon);
      this.mapService.activeRoomNextFloorTable = null;
      this.mapService.layer_selected_room.setVisible(true);
      this.mapService.layer_selected_room.getSource().addFeatures(feature);
      const thisref = this;
      // this.mapService.clickedFeaturesOnMap = multiPolygon;
      this.mapService.zoomMapToCords(x, y, 20, function () {
        thisref.mapService.flashpoly(multiPolygon);
      });
    } else if (clickedResult.search_type === 'poi') {
      x = parseFloat(clickedResult.st_x);
      y = parseFloat(clickedResult.st_y);
      this.mapService.poiClicked = true;
      this.mapService.activeRoomNextFloorTable = null;
      // for flash
      const thisref = this;
      this.mapService.poiName = clickedResult.r_name;
      this.mapService.poiCat = clickedResult.cat;
      // this.mapService.poiDetails = clickedResult.details;
      // build a temp feature
      const newMarker = new Feature(new Point([x, y]));
      this.mapService.zoomMapToCords(x, y, 20, function () {
        thisref.mapService.flash(newMarker);
      });
    }
  }

  hoverEnterOnSearchResult(ID: number) {
    this.mapService.layer_selected_room.getSource().clear();
    const thisref = this;
    const format = new WKT();
    const hoveredSearchResult = this.mapService.search_result_data[ID];
    const floorTable = hoveredSearchResult.table;
    if (hoveredSearchResult.search_type === 'room') {
      const multiPolygon = hoveredSearchResult.st_astext;
      if (floorTable === this.mapService.activeRoomTable) {
        const feature = format.readFeatures(multiPolygon);
        this.mapService.layer_selected_room.setVisible(true);
        thisref.mapService.layer_selected_room.getSource().addFeatures(feature);
      } else {
        this.mapService.activeRoomNextFloorTable = floorTable;
      }
    } else if (hoveredSearchResult.search_type === 'poi') {
      if (floorTable === this.mapService.activeRoomTable) {
        // Todo Scale Icons
      } else {
        this.mapService.activeRoomNextFloorTable = floorTable;
      }
    }
  }

  hoverLeaveOnSearchResult() {
    this.mapService.layer_selected_room.getSource().clear();
    this.mapService.activeRoomNextFloorTable = '';
  }

}
