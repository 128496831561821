import {Component, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
const log = new Logger('RoomNameInfoComponent');

@Component({
  selector: 'room-name-info',
  templateUrl: './room-name-info.component.html',
  styleUrls: ['./room-name-info.component.scss'],
  animations: [
    trigger('s_background_imageAnim', [
      transition('* => *', [
        query('.header-image', style({ opacity: 0, filter: 'blur(5px)'})),

        query('.header-image', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, filter: 'blur(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('s_background_textAnim', [
      transition('* => *', [
        query('.roomtext', style({ opacity: 0, transform: 'translateX(-500px)' })),

        query('.roomtext', stagger('300ms', [
          animate('0.4s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(20px)',  offset: 0.5}),
            style({opacity: 1, transform: 'translateX(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ])
  ]
})
export class RoomNameInfoComponent implements OnInit {

  private roomImages: object = {
    'Flur': 'assets/img/roominfo/flur.jpg',
    'Bad': 'assets/img/roominfo/bad.jpg',
    'Patientenraum': 'assets/img/roominfo/patientenraum.jpg',
    'Patientenzimmer': 'assets/img/roominfo/patientenraum.jpg',
    'Büro': 'assets/img/roominfo/buero.jpg',
    '1-Bettzimmer': 'assets/img/roominfo/1-bettzimmer.jpg',
    'Lager': 'assets/img/roominfo/lager.jpg',
    'Technik': 'assets/img/roominfo/technik.jpg',
    'WC': 'assets/img/roominfo/wc.jpg',
    'WC H': 'assets/img/roominfo/wc-h.jpg',
    'WC D': 'assets/img/roominfo/wc-d.jpg',
    'Abstellraum': 'assets/img/roominfo/abstellraum.jpg',
    'Arztraum': 'assets/img/roominfo/arztraum.jpg',
    'Vorraum': 'assets/img/roominfo/vorraum.jpg',
    'Lagerraum': 'assets/img/roominfo/lager.jpg',
    'Aufzug': 'assets/img/roominfo/aufzug.jpg',
    'Teeküche': 'assets/img/roominfo/teekueche.jpg',
    'TH': 'assets/img/roominfo/treppenhaus.jpg',
    'Speisesaal': 'assets/img/roominfo/speisesaal.jpg',
    'Kein Name vergeben': 'assets/img/roominfo/404.jpg'
  };

  roomImageURL = this.roomImages['Kein Name vergeben'];

  constructor(public mapService: MapService) {
    log.debug('constructor room-name-info');
  }

  ngOnInit(): void {
    log.debug('on init room-name-info');
    const roomName = this.mapService.roomName;
    const roomImageKeys = Object.keys(this.roomImages);
    if (roomName === null) {
      this.mapService.roomName = 'Kein Name vergeben';
      this.roomImageURL = this.roomImages['Kein Name vergeben'];
    } else {
      if (this.mapService.roomName) {
        for (let i = 0; i < roomImageKeys.length; i++) {
            if ((this.mapService.roomName.indexOf(roomImageKeys[i]) !== -1)) {
              this.roomImageURL = this.roomImages[roomImageKeys[i]];
              return;
            }
          }
        }
      }
    }
}
