import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    // MainRoutingModule
  ],
  declarations: [
    MainComponent
  ]
})
export class MainModule { }
