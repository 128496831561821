<div @s_background_imageAnim *ngIf="!mapService.roomClicked">
  <div  class="sidebar-background" >
  </div>
</div>
<ng-container *ngIf="mapService.welcome_open">
<div class="card" @componentAnim>
  <div class="">
    <div class="card-body">
      <img class="img-fluid" src="assets/img/logo.png"/>
      <h3 class="component text-center">
        Herzlich Willkommen <br />{{username}}
      </h3>
      <p class="component text-center"><br />
        Hier sehen Sie alle zur Verfügung stehenden Funktionen. Per Mausklick können Sie sich zusätzliche Informationen zu Räumen oder interessanten Orten anzeigen lassen.<br />
      </p>
      <!-- <button (click)="mapService.welcome_open = false" class="btn btn-outline-info text-center component col-12"><a href="#">nicht wieder anzeigen</a>
      </button> -->
      <button (click)="startTour()" class="btn btn-outline-primary text-center component col-12">Funktionen erneut ansehen
      </button>
    </div>
  </div>
</div>
</ng-container>
<routing-widget></routing-widget>
