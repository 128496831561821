export class ConfigService {

  static geoServerUrl = 'https://geoserver.indoorplan.de';
  static restBaseUrl = 'https://dianaklinikapi.indoorplan.de';
  // static restBaseUrl = 'http://localhost:81/diana_api_refactor';
  static loadingScreenActive: boolean = false;

  static poiIconPaths = {
    bett: 'assets/img/poi/bed.png',
    doc: 'assets/img/poi/doc.png',
    door: 'assets/img/poi/entry.png',
    drop: 'assets/img/poi/drop.png',
    elevator: 'assets/img/poi/elevator.png',
    medical: 'assets/img/poi/medical.png',
    parkplatz: 'assets/img/poi/parkplatz.png',
    ramp: 'assets/img/poi/wheelchair.png',
    toilettem: 'assets/img/poi/wcm.png',
    toilettew: 'assets/img/poi/wcf.png',
    treppe: 'assets/img/poi/stairs.png',
    tresen: 'assets/img/poi/tresen.png',
    food: 'assets/img/poi/food.png',
    lab: 'assets/img/poi/lab.png',
    pills: 'assets/img/poi/pills.png',
    swimming: 'assets/img/poi/swimming.png',
    syringe: 'assets/img/poi/syringe.png',
    bathtub: 'assets/img/poi/bathtub.png'
  };

  static routeIconPaths = {
    start: 'assets/img/route/start.png',
    end: 'assets/img/route/end.png',
    stairup: 'assets/img/route/stair-up.png',
    stair: 'assets/img/route/stair.png',
    stairdown: 'assets/img/route/stair-down.png',
    elevatorup: 'assets/img/route/elevator-up.png',
    elevator: 'assets/img/route/elevator.png',
    elevatordown: 'assets/img/route/elevator-down.png',
    turn: 'assets/img/route/turn.png'
  };

}
