<div @s_background_imageAnim *ngIf="!mapService.roomClicked">
  <div  class="sidebar-background" >
  </div>
</div>
<div class="container">
  <div @componentAnim>
    <div class="component">
      <div class="card-body">
        <div class="col-12  text-center" @s_background_textAnim>
          <img class="img-fluid" src="assets/img/logo.png"/>
          <span class="fatfont logotext">DIANA</span><span class="logotext">MAPS</span><br />
          ADMINISTRATIONSBEREICH
        </div>
      </div>
    </div>
  </div>
</div>
