/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./room-name-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./room-name-info.component";
import * as i3 from "../../../../services/map.service";
var styles_RoomNameInfoComponent = [i0.styles];
var RenderType_RoomNameInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoomNameInfoComponent, data: { "animation": [{ type: 7, name: "s_background_imageAnim", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ".header-image", animation: { type: 6, styles: { opacity: 0, filter: "blur(5px)" }, offset: null }, options: null }, { type: 11, selector: ".header-image", animation: { type: 12, timings: "200ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, filter: "blur(0px)", offset: 1 }, offset: null }] }, timings: "0.8s ease-out" }] }, options: null }], options: null }], options: {} }, { type: 7, name: "s_background_textAnim", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ".roomtext", animation: { type: 6, styles: { opacity: 0, transform: "translateX(-500px)" }, offset: null }, options: null }, { type: 11, selector: ".roomtext", animation: { type: 12, timings: "300ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateX(20px)", offset: 0.5 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateX(0px)", offset: 1 }, offset: null }] }, timings: "0.4s ease-out" }] }, options: null }], options: null }], options: {} }] } });
export { RenderType_RoomNameInfoComponent as RenderType_RoomNameInfoComponent };
export function View_RoomNameInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "roomimagecontainer"]], [[24, "@s_background_imageAnim", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "fadeout"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["class", "header-image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "roominfo-text"]], [[24, "@s_background_textAnim", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "title-room roomtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "title-number roomtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n\n\n\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.roomImageURL; _ck(_v, 4, 0, currVal_1); var currVal_2 = undefined; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.mapService.roomName; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.mapService.roomNumber; _ck(_v, 13, 0, currVal_4); }); }
export function View_RoomNameInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "room-name-info", [], null, null, null, View_RoomNameInfoComponent_0, RenderType_RoomNameInfoComponent)), i1.ɵdid(1, 114688, null, 0, i2.RoomNameInfoComponent, [i3.MapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomNameInfoComponentNgFactory = i1.ɵccf("room-name-info", i2.RoomNameInfoComponent, View_RoomNameInfoComponent_Host_0, {}, {}, []);
export { RoomNameInfoComponentNgFactory as RoomNameInfoComponentNgFactory };
