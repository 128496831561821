import { OnInit } from '@angular/core';
import { MapService } from '@app/core/services/map.service';
import { Logger } from '@app/core/services/logger/logger.service';
var log = new Logger('WelcomeWidgetComponent');
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { JoyrideService } from 'ngx-joyride';
var WelcomeWidgetComponent = /** @class */ (function () {
    function WelcomeWidgetComponent(mapService, authenticationService, joyrideService) {
        this.mapService = mapService;
        this.authenticationService = authenticationService;
        this.joyrideService = joyrideService;
    }
    WelcomeWidgetComponent.prototype.ngOnInit = function () {
    };
    WelcomeWidgetComponent.prototype.startTour = function () {
        var _this = this;
        this.mapService.tutorialActive = false;
        this.joyrideService.startTour({
            themeColor: '#000000',
            steps: [
                'homeButton',
                'suchFeld@map',
                'suchButton@map',
                'recordButton@map',
                'poiCategorys@map',
                'sidebarCloseButton@map',
                'meinraumButton@map',
                'rotationButton@map',
                'zoominButton@map',
                'zoomoutButton@map',
                'layerSwitcher@map'
            ]
        }).subscribe(function (step) {
            // je nachdem welcher step aufgerufen wird, kann man aktionen ausführen
            // wenn elemte aufgerufen werden sollen, die bei appstart mit *ngIf verdeckt sind,
            // muss möglicherweise der joyride array neu geladen werden  this.joyrideService.refresh();
            if (step.name === 'meinraumButton') {
                _this.mapService.infowindowhidden = true;
            }
        });
    };
    Object.defineProperty(WelcomeWidgetComponent.prototype, "username", {
        get: function () {
            var credentials = this.authenticationService.credentials;
            return credentials ? credentials.username : null;
        },
        enumerable: true,
        configurable: true
    });
    return WelcomeWidgetComponent;
}());
export { WelcomeWidgetComponent };
