<div class="container">
  <form (change)="updateRoombookData();">
    <div class="card" @roombookAnim>
      <div class="card-foto">
      </div>
      <div class="card-header">
        <h5>
          <i class="fas fa-map-marker-alt"></i> Raumbuch
        </h5>
      </div>
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Revier</label>
          </div>
            <div ngbDropdown ngbDropdownReposition class="d-inline-block col-6 mb-2" >
              <button class="btn btn-dropdown" id="dropdown-1" ngbDropdownToggle>{{mapService.roomRevier ? mapService.roomRevier : 'nicht zugeteilt'}}</button>
              <div ngbDropdownMenu aria-labelledby="dropdown-1">
                <button class="dropdown-item" *ngFor="let obj of reviere" (click)="setRevier(obj)">{{obj}}</button>
              </div>
            </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Raumname</label>
          </div>
          <div class="col-6 mb-2">
            <input name="name" type="text" class="form-control" [(ngModel)]="mapService.roomName" placeholder="{{mapService.roomName}}" value="{{mapService.roomName}}" />
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Raumnummer</label>
          </div>
          <div class="col-6 mb-2">
            <input name="nummer" type="text" class="form-control" [(ngModel)]="mapService.roomNumber" placeholder="{{mapService.roomNumber}}" value="{{mapService.roomNumber}}" />
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Turnus</label>
          </div>
            <div ngbDropdown ngbDropdownReposition class="d-inline-block col-6 mb-2" >
              <button class="btn btn-dropdown" id="dropdown-2" ngbDropdownToggle>{{mapService.roomCleaningInterval ? mapService.roomCleaningInterval : 'nicht zugeteilt'}}</button>
              <div ngbDropdownMenu aria-labelledby="dropdown-2">
                <button class="dropdown-item" *ngFor="let obj of cleaningintervals" (click)="setCleaninginterval(obj)">{{obj}}</button>
              </div>
            </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Raumgrösse</label>
          </div>
          <div class="col-6 mb-2">
            <input name="groesse" type="text" class="form-control" [(ngModel)]="mapService.roomSize" placeholder="{{mapService.roomSize}}" value="{{mapService.roomSize}}" />
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Bodenbelag</label>
          </div>
          <div class="col-6 mb-2">
            <input name="flaeche" type="text" class="form-control" [(ngModel)]="mapService.roomSurface" placeholder="{{mapService.roomSurface}}" value="{{mapService.roomSurface}}" />
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Sichtbarkeit</label>
          </div>

          <div class="col-6 mb-2">
            <!--
            <div class="btn-group btn-group-toggle">
              <label class="sichtbar-btn" ngbButtonLabel>
                <input name="sichtbarkeit" class="hidden" type="checkbox" ngbButton [value]="mapService.roomPublic" [ngModel]="mapService.roomPublic== '1' ? true : mapService.roomPublic == '0' ? false : null" (ngModelChange)="mapService.roomPublic = $event ? 1 : 0" />
                {{mapService.roomPublic== '1' ? 'sichtbar' : mapService.roomPublic == '0' ? 'nicht sichtbar' : 0}}
              </label>
            </div>
            -->
            <span class="switch switch-sm">
              <input type="checkbox"  name="roomPublic" class="switch" id="switch-sm1" [checked]="roomPublicToggle" [ngModel]="roomPublicToggle" (click)="toggleRoomPublic()"/>
              <label class="mb-0" for="switch-sm1"></label>
            </span>
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Kategorie</label>
          </div>
          <div ngbDropdown ngbDropdownReposition class="d-inline-block col-6 mb-2" >
            <button class="btn btn-dropdown" id="dropdown-3" ngbDropdownToggle>{{mapService.roomCategory ? mapService.roomCategory : 'Keine Kategorie'}}</button>
            <div ngbDropdownMenu class="dropdown-short" aria-labelledby="dropdown-3">
              <button class="dropdown-item" (click)="setCategory('', '')">Keine Kategorie</button>
            <div  *ngFor="let item of katdata">
                <button class="dropdown-item" (click)="setCategory(item.kategorie, item.farbe)">{{item.kategorie}}</button>
            </div>
             <!-- <button class="dropdown-item" (click)="setCategory('Keine Kategorie')">Keine Kategorie</button>
              <button class="dropdown-item" (click)="setCategory('Patientenzimmer')">Patientenzimmer</button>
              <button class="dropdown-item" (click)="setCategory('Diagnostik')">Diagnostik</button>
              <button class="dropdown-item" (click)="setCategory('Pflegestützpunkt/Büro')">Pflegestützpunkt/Büro</button>
              <button class="dropdown-item" (click)="setCategory('Arztzimmer')">Arztzimmer</button>
              <button class="dropdown-item" (click)="setCategory('Gruppenraum')">Gruppenraum</button>
              <button class="dropdown-item" (click)="setCategory('Therapie')">Therapie</button>
              <button class="dropdown-item" (click)="setCategory('Lager')">Lager</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
