import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import TileWMS from 'ol/source/TileWMS';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import WebGLMap from 'ol/WebGLMap';
import Fill from 'ol/style/Fill';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import MultiPolygon from 'ol/geom/MultiPolygon';
import GeoJSON from 'ol/format/GeoJSON';
import {containsXY} from 'ol/extent';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import TileLayer from 'ol/layer/Tile';
import WFS from 'ol/format/WFS';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import View from 'ol/View';
import WKT from 'ol/format/WKT';
import Icon from 'ol/style/Icon';
import Text from 'ol/style/Text';
import Observable from 'ol/Observable';
import {RestService} from '@app/core/services/rest.service';
import {ConfigService} from '@app/core/services/config.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
import {BehaviorSubject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import OSM from 'ol/source/OSM';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import {DragBox, Select} from 'ol/interaction';
import {altKeyOnly, platformModifierKeyOnly, pointerMove} from 'ol/events/condition';
import Heatmap from 'ol/layer/Heatmap';
import {toLonLat} from 'ol/proj';

const log = new Logger('MapService');

@Injectable({
  providedIn: 'root'
})
export class MapService {

  wifiwizard2 = (<any> window).WifiWizard2;
  tracking_mode = false;

  isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;

  loading = true;
  layerswitcher_loading = false;
  infowindowhidden = true;

  searchSaved = false;
  roomClicked = false;
  beaconClicked = false;
  poiClicked = false;
  routeSegmentClicked = false;
  roomName: any;
  roomNumber: any;
  roomSurface: any;
  roomSize: any;
  roomCleaningInterval: any;
  roomCleaningStatus: any;
  roomRevier: any;
  roomComment: any;
  roomPublic: any;
  roomCategory: any;
  roomGISID: any;
  roomTable: any;
  roomColor: any;

  clickedPOIFeature: any;
  poiName: any;
  poiDetails: any;
  poiCat: any;
  poiIcon: any;
  poiTable: any;

  createPOICoordinateX: number;
  createPOICoordinateY: number;

  quote: string;
  isLoading: boolean;

  /* map reference */
  map: any = 'map';

  /* map view reference */
  map_view: any;

  source_wms_eg00: any;
  source_wms_eg01: any;
  source_wms_eg01_3d: any;
  source_wms_og1: any;
  source_wms_og2: any;
  source_wms_og3: any;
  source_wms_og4: any;
  source_wms_og5: any;
  source_wms_og6: any;
  source_wms_og7: any;
  source_wms_og8: any;
  source_wms_og9: any;
  source_wms_og10: any;
  source_wms_og11: any;

  /* wms ressources / map layer */
  layer_wms_eg00: any;
  layer_wms_eg01: any;
  layer_wms_eg01_3d: any;
  layer_wms_og1: any;
  layer_wms_og2: any;
  layer_wms_og3: any;
  layer_wms_og4: any;
  layer_wms_og5: any;
  layer_wms_og6: any;
  layer_wms_og7: any;
  layer_wms_og8: any;
  layer_wms_og9: any;
  layer_wms_og10: any;
  layer_wms_og11: any;

  layer_outline_eg00: any;
  layer_outline_eg01: any;
  layer_outline_og1: any;
  layer_outline_og2: any;
  layer_outline_og3: any;
  layer_outline_og4: any;
  layer_outline_og5: any;
  layer_outline_og6: any;
  layer_outline_og7: any;
  layer_outline_og8: any;
  layer_outline_og9: any;
  layer_outline_og10: any;
  layer_outline_og11: any;

  /* routing Layer */

  layer_routing_eg00: any;
  layer_routing_eg01: any;
  layer_routing_og01: any;
  layer_routing_og02: any;
  layer_routing_og03: any;
  layer_routing_og04: any;
  layer_routing_og05: any;
  layer_routing_og06: any;
  layer_routing_og07: any;
  layer_routing_og08: any;
  layer_routing_og09: any;
  layer_routing_og10: any;
  layer_routing_og11: any;

  layer_navigation_routes_bg: any;
  layer_navigation_routes: any;
  layer_route_icons: any;
  layer_routing_edit_selection: any;


  // patientenparams

  source_wms_eg00_params: any;
  source_wms_eg01_params: any;
  source_wms_og01_params: any;
  source_wms_og02_params: any;
  source_wms_og03_params: any;
  source_wms_og04_params: any;
  source_wms_og05_params: any;
  source_wms_og06_params: any;
  source_wms_og07_params: any;
  source_wms_og08_params: any;
  source_wms_og09_params: any;
  source_wms_og10_params: any;
  source_wms_og11_params: any;
  outline_eg00_params: any;
  outline_eg01_params: any;
  // layer_grey_background: any;

  /* layer for points of interest */
  layer_points_of_interest: any;

  layer_search_results: any;

  layer_selected_room: any;

  layer_selected_POI: any;

  selected_route_segments: any = [];

  layer_room_names: any;

  layer_cleaning_status: any;

  layer_user_rooms: any;

  layer_user_position: any;

  layer_beacons: any;
  layer_beacons_heatmap: any;

  layer_openstreetmap_background: any;

  radiusfunction: any;

  /* route line segments of last get route api call */
  navigation_line_segment_features: any;

  /* active wms ressource */
  activeWMS: any;
  activeWMSRequestURL: any;

  /*activevVector Source */
  activeVectorSource: any;

  /* active room polygon table name/reference */
  activeRoomTable: any = 'public.DIANA_EG01_POLY';

  /* if requested room is on another floor */
  activeRoomNextFloorTable: any;

  activeRoomsTable: any = [];

  /* active floor number */
  activeFloorNumber: any = 1;

  activeRoomFloors: any = [];

  /* global navigation iables */
  navigationStartCoordinate: any;
  navigationEndCoordinate: any;

  navigationStartFloorNumber: any;
  navigationEndFloorNumber: any;

  set_navigation_start = false;
  set_navigation_end = false;

  /* toggles / settings */
  poi_widget_open = false;
  show_poi = false;
  show_beacons = false;
  show_backgroundmap = true;
  setting_show_poi_all = false;
  setting_show_poi_freizeit = false;
  setting_show_poi_pflege = false;
  setting_show_poi_eingaenge_ausgaenge = false;
  setting_show_poi_therapie = false;
  setting_show_poi_treppen_aufzuege = false;
  setting_search_active = false;
  setting_search_marker_active = false;
  setting_side_menu_open = false;
  setting_show_route_network = false;
  setting_side_menu_reinigungsmanagement_open = false;
  setting_show_room_names = false;
  setting_show_cleaning_status = false;

  setting_animation_duration_bounce: any = 500;
  setting_animation_duration_flash: any = 3000;
  setting_css_animation_classes: any = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

  setting_global_house_filter: any = 'all';
  setting_sort_order: any = 'down';
  setting_show_roombook = false;

  /* Welcome window */
  welcome_open = true;

  /* Styling */
  searchbaractive = false;


  // Context Menu
  contextmenuLeft: any = 0;
  contextmenuTop: any = 0;
  contextMenuOpen = false;
  contextMenuEdit = false;
  contextMenuEditBeacon = false;
  contextmenuCoord: any = 0;

  /* popup overlay reference */
  popup_points_of_interest: any;

  /* stuff that needs to be cleaned up */
  clickedFeaturesOnMap: any;
  poiFeatures: Feature[];
  beaconFeatures: Feature[];
  beaconName: any;
  beaconBSSID1: any;
  beaconBSSID2: any;
  beaconBSSID3: any;
  beaconBSSID4: any;
  beaconTXPower: any;
  beaconInactive: any;
  displayed_poiFeatures: any = [];
  clickedBeaconFeature: any;

  /* Possible Search Service */
  searchInputString: string;
  search_result_data: any = [];
  search_suggestions: any;
  search_results_hidden = true;
  search_filter_active = false;
  searchFilterBy = 'all';
  searchSortBy = 'relevance';
  searchFilterdFloor = 'all';
  roombookfiltered = false;

  dropdown_selected_district: any = 'Revier wählen';
  dropdown_selected_status: any = 'Status';
  cleaning_welcome_selection = false;

  /* Navigation */
  NaviStart: any;
  NaviStartFloor: any;
  NaviEnd: any;
  NaviEndFloor: any;
  NaviStartName: any;
  NaviEndName: any;
  NaviStartNumber: any;
  NaviEndNumber: any;
  NaviLength: any;
  NaviTime: any;
  NaviRouteSegments: any;
  routeComponentshown: any = false;

  navigationMode: any = false;

  show_routeAccessible: any = false;
  show_routePublic: any = true;

  select_interaction: any;
  dragBox: any;

  RoomTexture = 'assets/img/roompanos/6.679.jpg';
  rooms_data: any;
  lazyArray = [];
  sum = 100;
  throttle = 150;
  scrollDistance = 2;
  scrollUpDistance = 2;
  clicked_row: number;
  clickedResult: any;
  onlyEmptyRooms = false;
  roombookFilterdFloor = 'all';
  roombooksearchSortBy = 'asc';

  user_room_shown = false;
  user_room: any;
  tutorialActive = false;

  stationinfo: any = 'Psychosomatik, Speisesaal, MVZ, EKG, Lehrküche, Ernährungsberatung, Bewegungsbäder, Diagnostik, Röntgen, Neuropsychologie, Therapiebereiche';

  clickedPoiFeatureSubject = new BehaviorSubject(null);
  clickedBeaconFeatureSubject = new BehaviorSubject(null);

  directiveRef: any;

  constructor(private httpClient: HttpClient,
              private restService: RestService,
              private authService: AuthenticationService,
              private toast: ToastrService) {
  }


  initMap() {

    this.layer_openstreetmap_background = new TileLayer({
      opacity: 1,
      visible: true,
      source: new OSM()
    });

    const routing_style = new Style({
      stroke: new Stroke({
        color: 'rgb(29, 60, 131)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(29, 60, 131, 0.3)'
      })
    });
    // different params for patientent
    this.layer_outline_eg00 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: this.outline_eg00_params,
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_eg01 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: this.outline_eg01_params,
        serverType: 'geoserver'
      }),
      visible: true
    });
    this.layer_outline_og1 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG1_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og2 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG2_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og3 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG3_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og4 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG4_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og5 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG5_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og6 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG6_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og7 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG7_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og8 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG8_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og9 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG9_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og10 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG10_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });
    this.layer_outline_og11 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'Diana:UMRISS_OG11_GRP'},
        serverType: 'geoserver'
      }),
      visible: false
    });

    /* room polygone wms sources/layer */
    // different params for patientent
    this.source_wms_eg00 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_eg00_params,
      serverType: 'geoserver'
    });

    this.layer_wms_eg00 = new TileLayer({
      source: this.source_wms_eg00,
      // preload: Infinity,
      visible: false
    });

    // different params for patientent
    this.source_wms_eg01 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_eg01_params,
      serverType: 'geoserver'
    });
    this.layer_wms_eg01 = new TileLayer({
      source: this.source_wms_eg01,
      // preload: Infinity,
      visible: true
    });

    // 3d example

    this.source_wms_eg01_3d = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: {'LAYERS': '3dtestgroup'},
      serverType: 'geoserver'
    });
    this.layer_wms_eg01_3d = new TileLayer({
      source: this.source_wms_eg01_3d,
      // preload: Infinity,
      visible: false
    });

    this.source_wms_og1 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og01_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og1 = new TileLayer({
      source: this.source_wms_og1,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og2 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og02_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og2 = new TileLayer({
      source: this.source_wms_og2,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og3 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og03_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og3 = new TileLayer({
      source: this.source_wms_og3,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og4 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og04_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og4 = new TileLayer({
      source: this.source_wms_og4,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og5 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og05_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og5 = new TileLayer({
      source: this.source_wms_og5,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og6 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og06_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og6 = new TileLayer({
      source: this.source_wms_og6,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og7 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og07_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og7 = new TileLayer({
      source: this.source_wms_og7,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og8 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og08_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og8 = new TileLayer({
      source: this.source_wms_og8,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og9 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og09_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og9 = new TileLayer({
      source: this.source_wms_og9,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og10 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og10_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og10 = new TileLayer({
      source: this.source_wms_og10,
      // preload: Infinity,
      visible: false
    });
    this.source_wms_og11 = new TileWMS({
      url: ConfigService.geoServerUrl + '/geoserver/wms',
      params: this.source_wms_og11_params,
      serverType: 'geoserver'
    });
    this.layer_wms_og11 = new TileLayer({
      source: this.source_wms_og11,
      // preload: Infinity,
      visible: false
    });

    /* routing layer */
    /*
    this.layer_routing_eg00 = new TileLayer({
      source: new TileWMS({
        url: ConfigService.geoServerUrl + '/geoserver/wms',
        params: {'LAYERS': 'DIANA_EG0_LINE_NETWORK'},
        serverType: 'geoserver'
      }),
      visible: false
    });
*/
    this.layer_beacons = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any, resolution: any) {
        return beacon_icon(feature, resolution);
      },
      visible: true
    });

    this.layer_beacons_heatmap = new Heatmap({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      blur: parseInt('70', 10),
      radius: parseInt('70', 10),
      gradient: ['#e93e3a', '#ed683c' , '#f3903f' , '#fdc70c'],
      weight: function (feature: any) {
        const view = thisref.map.getView();
        const zoom = view.getZoom();
        const meter = 5;
        return  meter * zoom;
      },
      visible: true
    });

    this.radiusfunction = function() {
      const view = thisref.map.getView();
      const zoom = view.getZoom();
      return parseInt(zoom, 10);
    };

    this.layer_user_position = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any) {
        return location_icon(feature);
      },
      visible: true
    });

    this.layer_routing_edit_selection = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any) {
        return getRouteEditSelectionStyle(feature);
      },
      visible: true
    });

    this.layer_routing_eg00 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_EG0_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_eg01 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_EG01_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og01 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG01_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og02 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG02_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og03 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG03_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og04 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG04_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og05 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG05_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og06 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG06_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og07 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG07_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og08 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG08_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og09 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG09_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og10 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG10_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    this.layer_routing_og11 = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: function(extent: any) {
          return ConfigService.geoServerUrl + '/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=DIANA_OG11_LINE_NETWORK&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' + extent.join(',') + ',EPSG:3857';
        },
        strategy: bboxStrategy
      }),
      style: function (feature: any) {
        return getRouteEditStyle(feature);
      },
      visible: false
    });

    const routestyle_bg = [
      new Style({
        stroke: new Stroke({
          color: 'rgba(26,104,210,1)',
          width: 6
        })
      }),
    ];

    const routestyle = [
      new Style({
        stroke: new Stroke({
          color: 'rgba(102,157,246,1)',
          width: 4
        })
      })
    ];

    // -_-
    const thisref: any = this;

    function getRouteStyle_bg(floor: any) {
      if (floor === thisref.activeFloorNumber) {
        return routestyle_bg;
      }
      if (floor !== thisref.activeFloorNumber) {
        return new Style({
          stroke: new Stroke({
            color: 'rgba(0,179,253,0)',
            width: 3,
            lineDash: [5, 5]
          })
        });
      }
    }

    function location_icon(feature: any) {
     const circle_distance = feature.get('distance');
     return new Style({
       image: new Icon({
         opacity: 1 ,
         src: 'assets/img/live-location.png'
       })
     });
    }

    function beacon_icon(feature: any, resolution: number) {
      const view = thisref.map.getView();
      const zoom = view.getZoom();
      let scale: any = 0;
      const name = feature.get('name');
      // todo: set circle with distance
      // const circle_distance = feature.get('distance');
      switch (true) {
        case (zoom > 20): { scale = 1; break; }
        case (zoom > 19): { scale = 0.6; break; }
        case (zoom > 18): { scale = 0.4; break; }
        case (zoom > 17): { scale = 0.3; break; }
        case (zoom > 16): { scale = 0.2; break; }
      }
      const viewProjection = thisref.map.getView().getProjection();
      const coordsInViewProjection = (<Point>(feature.getGeometry())).getCoordinates();
      const longLat = toLonLat(coordsInViewProjection, viewProjection);
      const latitude_rad = longLat[1] * Math.PI / 180.;
      const inactive = feature.get('inactive');
      let colorRadius = '';
      if (inactive) {
        colorRadius = 'rgba(204,46,46,0.2)';
      } else {
        colorRadius = 'rgba(46, 204, 113,0.2)';
      }
      return [
        new Style({
          image: new Circle({
            fill: new Fill({
              color: colorRadius
            }),
            stroke: new Stroke({
              color: '#3399CC',
              width: 1.25
            }),
            radius: 10 / (resolution / viewProjection.getMetersPerUnit() * Math.cos(latitude_rad)),
          })
        }),
        new Style({
          image: new Icon({
            opacity: 1 ,
            scale: scale,
            src: 'assets/img/accesspoint.png'
          }),
          text: new Text({
            font: '' + 9 + 'px Roboto,sans-serif',
            text: name,
            stroke: new Stroke({
              color: '#fff',
              width: 2
            }),
            offsetY: 25,
          }),
        })
      ];
    }

    function getPOIStyle( feature: any ) {
      const view = thisref.map.getView();
      const zoom = view.getZoom();
      let scale: any = 0;
      const name = feature.get('icon');
      switch (true) {
        case (zoom > 20): { scale = 1; break; }
        case (zoom > 19): { scale = 0.6; break; }
        case (zoom > 18): { scale = 0.4; break; }
        case (zoom > 17): { scale = 0.3; break; }
        case (zoom > 16): { scale = 0.2; break; }
      }
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          scale: scale,
          opacity: 1,
          src: '' + ConfigService.poiIconPaths[name]
        })
      });
    }

    function getRouteEditStyle(feat: any) {
      const attrib_public = feat.get('public');
      const attrib_accessible = feat.get('accessible');
      let color: any;
      let dash: any;
      if (attrib_public === true) { color = 'rgba(0,134,246,1)'; } else { color = 'rgba(231,76,60,1)'; }
      if (attrib_accessible === true) { dash = [1]; } else { dash = [1, 5]; }
      return new Style({
        stroke: new Stroke({
          color: color,
          width: 3,
          lineDash: dash
        })
      });
    }

    function getRouteEditSelectionStyle(feat: any) {
      const attrib_public = feat.get('public');
      const attrib_accessible = feat.get('accessible');
      let color: any;
      let dash: any;
      if (attrib_public === true) { color = 'rgba(0,98,177,1)'; } else { color = 'rgba(179,38,22,1)'; }
      if (attrib_accessible === true) { dash = [1]; } else { dash = [1, 5]; }
      return new Style({
        stroke: new Stroke({
          color: color,
          width: 6,
          lineDash: dash
        })
      });
    }

    function getRouteStyle(floor: any) {
      if (floor === thisref.activeFloorNumber) {
        return routestyle;
      }
      if (floor !== thisref.activeFloorNumber) {
        return new Style({
          stroke: new Stroke({
            color: 'rgba(0,179,253,0)',
            width: 3,
            lineDash: [5, 5]
          })
        });
      }
    }

    this.layer_navigation_routes_bg = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any) {
        return getRouteStyle_bg(feature.get('floor'));
      },
      visible: true
    });

    this.layer_navigation_routes = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any) {
        return getRouteStyle(feature.get('floor'));
      },
      visible: true
    });


    this.layer_points_of_interest = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      style: function (feature: any) {
        return getPOIStyle( feature );
      },
      visible: true,
      updateWhileAnimating: true
    });

    this.layer_room_names = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON()
      }),
      visible: true
    });

    if (this.setting_show_room_names === true) {
      this.layer_room_names.setVisible(true);
    }

    const selection_style = new Style({
      stroke: new Stroke({
        color: 'rgb(114, 123, 142)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(29, 60, 131, 0.3)'
      })
    });

    const search_style = new Style({
      stroke: new Stroke({
        color: 'rgb(253, 113, 113)',
        width: 3
      })
    });

    this.layer_selected_room = new VectorLayer({
      style: function () {
        return selection_style;
      },
      source: new VectorSource({
        format: new GeoJSON(),
      }),
      visible: true
    });

    this.layer_selected_POI = new VectorLayer({
      style: function () {
        return selection_style;
      },
      source: new VectorSource({
        format: new GeoJSON(),
      }),
      visible: true,
      zIndex: 9999
    });

    this.layer_search_results = new VectorLayer({
      style: function () {
        return search_style;
      },
      source: new VectorSource({
        format: new WKT(),
      }),
      visible: true,
      zIndex: 9999
    });

    this.layer_cleaning_status = new VectorLayer({
      source: new VectorSource({
        format: new WKT(),
      }),
      visible: true,
      opacity: 1
    });

    const user_room_style = new Style({
      stroke: new Stroke({
        color: 'rgb(254, 0, 0)',
        width: 5
      }),
      fill: new Fill({
        color: 'rgba(244, 0, 0, 0.6)'
      })
    });

    this.layer_user_rooms = new VectorLayer({
      style: function () {
        return user_room_style;
      },
      source: new VectorSource({
        format: new WKT(),
      }),
      visible: true,
      opacity: 1
    });

    function route_icon_style(floor: any, name: any) {
      let icon_opacity: any;
      // if ( thisref.activeFloorNumber < floor ) {icon_opacity = Math.abs(thisref.activeFloorNumber / floor); } else { icon_opacity = Math.abs(floor / thisref.activeFloorNumber); }
      if ( thisref.activeFloorNumber !== floor ) {icon_opacity = 0; } else { icon_opacity = 1; }
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          opacity: icon_opacity ,
          src: '' + ConfigService.routeIconPaths[name]
        })
      });
    }

    this.layer_route_icons = new VectorLayer({
      style: function (feature: any) {
        const floor = feature.get('floor');
        const iconname = feature.get('routeIconName');
        return route_icon_style(floor, iconname);
      },
      source: new VectorSource({
        format: new GeoJSON()
      }),
      visible: true
    });

    /* initial map view */
    const map_view = new View({
      center: [1178794.825468269642442, 6998100.546549841761589],
      zoom: 18.7,
      // rotation: Math.PI / 2,
    });

    /* initial parameter: selected feature source, selected table, selected floor */
    this.activeWMS = this.source_wms_eg01;
    this.activeRoomTable = 'public.DIANA_EG01_POLY';
    // this.activeFloorNumber = 1;

    // initialize the map
    // this.map = new WebGLMap({  // WebGL Renderer: aktivieren für besserer performance aber probleme mit selection vom komplexen geometrien
    this.map = new Map({
      target: 'map',
      maxTilesLoading: 16, // performance nur 8
      loadTilesWhileInteracting: true, // performance false
      moveTolerance: 20, // DK-98 Click performance
      pixelRatio: 1,
      layers: [
        this.layer_openstreetmap_background,
        this.layer_outline_eg00,
        this.layer_wms_eg00,
        this.layer_outline_eg01,
        this.layer_wms_eg01,
        this.layer_wms_eg01_3d,
        this.layer_outline_og1,
        this.layer_wms_og1,
        this.layer_outline_og2,
        this.layer_wms_og2,
        this.layer_outline_og3,
        this.layer_wms_og3,
        this.layer_outline_og4,
        this.layer_wms_og4,
        this.layer_outline_og5,
        this.layer_wms_og5,
        this.layer_outline_og6,
        this.layer_wms_og6,
        this.layer_outline_og7,
        this.layer_wms_og7,
        this.layer_outline_og8,
        this.layer_wms_og8,
        this.layer_outline_og9,
        this.layer_wms_og9,
        this.layer_outline_og10,
        this.layer_wms_og10,
        this.layer_outline_og11,
        this.layer_wms_og11,
        this.layer_routing_eg00,
        this.layer_routing_eg01,
        this.layer_routing_og01,
        this.layer_routing_og02,
        this.layer_routing_og03,
        this.layer_routing_og04,
        this.layer_routing_og05,
        this.layer_routing_og06,
        this.layer_routing_og07,
        this.layer_routing_og08,
        this.layer_routing_og09,
        this.layer_routing_og10,
        this.layer_routing_og11,
        this.layer_search_results,
        this.layer_cleaning_status,
        this.layer_user_rooms,
        this.layer_points_of_interest,
        this.layer_selected_room,
        this.layer_selected_POI,
        this.layer_room_names,
        this.layer_navigation_routes_bg,
        this.layer_navigation_routes,
        this.layer_route_icons,
        this.layer_routing_edit_selection,
        this.layer_user_position,
        // this.layer_beacons_heatmap,
        this.layer_beacons
      ],
      view: map_view
    });

    // count all loading tiles
    let counttiles: any = 0;
    let counttiles_finished: any = 0;

    // wenn loadingscreen ausgeschaltet muss das infowindow eher aufpoppen, nicht erst nachdem loaden aller tiles
    if (ConfigService.loadingScreenActive === false) {
      setTimeout(() => {
        this.infowindowhidden = false;
      }, 0);
    }

    // wenn cordova zoom auf patientenraum
    // #DK-110
    if (this.isCordovaApp) {
      setTimeout(() => {
        this.show_user_room('intent');
      }, 3000);
    }

    this.source_wms_eg01.on('tileloadstart', function (evt: any) {
      if (thisref.loading === true && ConfigService.loadingScreenActive === true) {
        counttiles++;
      }
    });

    this.source_wms_eg01.on('tileloadend', function (evt: any) {
      if (thisref.loading === true && ConfigService.loadingScreenActive === true) {
        counttiles_finished++;
        if (counttiles === counttiles_finished) {
          counttiles_finished = 0;
          counttiles = 0;
          thisref.loading = false;
          // das infowindow soll erst aufploppen wenn die map geladen und der loadingscreen verschwunden ist, 3sek timeout
          setTimeout(() => {
            thisref.infowindowhidden = false;
          }, 3000);
        }
      }
    });

    this.map.getViewport().addEventListener('contextmenu', function (evt: any) {
    });

    // route select via box
    // create dragbox but dont add it
    this.dragBox = new DragBox({ condition: platformModifierKeyOnly, onBoxEnd : function() { } });

    this.dragBox.on('boxend', function() {
      // features that intersect the box are added to the collection of
      // selected features
      const extent = this.getGeometry().getExtent();
      thisref.activeVectorSource.forEachFeatureIntersectingExtent(extent, function(feature: any) {
        // bug openlayers, nach zoom findet er features zweimal, deswegen check nach eineindeutiger id
        if (!thisref.selected_route_segments.find(x => x.get('id') === feature.get('id'))) {
          thisref.selected_route_segments.push(feature);
          thisref.layer_routing_edit_selection.getSource().addFeature(feature);
        }
      });
      // show widget etc
      thisref.routeSegmentClicked = true;
      thisref.roomClicked = false;
      thisref.beaconClicked = false;
      thisref.infowindowhidden = false;
    });

    this.dragBox.on('boxstart', function() {
      // clear selected segments
      thisref.selected_route_segments = [];
      thisref.routeSegmentClicked = false;
      thisref.infowindowhidden = true;
      thisref.layer_routing_edit_selection.getSource().clear();
    });

    /* ONLY POICARDS IN VIEW */
    /*
    this.map.on('moveend', (evt: any) => {
      this.displayed_poiFeatures = [];
      const extent = this.map.getView().calculateExtent();
      this.layer_points_of_interest.getSource().forEachFeatureIntersectingExtent(extent, function(feature: any) {
        thisref.displayed_poiFeatures.push(feature);
      });
    });
    */
    this.map.on('singleclick', (evt: any) => {
      this.contextMenuOpen = false;
      const viewResolution = map_view.getResolution();
      this.activeWMSRequestURL = this.activeWMS.getGetFeatureInfoUrl(
        evt.coordinate, viewResolution, 'EPSG:3857',
        {
          'INFO_FORMAT': 'application/json',
          'FORMAT': 'application/json'
        });

      let featurefound = false;
      this.layer_selected_POI.getSource().clear();
      this.layer_selected_room.getSource().clear();
      this.layer_routing_edit_selection.getSource().clear();
      thisref.selected_route_segments = [];

      this.routeSegmentClicked = false;
      this.map.forEachFeatureAtPixel(evt.pixel, (feature: any, layer: any) => {
        log.debug(feature);
        this.roomNumber = undefined;
        if (feature) {

          featurefound = true;
          const type = feature.get('type');
          switch (type) {
            case 'POI':
              this.infowindowhidden = true;
              // this.infowindowhidden = false;
              this.poiClicked = true;
              this.roomClicked = false;
              this.beaconClicked = false;
              setTimeout(() => {
                this.infowindowhidden = false;
              }, 1);
              this.clickedPoiFeatureSubject.next(feature);
              this.poiName = feature.get('name');
              this.poiDetails = feature.get('details');
              this.poiCat = feature.get('cat');
              this.poiIcon = feature.get('icon');
              this.poiTable = feature.get('table');
              this.layer_selected_POI.getSource().clear();

              const selectedFeature = feature.clone();
              selectedFeature.setStyle(this.getSelectedPoiIconStyle(feature.get('icon')));
              this.layer_selected_POI.getSource().addFeature(selectedFeature);

              break;

            case 'room':
              this.infowindowhidden = false;
              this.roomClicked = true;
              this.beaconClicked = false;
              // TODO Values swap into one Method
              this.roomName = feature.get('r_name');
              this.roomNumber = feature.get('r_nummer');
              this.roomSurface = feature.get('rb_belag');
              this.roomSize = feature.get('rb_groesse');
              this.roomCleaningInterval = feature.get('rb_turnus');
              // thisref.roomCleaningStatus = feature.get('rb_status');
              this.roomRevier = feature.get('rb_revier');
              this.roomColor = feature.get('rb_color');
              this.roomPublic = feature.get('r_public');
              this.roomComment = feature.get('rb_bemerk');
              this.roomTable = this.activeRoomTable;
              this.roomGISID = feature.getId();
              // set type room
              this.clickedFeaturesOnMap.set('type', 'room');
              this.layer_selected_room.getSource().addFeature(feature);
              // load pano
              this.RoomTexture = 'assets/img/roompanos/6.679.jpg';

              break;

            case 'Beacon':
              this.clickedBeaconFeatureSubject.next(feature);
              this.infowindowhidden = false;
              this.beaconClicked = true;
              this.roomClicked = false;
              // TODO Values swap into one Method
              this.beaconName = feature.get('name');
              this.beaconBSSID1 = feature.get('BSSID1');
              this.beaconBSSID2 = feature.get('BSSID2');
              this.beaconBSSID3 = feature.get('BSSID3');
              this.beaconBSSID4 = feature.get('BSSID4');
              this.beaconTXPower = feature.get('TXPower');
              this.beaconTXPower = feature.get('inactive');
              this.roomGISID = feature.getId();

              break;

            case 'Route':
              this.roomClicked = false;
              this.beaconClicked = false;
              this.infowindowhidden = false;
              this.routeSegmentClicked = true;
              // ol bug
              if (!thisref.selected_route_segments.find(x => x.get('id') === feature.get('id'))) {
                thisref.selected_route_segments.push(feature);
                thisref.layer_routing_edit_selection.getSource().addFeature(feature);
              }

              break;

            default:
              featurefound = false;
          }
        }
      });

      // kein feature auf der karte geclickt
      if (!featurefound) {
        this.roomClicked = false;
        this.beaconClicked = false;
        this.poiClicked = false;
        this.infowindowhidden = true;
        this.restService.getPolyDataGeoServer(this.activeWMSRequestURL).then((data: any) => {
          const format = new GeoJSON();
          const features = format.readFeatures(data, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:3857'
          });

          this.clickedFeaturesOnMap = features[0];
          let public_room: any;
          if (this.clickedFeaturesOnMap) {
            public_room = this.clickedFeaturesOnMap.get('r_public');
          }
          const userrole: any = this.authService.credentials['userrole'];

          if (userrole === 'patient' && public_room === 'false') {
            this.layer_selected_room.getSource().clear();
            this.roomClicked = true;
            this.roomName = 'Keine Berechtigung';
            this.roomNumber = this.clickedFeaturesOnMap.get('r_nummer');
          }

          if (userrole !== 'patient' || public_room !== 'false') {
            this.layer_selected_room.getSource().clear();
            if (this.clickedFeaturesOnMap && features[0].get('r_name') !== undefined) {
              this.roomClicked = true;
              this.layer_selected_room.setVisible(true);
              // TODO Values swap into one Method
              this.roomName = this.clickedFeaturesOnMap.get('r_name');
              this.roomNumber = this.clickedFeaturesOnMap.get('r_nummer');
              this.roomSurface = this.clickedFeaturesOnMap.get('rb_belag');
              this.roomSize = this.clickedFeaturesOnMap.get('rb_groesse');
              this.roomCleaningInterval = this.clickedFeaturesOnMap.get('rb_turnus');
              this.roomCleaningStatus = this.clickedFeaturesOnMap.get('rb_status');
              this.roomRevier = this.clickedFeaturesOnMap.get('rb_revier');
              this.roomColor = this.clickedFeaturesOnMap.get('rb_color');
              this.roomPublic = this.clickedFeaturesOnMap.get('r_public');
              this.roomComment = this.clickedFeaturesOnMap.get('rb_bemerk');
              this.roomCategory = this.clickedFeaturesOnMap.get('r_cat');
              this.roomTable = this.activeRoomTable;
              this.roomGISID = this.clickedFeaturesOnMap.getId();
              // set type room
              this.clickedFeaturesOnMap.set('type', 'room');
              // const allowed = thisref.authService.onlypublicroomaccess;
              // if (thisref.roomPublic === 0 && allowed) {
              // } else {
              this.layer_selected_room.getSource().addFeatures(features);
              this.infowindowhidden = false;
              // load pano
              this.RoomTexture = 'assets/img/roompanos/' + this.roomNumber + '.jpg';
              // }
              log.debug('features', features);
            }
          }
        }).catch((error) => {
          log.debug('rest api getPolyDataGeoServer', error);
          this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
            positionClass: 'toast-bottom-left',
          });
        });
      }
    });

    // ende init
  }

  easein(t: any) {
    return Math.pow(t, 3);
  }

  easeout(t: any) {
    return 1 - this.easein(1 - t);
  }

  poiBounceAnimation(feature: Feature) {
    const thisref = this;
    const start = new Date().getTime();
    const observableObject = new Observable();
    let listenerKey = this.map.on('postcompose', animate);

    function animate(event: any) {
      const vectorContext = event.vectorContext;
      const frameState = event.frameState;
      const attributes = feature.getProperties();
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / 500; // change 500 if needed
      const opacity = thisref.easeout(0 + elapsedRatio);
      let scale: any = thisref.easeout(0 + elapsedRatio);
      if (scale > 1) {
        scale = 1;
      }
      const iconStyle = new Style({
        image: new Icon(({
          anchor: [0.5, 0.5],
          // anchorXUnits: 'pixels',
          // anchorYUnits: 'pixels',
          opacity: opacity,
          scale: scale,
          src: '' + ConfigService.poiIconPaths[attributes.icon]
        }))
      });
      feature.setStyle(iconStyle);
      if (elapsed > 500) {
        // TODO put the 500 in a global var
        observableObject.unByKey(listenerKey);
        return;
      }
    }

    listenerKey = this.map.on('postcompose', animate);
  }

  poiCardClick (feature: any) {
    this.infowindowhidden = true;
    this.poiClicked = true;
    this.roomClicked = false;
    setTimeout(() => {
      this.infowindowhidden = false;
    }, 1);
    this.clickedPoiFeatureSubject.next(feature);
    this.switchToLayer(+feature.get('ebene'));
    this.poiName = feature.get('name');
    this.poiDetails = feature.get('details');
    this.poiCat = feature.get('cat');
    this.poiIcon = feature.get('icon');
    this.poiTable = feature.get('table');
    this.layer_selected_POI.getSource().clear();
    const selectedFeature = feature.clone();
    selectedFeature.setStyle(this.getSelectedPoiIconStyle(feature.get('icon')));
    this.layer_selected_POI.getSource().addFeature(selectedFeature);
    this.zoomMapToCords(feature.getGeometry().getCoordinates()[0], feature.getGeometry().getCoordinates()[1], 21,  this.flash(feature));
  }

  // holt bei einem rechtsclick die koordinate auf der karte
  rightClickOnMap(evt: any) {
    // event.preventDefault();  // für entwicklung auskommentiert, da es den rechtsclick ausschaltet

    log.debug(this.map.getEventCoordinate(event));
    this.createPOICoordinateX = this.map.getEventCoordinate(event)[0];
    this.createPOICoordinateY = this.map.getEventCoordinate(event)[1];

    log.debug('createPOICoordinateX', this.createPOICoordinateX);
    log.debug('createPOICoordinateY', this.createPOICoordinateY);

    this.contextMenuOpen = false;
    // log.debug(thisref2.map.getEventCoordinate(evt));

    this.contextmenuTop = evt.clientY;
    this.contextmenuLeft = evt.clientX;
    const thisref = this;
    let featurefound = false;
    this.map.forEachFeatureAtPixel([evt.clientX, evt.clientY], function (feature: any, layer: any) {
      log.debug(feature);
      featurefound = true;
      // alert('menu1 anzeigen');
      // check if poi feature
      // show editmenu
      if (feature.get('type') === 'POI') {

        thisref.infowindowhidden = true;
        setTimeout(() => {
          thisref.infowindowhidden = false;
        }, 1);

        thisref.clickedPOIFeature = feature;
        thisref.clickedPoiFeatureSubject.next(feature);

        log.debug('feature', feature);

        evt.preventDefault();
        thisref.contextMenuOpen = true;
        thisref.contextMenuEdit = true;
        thisref.contextMenuEditBeacon = false;
      }

      if (feature.get('type') === 'Beacon') {

        thisref.infowindowhidden = true;
        setTimeout(() => {
          thisref.infowindowhidden = false;
        }, 1);

        thisref.clickedBeaconFeature = feature;
        thisref.clickedBeaconFeatureSubject.next(feature);

        log.debug('feature', feature);

        evt.preventDefault();
        thisref.contextMenuOpen = true;
        thisref.contextMenuEdit = false;
        thisref.contextMenuEditBeacon = true;
      }
    });

    if (!featurefound) {
      evt.preventDefault();
      thisref.contextMenuOpen = true;
      thisref.contextMenuEdit = false;
      thisref.contextMenuEditBeacon = false;
    }
  }

  toggleBeacons() {
    this.show_beacons ? this.show_beacons = false : this.show_beacons = true;
    if (this.show_beacons === true) {
      this.redrawBeacons();
      this.redrawBeaconsHeatmap();
    } else {
      this.layer_beacons.getSource().clear();
      this.layer_beacons_heatmap.getSource().clear();
    }
  }

  redrawBeaconsHeatmap() {
    if (this.show_beacons === true) {
      this.layer_beacons_heatmap.getSource().clear();
      log.debug('RUN toggleGWA');

      this.layer_beacons_heatmap.setVisible(true);

      const poiRequestUrl = ConfigService.geoServerUrl + '/geoserver/Diana/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Diana:beacons&outputFormat=application%2Fjson';
      this.restService.getPolyDataGeoServer(poiRequestUrl).then((featureCollection: any) => {
        const format = new GeoJSON();
        this.poiFeatures = format.readFeatures(featureCollection);
        log.debug('GET GWA Features:', this.poiFeatures);
        let i = 0;
        this.poiFeatures.forEach((poiFeature: Feature) => {
          poiFeature.set('type', 'WIFI');
          if (poiFeature.get('ebene') === this.activeFloorNumber) {
            i++;
            setTimeout(() => {
              this.layer_beacons_heatmap.getSource().addFeature(poiFeature);
            }, 30 * i);
          }
        });
      }).catch((error) => {
        log.debug('rest api getPolyDataGeoServer', error);
        this.toast.error(error.message, 'Fehler: rest api getPolyDataGeoServer', {
          positionClass: 'toast-bottom-left',
        });
      });
    } else {
      this.layer_beacons_heatmap.getSource().clear();
    }
  }

  redrawBeacons() {
    log.debug('redrawBeacons');
    this.layer_beacons.getSource().clear();
      const beaconRequestUrl = ConfigService.geoServerUrl + '/geoserver/Diana/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Diana:beacons&outputFormat=application%2Fjson';
      this.restService.getPolyDataGeoServer(beaconRequestUrl).then((featureCollection: any) => {
        const format = new GeoJSON();
        this.beaconFeatures = format.readFeatures(featureCollection);
        log.debug('redrawBeacons this.beaconFeatures', this.beaconFeatures);
        let i = 0;
        // const extent = this.map.getView().calculateExtent();
        this.beaconFeatures.forEach((beaconFeature: Feature) => {
          beaconFeature.set('type', 'Beacon');
          // poiFeature.setStyle(this.getPoiIconStyle(poiFeature.get('icon')));
          if (beaconFeature.get('ebene') === this.activeFloorNumber) {
            i++;
            const geom: any = beaconFeature.getGeometry();
            const coords = geom.getCoordinates();
            setTimeout(() => {
              this.layer_beacons.getSource().addFeature(beaconFeature);
              // if (!this.isCordovaApp) {
              //  this.poiBounceAnimation(poiFeature);
              // } // animation nur auf desktop
            }, 30 * i);
          }
        });
      }).catch((error) => {
        log.debug('rest api getPolyDataGeoServer', error);
        this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      });
  }

  toggleBackgroundmap() {
    this.show_backgroundmap ? this.show_backgroundmap = false : this.show_backgroundmap = true;
    this.layer_openstreetmap_background.setVisible(this.show_backgroundmap);
    this.layer_outline_eg00.setVisible(this.show_backgroundmap);
    this.layer_outline_eg01.setVisible(this.show_backgroundmap);
  }

  togglePoi() {
    this.show_poi ? this.show_poi = false : this.show_poi = true;
    // this.layer_points_of_interest.setVisible(this.show_poi);
    if (this.show_poi === true) {
      this.redrawPOI();
    } else {
      this.layer_points_of_interest.getSource().clear();
    }
    if (!this.setting_show_poi_pflege && !this.setting_show_poi_eingaenge_ausgaenge && !this.setting_show_poi_freizeit && !this.setting_show_poi_therapie &&
      !this.setting_show_poi_treppen_aufzuege) {
      this.togglePoiCategory('all');
    }
  }

  togglePoiCategory(category: string) {
    log.debug('togglePoiCategory');
    if (category === 'all' && this.setting_show_poi_all === false ||
      category === 'Pflege' && this.setting_show_poi_pflege === false ||
      category === 'Eingänge & Ausgänge' && this.setting_show_poi_eingaenge_ausgaenge === false ||
      category === 'Freizeit' && this.setting_show_poi_freizeit === false ||
      category === 'Therapie' && this.setting_show_poi_therapie === false ||
      category === 'Treppen & Aufzüge' && this.setting_show_poi_treppen_aufzuege === false) {

      if (category === 'Freizeit') {
        this.setting_show_poi_freizeit = true;
      }
      if (category === 'Pflege') {
        this.setting_show_poi_pflege = true;
      }
      if (category === 'Eingänge & Ausgänge') {
        this.setting_show_poi_eingaenge_ausgaenge = true;
      }
      if (category === 'Therapie') {
        this.setting_show_poi_therapie = true;
      }
      if (category === 'Treppen & Aufzüge') {
        this.setting_show_poi_treppen_aufzuege = true;
      }
      if (category === 'all') {
        this.setting_show_poi_all = true;
        this.setting_show_poi_treppen_aufzuege = true;
        this.setting_show_poi_therapie = true;
        this.setting_show_poi_eingaenge_ausgaenge = true;
        this.setting_show_poi_pflege = true;
        this.setting_show_poi_freizeit = true;
        this.layer_points_of_interest.getSource().clear();
      }

      this.show_poi = true;
      this.layer_points_of_interest.setVisible(this.show_poi);
      this.layerswitcher_loading = true;
      const poiRequestUrl = ConfigService.geoServerUrl + '/geoserver/Diana/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Diana:poi&outputFormat=application%2Fjson';
      this.restService.getPolyDataGeoServer(poiRequestUrl).then((featureCollection: any) => {
        const format = new GeoJSON();
        this.poiFeatures = format.readFeatures(featureCollection);
        log.debug('togglePoiCategory this.poiFeatures', this.poiFeatures);
        let i = 0;
        this.layerswitcher_loading = false;
        // const extent = this.map.getView().calculateExtent();
        this.poiFeatures.forEach((poiFeature: Feature) => {
          poiFeature.set('type', 'POI');
          poiFeature.set('icon', (poiFeature.get('icon')));
          // poiFeature.setStyle(this.getPoiIconStyle(poiFeature.get('icon')));
          if (category === poiFeature.get('cat') && poiFeature.get('cat') !== 'Eingänge & Ausgänge' && poiFeature.get('cat') !== 'Treppen & Aufzüge' || category === 'all' && poiFeature.get('cat') !== 'Eingänge & Ausgänge' && poiFeature.get('cat') !== 'Treppen & Aufzüge') { this.displayed_poiFeatures.unshift(poiFeature); }
          if (poiFeature.get('table') === this.activeRoomTable && category === poiFeature.get('cat') || poiFeature.get('table') === this.activeRoomTable && category === 'all') {
            i++;
            // const geom: any = poiFeature.getGeometry();
            // const coords = geom.getCoordinates();
            // if (containsXY( extent, coords[0], coords[1])) { this.displayed_poiFeatures.push(poiFeature); }
            setTimeout(() => {
              this.layer_points_of_interest.getSource().addFeature(poiFeature);
              // if (!this.isCordovaApp) {
               // this.poiBounceAnimation(poiFeature);
              // } // animation nur auf desktop
            }, 30 * i);

          }
      });
      }).catch((error) => {
        this.layerswitcher_loading = false;
        log.debug('rest api getPolyDataGeoServer', error);
        this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      });
    } else if (category === 'all' && this.setting_show_poi_all === true ||
      category === 'Pflege' && this.setting_show_poi_pflege === true ||
      category === 'Eingänge & Ausgänge' && this.setting_show_poi_eingaenge_ausgaenge === true ||
      category === 'Freizeit' && this.setting_show_poi_freizeit === true ||
      category === 'Therapie' && this.setting_show_poi_therapie === true ||
      category === 'Treppen & Aufzüge' && this.setting_show_poi_treppen_aufzuege === true) {
      if (category === 'Freizeit') {
        this.setting_show_poi_freizeit = false;
      }
      if (category === 'Pflege') {
        this.setting_show_poi_pflege = false;
      }
      if (category === 'Eingänge & Ausgänge') {
        this.setting_show_poi_eingaenge_ausgaenge = false;
      }
      if (category === 'Therapie') {
        this.setting_show_poi_therapie = false;
      }
      if (category === 'Treppen & Aufzüge') {
        this.setting_show_poi_treppen_aufzuege = false;
      }
      if (category === 'all') {
        this.setting_show_poi_all = false;
        this.setting_show_poi_treppen_aufzuege = false;
        this.setting_show_poi_therapie = false;
        this.setting_show_poi_eingaenge_ausgaenge = false;
        this.setting_show_poi_pflege = false;
        this.setting_show_poi_freizeit = false;
      }
      if (category === 'all') {
        this.layer_points_of_interest.getSource().clear();
        this.layerswitcher_loading = false;
      } else {
        const features = this.layer_points_of_interest.getSource().getFeatures();

        features.forEach((feature: any, index: any) => {
          const attributes = feature.getProperties();
          if (attributes.cat === category) {
            this.layer_points_of_interest.getSource().removeFeature(feature);
          }
        });

        this.displayed_poiFeatures.forEach((feature: any, index: any) => {
          const attributes = feature.getProperties();
          if (attributes.cat === category) {
            delete this.displayed_poiFeatures[index];
          }
        });

        this.displayed_poiFeatures = this.displayed_poiFeatures.filter(function (el: any) {
          return el != null;
        });

        this.layerswitcher_loading = false;

      }
      if (!this.setting_show_poi_treppen_aufzuege && !this.setting_show_poi_therapie && !this.setting_show_poi_eingaenge_ausgaenge && !this.setting_show_poi_pflege && !this.setting_show_poi_freizeit) {
        log.debug('all points of interest are disabled -> hide layer');
        this.layerswitcher_loading = false;
        this.setting_show_poi_all = false;
        this.show_poi = false;
      }
    }
  }

  redrawPOI() {
    log.debug('redrawPOI');
    if (this.setting_show_poi_all === true || this.setting_show_poi_pflege === true ||
      this.setting_show_poi_eingaenge_ausgaenge === true || this.setting_show_poi_freizeit === true ||
      this.setting_show_poi_therapie === true || this.setting_show_poi_treppen_aufzuege === true) {

      this.layer_points_of_interest.getSource().clear();
      const categories: any = [];

      if (this.setting_show_poi_freizeit === true) {
        categories.push('Freizeit');
      }
      if (this.setting_show_poi_pflege === true) {
        categories.push('Pflege');
      }
      if (this.setting_show_poi_eingaenge_ausgaenge === true) {
        categories.push('Eingänge & Ausgänge');
      }
      if (this.setting_show_poi_therapie === true) {
        categories.push('Therapie');
      }
      if (this.setting_show_poi_treppen_aufzuege === true) {
        categories.push('Treppen & Aufzüge');
      }
      if (this.setting_show_poi_all === true) {
      }
      const poiRequestUrl = ConfigService.geoServerUrl + '/geoserver/Diana/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Diana:poi&outputFormat=application%2Fjson';
      this.restService.getPolyDataGeoServer(poiRequestUrl).then((featureCollection: any) => {
        const format = new GeoJSON();
        // this.displayed_poiFeatures = [];
        this.poiFeatures = format.readFeatures(featureCollection);
        log.debug('redrawPOI this.poiFeatures', this.poiFeatures);
        let i = 0;
       // const extent = this.map.getView().calculateExtent();
        this.poiFeatures.forEach((poiFeature: Feature) => {
          poiFeature.set('type', 'POI');
          // poiFeature.setStyle(this.getPoiIconStyle(poiFeature.get('icon')));
          poiFeature.set('icon', poiFeature.get('icon'));
          if (poiFeature.get('table') === this.activeRoomTable && (categories.indexOf(poiFeature.get('cat')) !== -1) ||
            poiFeature.get('table') === this.activeRoomTable && (categories.indexOf('all') !== -1)) {
            i++;
            const geom: any = poiFeature.getGeometry();
            const coords = geom.getCoordinates();
           // if (containsXY( extent, coords[0], coords[1])) { this.displayed_poiFeatures.push(poiFeature); }
            setTimeout(() => {
              this.layer_points_of_interest.getSource().addFeature(poiFeature);
              // if (!this.isCordovaApp) {
              //  this.poiBounceAnimation(poiFeature);
              // } // animation nur auf desktop
            }, 30 * i);
          }
        });
      }).catch((error) => {
        log.debug('rest api getPolyDataGeoServer', error);
        this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      });
    }
  }

  getSelectedPoiIconStyle(poiIconName: string) {
    const stylearray = [];
    const iconstyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        opacity: 1,
        scale: 1,
        src: 'assets/img/poi/selection_glow.png'
      })
    });
    const shadowstyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        opacity: 1,
        scale: 1.1,
        src: '' + ConfigService.poiIconPaths[poiIconName]
      })
    });
    stylearray.push(iconstyle);
    stylearray.push(shadowstyle);
    return stylearray;
  }

  getSearchresultPoiIconStyle(poiIconName: string) {
    const stylearray = [];
    const iconstyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        opacity: 1,
        scale: 0.9,
        src: 'assets/img/poi/selection_glow_search.png'
      })
    });
    const shadowstyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        opacity: 1,
        scale: 1,
        src: '' + ConfigService.poiIconPaths[poiIconName]
      })
    });
    stylearray.push(iconstyle);
    stylearray.push(shadowstyle);
    return stylearray;
  }

  toggleRoomNames() {
    if (this.setting_show_room_names === false) {
      this.setting_show_room_names = true;
      const input_table = {'table': this.activeRoomTable};
      this.restService.getAllRoomNameFeaturesForInputTable(input_table).then((data) => {
        const thisRef = this;
        const roomNameFeatures: any = [];
        roomNameFeatures.push(data);
        const userrole: any = this.authService.credentials['userrole'];
        roomNameFeatures[0].forEach(function (feature: any) {
          const roomNameMarker = new Feature(new Point([feature.st_x, feature.st_y]));
          roomNameMarker.set('name', feature.r_name + ' \n ' + feature.r_nummer);
          roomNameMarker.setStyle(thisRef.getRoomNameFeatureStyle(feature));
          const public_room: any = feature.r_public;
          if (userrole === 'patient' && public_room !== 'false') {
            if (feature.r_name !== 'Flur') { // bestimmte raumnamen entfernen aber trotzdem public
              thisRef.layer_room_names.getSource().addFeature(roomNameMarker);
            }
          }
          if (userrole !== 'patient') {
            thisRef.layer_room_names.getSource().addFeature(roomNameMarker);
          }
        });
      }, (error) => {
        log.debug('rest api getAllRoomNameFeaturesForInputTable', error);
        this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      });
    } else {
      this.setting_show_room_names = false;
      this.layer_room_names.getSource().clear();
    }
  }

  redrawRoomNames() {
    if (this.setting_show_room_names === true) {
      this.layerswitcher_loading = true;
      this.layer_room_names.getSource().clear();
      const input_table = {'table': this.activeRoomTable};
      this.restService.getAllRoomNameFeaturesForInputTable(input_table).then((data) => {
        this.layerswitcher_loading = false;
        const thisRef = this;
        const roomNameFeatures: any = [];
        roomNameFeatures.push(data);
        const userrole: any = this.authService.credentials['userrole'];
        roomNameFeatures[0].forEach(function (feature: any) {
          const roomNameMarker = new Feature(new Point([feature.st_x, feature.st_y]));
          roomNameMarker.set('name', feature.r_name + ' \n ' + feature.r_nummer);
          roomNameMarker.setStyle(thisRef.getRoomNameFeatureStyle(feature));
          const public_room: any = feature.r_public;
          if (userrole === 'patient' && public_room !== 'false') {
            if (feature.r_name !== 'Flur') { // bestimmte raumnamen entfernen aber trotzdem public
              thisRef.layer_room_names.getSource().addFeature(roomNameMarker);
            }
          }
          if (userrole !== 'patient') {
            thisRef.layer_room_names.getSource().addFeature(roomNameMarker);
          }
        });
      }, (error) => {
        log.debug('rest api getAllRoomNameFeaturesForInputTable', error);
        this.layerswitcher_loading = false;
        this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      });
    }
  }

  zoomMapToCords(x: any, y: any, zoomOnMap: number, done: any) {
    const duration = 2000;
    const view = this.map.getView();
    const zoom = view.getZoom();
    let parts = 2;
    let called = false;

    function callback(complete: any) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        if (done) {
          done(complete);
        }
      }
    }

    view.animate({
      center: [x, y],
      duration: duration
    }, callback);
    view.animate({
      zoom: zoom,
      duration: duration / 2
    }, {
      zoom: zoomOnMap,
      duration: duration / 2
    }, callback);

  }

  flash(feature: any) {
    const duration = 3000;
    const start = new Date().getTime();
    const listenerKey = this.map.on('postcompose', animate);
    const observable2 = new Observable();


    function animate(event: any) {
      const vectorContext = event.vectorContext;
      const frameState = event.frameState;
      const flashGeom = feature.getGeometry().clone();
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / duration;

      // radius will be 5 at start and 30 at end.
      function easein(t: any) {
        return Math.pow(t, 3);
      }

      function easeout(t: any) {
        return 1 - easein(1 - t);
      }

      const radius = easeout(elapsedRatio) * 25 + 5;
      const opacity = easeout(1 - elapsedRatio);

      const style = new Style({
        image: new Circle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, ' + opacity + ')',
            width: 0.25 + opacity
          })
        })
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {
        observable2.unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postcompose animation
      this.render();
    }
  }

  flashpoly(multipoly: any) {
    const duration = 2000;
    const start = new Date().getTime();
    const listenerKey = this.map.on('postcompose', animate);
    const observable2 = new Observable();
    const format = new WKT();

    function animate(event: any) {
      const vectorContext = event.vectorContext;
      const frameState = event.frameState;
      // const flashGeom = feature[0].clone();
      const flashGeom = format.readFeatures(multipoly);
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / duration;

      // radius will be 5 at start and 30 at end.
      function easein(t: any) {
        return Math.pow(t, 3);
      }

      function easeout(t: any) {
        return 1 - easein(1 - t);
      }

      const radius = easeout(elapsedRatio) * 25 + 5;
      const opacity = easeout(1 - elapsedRatio);

      const style2 = new Style({
        stroke: new Stroke({
          color: 'rgba(29, 60, 131,' + opacity + ')',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(29, 60, 131, ' + opacity + ')'
        })
      });

      const style = new Style({
        image: new Circle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, ' + opacity + ')',
            width: 0.25 + opacity
          })
        })
      });

      vectorContext.setStyle(style2);
      const polygeometry = flashGeom[0].getGeometry();
      vectorContext.drawGeometry(polygeometry);
      if (elapsed > duration) {
        observable2.unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postcompose animation
      this.render();
    }
  }

  flashline(feature: any) {
    const duration = 3000;
    const start = new Date().getTime();
    const listenerKey = this.map.on('postcompose', animate);
    const observable2 = new Observable();


    function animate(event: any) {
      const vectorContext = event.vectorContext;
      const frameState = event.frameState;
      const flashGeom = feature.getGeometry().clone();
      const elapsed = frameState.time - start;
      const elapsedRatio = elapsed / duration;

      // radius will be 5 at start and 30 at end.
      function easein(t: any) {
        return Math.pow(t, 3);
      }

      function easeout(t: any) {
        return 1 - easein(1 - t);
      }

      const radius = easeout(elapsedRatio) * 25 + 5;
      const opacity = easeout(1 - elapsedRatio);

      const style = new Style({
        stroke: new Stroke({
          color: 'rgba(0, 179, 253, ' + opacity + ')',
          lineCap: 'square',
          width: 6 + (4 * opacity)
        })
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {
        observable2.unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postcompose animation
      this.render();
    }
  }

  createBeacon(name: string, BSSID1: string, BSSID2: string, BSSID3: string, BSSID4: string, TXPower: string, inactive: boolean) {

    log.debug('create new Beacon at x: ' + this.createPOICoordinateX + ' y: ' + this.createPOICoordinateY);

    const poiFeature = new Feature({
      geom: new Point([this.createPOICoordinateX, this.createPOICoordinateY])
    });

    poiFeature.set('name', name);
    if (!BSSID1) {
      poiFeature.set('BSSID1', null);
    } else {
      poiFeature.set('BSSID1', BSSID1);
    }
    if (!BSSID2) {
      poiFeature.set('BSSID2', null);
    } else {
      poiFeature.set('BSSID2', BSSID2);
    }
    if (!BSSID3) {
      poiFeature.set('BSSID3', null);
    } else {
      poiFeature.set('BSSID3', BSSID3);
    }
    if (!BSSID4) {
      poiFeature.set('BSSID3', null);
    } else {
      poiFeature.set('BSSID3', BSSID3);
    }
    if (!TXPower) {
      poiFeature.set('TXPower', null);
    } else {
      poiFeature.set('TXPower', TXPower);
    }
    // poiFeature.set('table', this.activeRoomTable); not really needed
    poiFeature.set('ebene', this.activeFloorNumber);

    if (!inactive) {
      poiFeature.set('inactive', false);
    } else {
      poiFeature.set('inactive', inactive);
    }
    console.log('inactive', inactive);

    const format = new WFS({});
    const node = format.writeTransaction([poiFeature], null, null, {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'beacons',
      srsName: 'EPSG:3857',
      nativeElements: []
    });

    const xmlSerializer = new XMLSerializer();
    this.restService.sendWFSTRequest(xmlSerializer.serializeToString(node)).then((data) => {
      log.debug('create beacon wfst result', data);
      this.contextMenuOpen = false;
      this.redrawBeacons();
      // show poi info window ?!
      this.reloadSearchSuggestions();
      this.toast.success('Access Point erfolgreich erstellt', '', {
        positionClass: 'toast-bottom-left',
      });
    }, (error) => {
      log.debug('create beacon wfst result error', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  deleteBeacon() {
    const format = new WFS({});
    const node = format.writeTransaction(null, null, [this.clickedBeaconFeature], {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'beacons',
      srsName: 'EPSG:3857',
      nativeElements: []
    });
    const s = new XMLSerializer();
    this.restService.sendWFSTRequest(s.serializeToString(node)).then((data) => {
      log.debug('delete beacon wfst result', data);
      this.contextMenuOpen = false;
      this.redrawBeacons();
      // this.layer_beacons_seletion.getSource().clear();
      this.reloadSearchSuggestions();
      this.toast.success('AccessPoint erfolgreich gelöscht', '', {
        positionClass: 'toast-bottom-left',
      });
    }, (error) => {
      log.debug('delete beacon wfst result error', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  createPOI(name: string, description: string, icon: string, category: string) {

    log.debug('create new poi at x: ' + this.createPOICoordinateX + ' y: ' + this.createPOICoordinateY);

    const poiFeature = new Feature({
      geom: new Point([this.createPOICoordinateX, this.createPOICoordinateY])
    });

    poiFeature.set('name', name);
    if (description) { poiFeature.set('details', description); }
    poiFeature.set('icon', icon);
    poiFeature.set('cat', category);
    poiFeature.set('table', this.activeRoomTable);
    poiFeature.set('ebene', this.activeFloorNumber);

    const format = new WFS({});
    const node = format.writeTransaction([poiFeature], null, null, {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'poi',
      srsName: 'EPSG:3857',
      nativeElements: []
    });

    const xmlSerializer = new XMLSerializer();
    this.restService.sendWFSTRequest(xmlSerializer.serializeToString(node)).then((data) => {
      log.debug('create poi wfst result', data);
      this.contextMenuOpen = false;

      if (!this.show_poi) {
        this.togglePoiCategory('all');
        this.show_poi = true;
      }

      this.redrawPOI();
      // show poi info window ?!
      this.reloadSearchSuggestions();
      this.toast.success('POI erfolgreich erstellt', '', {
        positionClass: 'toast-bottom-left',
      });


    }, (error) => {
      log.debug('create poi wfst result error', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  deletePOI() {
    const format = new WFS({});
    const node = format.writeTransaction(null, null, [this.clickedPOIFeature], {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'poi',
      srsName: 'EPSG:3857',
      nativeElements: []
    });
    const s = new XMLSerializer();
    this.restService.sendWFSTRequest(s.serializeToString(node)).then((data) => {
      log.debug('delete poi wfst result', data);
      this.contextMenuOpen = false;
      this.redrawPOI();
      this.layer_selected_POI.getSource().clear();
      this.reloadSearchSuggestions();
      this.toast.success('POI erfolgreich gelöscht', '', {
        positionClass: 'toast-bottom-left',
      });
    }, (error) => {
      log.debug('delete poi wfst result error', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  hideAllRoomWmsLayer() {
    this.layer_wms_eg00.setVisible(false);
    this.layer_wms_eg01.setVisible(false);
    this.layer_wms_og1.setVisible(false);
    this.layer_wms_og2.setVisible(false);
    this.layer_wms_og3.setVisible(false);
    this.layer_wms_og4.setVisible(false);
    this.layer_wms_og5.setVisible(false);
    this.layer_wms_og6.setVisible(false);
    this.layer_wms_og7.setVisible(false);
    this.layer_wms_og8.setVisible(false);
    this.layer_wms_og9.setVisible(false);
    this.layer_wms_og10.setVisible(false);
    this.layer_wms_og11.setVisible(false);
  }

  hideAllOutlineWmsLayer() {
    this.layer_outline_eg00.setVisible(false);
    this.layer_outline_eg01.setVisible(false);
    this.layer_outline_og1.setVisible(false);
    this.layer_outline_og2.setVisible(false);
    this.layer_outline_og3.setVisible(false);
    this.layer_outline_og4.setVisible(false);
    this.layer_outline_og5.setVisible(false);
    this.layer_outline_og6.setVisible(false);
    this.layer_outline_og7.setVisible(false);
    this.layer_outline_og8.setVisible(false);
    this.layer_outline_og9.setVisible(false);
    this.layer_outline_og10.setVisible(false);
    this.layer_outline_og11.setVisible(false);
  }

  hideAllRoutingWmsLayer() {
    this.layer_routing_eg00.setVisible(false);
    this.layer_routing_eg01.setVisible(false);
    this.layer_routing_og01.setVisible(false);
    this.layer_routing_og02.setVisible(false);
    this.layer_routing_og03.setVisible(false);
    this.layer_routing_og04.setVisible(false);
    this.layer_routing_og05.setVisible(false);
    this.layer_routing_og06.setVisible(false);
    this.layer_routing_og07.setVisible(false);
    this.layer_routing_og08.setVisible(false);
    this.layer_routing_og09.setVisible(false);
    this.layer_routing_og10.setVisible(false);
    this.layer_routing_og11.setVisible(false);
  }

  switchToLayer(layer: number) {
    this.hideAllRoomWmsLayer();
    this.hideAllOutlineWmsLayer();
    this.hideAllRoutingWmsLayer();
    this.layer_selected_POI.getSource().clear();
    if (this.user_room_shown) {
      this.show_user_room('redraw'); // redraw da er immer auf der karte bleiben soll wenn er einmal angezeigt wird DK-108
    }
    this.layer_wms_eg01_3d.setVisible(false); // todo kann entfernt werde, vorher prüfen
    this.layer_navigation_routes.getSource().refresh({force: true});
    this.layer_navigation_routes_bg.getSource().refresh({force: true});
    this.layer_route_icons.getSource().refresh({force: true});
    console.log('test 123');
    switch (layer) {
      case 0:
        this.layer_wms_eg00.setVisible(true);
        this.layer_outline_eg00.setVisible(this.show_backgroundmap);
        this.activeWMS = this.source_wms_eg00;
        this.activeVectorSource = this.layer_routing_eg00.getSource();
        this.activeRoomTable = 'public.DIANA_EG00_POLY';
        this.activeFloorNumber = 0;
        this.stationinfo = 'Ausgang, Empfang, Seelsorge, Forum, Patientenberatung, Atrium Café & Kiosk, Bücherecke, Parkplatz An- und Abreise';
        break;
      case 1:
        this.layer_wms_eg01.setVisible(true);
        this.layer_outline_eg01.setVisible(this.show_backgroundmap);
        this.activeWMS = this.source_wms_eg01;
        this.activeVectorSource = this.layer_routing_eg01.getSource();
        this.activeRoomTable = 'public.DIANA_EG01_POLY';
        this.activeFloorNumber = 1;
        this.stationinfo = 'Psychosomatik, Speisesaal, MVZ, EKG, Lehrküche, Ernährungsberatung, Bewegungsbäder, Diagnostik, Röntgen, Neuropsychologie, Therapiebereiche';
        break;
      case 2:
        this.layer_wms_og1.setVisible(true);
        this.activeWMS = this.source_wms_og1;
        this.activeVectorSource = this.layer_routing_og01.getSource();
        this.activeRoomTable = 'public.DIANA_OG01_POLY';
        this.layer_outline_og1.setVisible(true);
        this.activeFloorNumber = 2;
        this.stationinfo = 'Psychosomatik';
        break;
      case 3:
        this.layer_wms_og2.setVisible(true);
        this.activeWMS = this.source_wms_og2;
        this.activeVectorSource = this.layer_routing_og02.getSource();
        this.activeRoomTable = 'public.DIANA_OG02_POLY';
        this.layer_outline_og2.setVisible(true);
        this.activeFloorNumber = 3;
        this.stationinfo = 'Psychosomatik, Orthopädie';
        break;
      case 4:
        this.layer_wms_og3.setVisible(true);
        this.activeWMS = this.source_wms_og3;
        this.activeVectorSource = this.layer_routing_og03.getSource();
        this.activeRoomTable = 'public.DIANA_OG03_POLY';
        this.layer_outline_og3.setVisible(true);
        this.activeFloorNumber = 4;
        this.stationinfo = 'Neurologie, Musiktherapie';
        break;
      case 5:
        this.layer_wms_og4.setVisible(true);
        this.activeWMS = this.source_wms_og4;
        this.activeVectorSource = this.layer_routing_og04.getSource();
        this.activeRoomTable = 'public.DIANA_OG04_POLY';
        this.layer_outline_og4.setVisible(true);
        this.activeFloorNumber = 5;
        this.stationinfo = 'Neurologie';
        break;
      case 6:
        this.layer_wms_og5.setVisible(true);
        this.activeWMS = this.source_wms_og5;
        this.activeVectorSource = this.layer_routing_og05.getSource();
        this.activeRoomTable = 'public.DIANA_OG05_POLY';
        this.layer_outline_og5.setVisible(true);
        this.activeFloorNumber = 6;
        this.stationinfo = 'Orthopädie, Hygienefachkraft, Chefarzt Orthopädie, Chefarzt Neurologie, Pflegedirektion';
        break;
      case 7:
        this.layer_wms_og6.setVisible(true);
        this.activeWMS = this.source_wms_og6;
        this.activeVectorSource = this.layer_routing_og06.getSource();
        this.activeRoomTable = 'public.DIANA_OG06_POLY';
        this.layer_outline_og6.setVisible(true);
        this.activeFloorNumber = 7;
        this.stationinfo = 'Orthopädie';
        break;
      case 8:
        this.layer_wms_og7.setVisible(true);
        this.activeWMS = this.source_wms_og7;
        this.activeVectorSource = this.layer_routing_og07.getSource();
        this.activeRoomTable = 'public.DIANA_OG07_POLY';
        this.layer_outline_og7.setVisible(true);
        this.activeFloorNumber = 8;
        this.stationinfo = 'Orthopädie';
        break;
      case 9:
        this.layer_wms_og8.setVisible(true);
        this.activeWMS = this.source_wms_og8;
        this.activeVectorSource = this.layer_routing_og08.getSource();
        this.activeRoomTable = 'public.DIANA_OG08_POLY';
        this.layer_outline_og8.setVisible(true);
        this.activeFloorNumber = 9;
        this.stationinfo = 'Geschäftführung, Finanzbuchhaltung, Prozess- und Qualitätsmanagement, Personalabteilung';
        break;
      case 10:
        this.layer_wms_og9.setVisible(true);
        this.activeWMS = this.source_wms_og9;
        this.activeVectorSource = this.layer_routing_og09.getSource();
        this.activeRoomTable = 'public.DIANA_OG09_POLY';
        this.layer_outline_og9.setVisible(true);
        this.activeFloorNumber = 10;
        this.stationinfo = 'Zentraler Schreibdienst';
        break;
      case 11:
        this.layer_wms_og10.setVisible(true);
        this.activeWMS = this.source_wms_og10;
        this.activeVectorSource = this.layer_routing_og10.getSource();
        this.activeRoomTable = 'public.DIANA_OG10_POLY';
        this.layer_outline_og10.setVisible(true);
        this.activeFloorNumber = 11;
        this.stationinfo = '';
        break;
      case 12:
        this.layer_wms_og11.setVisible(true);
        this.activeWMS = this.source_wms_og11;
        this.activeVectorSource = this.layer_routing_og11.getSource();
        this.activeRoomTable = 'public.DIANA_OG11_POLY';
        this.layer_outline_og11.setVisible(true);
        this.activeFloorNumber = 12;
        this.stationinfo = '';
        break;
    }
    if (this.roomClicked && this.activeRoomTable === this.roomTable) {
      this.layer_selected_room.setVisible(true);
    } else {
      this.layer_selected_room.setVisible(false);
    }
    if (this.search_result_data.length !== 0) {
      this.refreshSearchResults();
    }
    if (this.setting_show_cleaning_status) {
      this.getRoomCleaningStatus();
    }
    if (this.cleaning_welcome_selection) {
      this.showRoomsByDistrictOrStatus();
    }
    if (this.showRouteNetwork) {
      this.showRouteNetwork();
    }
    this.redrawPOI();
    if (this.show_beacons === true) {
      this.redrawBeacons();
    }
    this.redrawRoomNames();

    if (this.roombookfiltered === true) {
      this.roombookFilterdFloor = this.activeRoomTable;
      this.getRoomsData();
    }
  }

  toggleRouteNetworkLayer() {
    this.setting_show_route_network = !this.setting_show_route_network;
    this.showRouteNetwork();
    // add dragbox to map
    this.map.addInteraction(this.dragBox);
    if (this.setting_show_route_network === false) {
      // delete dragbox
      this.map.removeInteraction(this.dragBox);
    }
  }

  showRouteNetwork() {
    switch (this.activeFloorNumber) {
      case 0:
        this.layer_routing_eg00.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_eg00.getSource(); }
        break;
      case 1:
        this.layer_routing_eg01.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_eg01.getSource(); }
        break;
      case 2:
        this.layer_routing_og01.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og01.getSource(); }
        break;
      case 3:
        this.layer_routing_og02.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og02.getSource(); }
        break;
      case 4:
        this.layer_routing_og03.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og03.getSource(); }
        break;
      case 5:
        this.layer_routing_og04.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og04.getSource(); }
        break;
      case 6:
        this.layer_routing_og05.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og05.getSource(); }
        break;
      case 7:
        this.layer_routing_og06.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og06.getSource(); }
        break;
      case 8:
        this.layer_routing_og07.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og07.getSource(); }
        break;
      case 9:
        this.layer_routing_og08.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og08.getSource(); }
        break;
      case 10:
        this.layer_routing_og09.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og09.getSource(); }
        break;
      case 11:
        this.layer_routing_og10.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og10.getSource(); }
        break;
      case 12:
        this.layer_routing_og11.setVisible(this.setting_show_route_network);
        if ( this.setting_show_route_network === true ) { this.activeVectorSource = this.layer_routing_og11.getSource(); }
        break;
    }
  }

  toggleRoomCleaningStatus() {
    if (this.setting_show_cleaning_status) {
      this.setting_show_cleaning_status = false;
      this.layer_cleaning_status.getSource().clear();
    } else {
      this.setting_show_cleaning_status = true;
      this.getRoomCleaningStatus();
    }
  }

  getRoomCleaningStatus() {
    this.layer_cleaning_status.getSource().clear();
    const format = new WKT();
    this.setting_show_cleaning_status = true;
    const input_table = this.activeRoomTable;
    this.restService.getRoomPolyData(input_table).then((data) => {
      const thisref = this;
      const roomNameFeatures = [];
      roomNameFeatures.push(data);

      const cleaned_style = new Style({
        fill: new Fill({
          color: 'rgba(39, 164, 68, 0.6)'
        })
      });

      const uncleaned_style = new Style({
        fill: new Fill({
          color: 'rgba(220, 53, 69, 0.6)'
        })
      });

      const flawed_style = new Style({
        fill: new Fill({
          color: 'rgba(139, 164, 68, 0.6)'
        })
      });

      const no_status_style = new Style({
        fill: new Fill({
          color: 'rgba(86, 86, 86, 0.6)'
        })
      });

      if (roomNameFeatures) {
        roomNameFeatures[0].forEach(function (eachObj: any) {
          let feature: any;
          const multiPolygon = eachObj.st_astext;
          const roomCleaningStatus = eachObj.rb_status;
          feature = format.readFeatures(multiPolygon, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:3857'
          });
          if (roomCleaningStatus === 'gereinigt') {
            feature[0].setStyle(cleaned_style);
          } else if (roomCleaningStatus === 'unrein') {
            feature[0].setStyle(uncleaned_style);
          } else if (roomCleaningStatus === 'maengel') {
            feature[0].setStyle(flawed_style);
          } else {
            feature[0].setStyle(no_status_style);
          }
          thisref.layer_cleaning_status.getSource().addFeatures(feature);
        });
      }
    }, (error) => {
      log.debug('rest api getRoomPolyData', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  private getRoomNameFeatureStyle(feature: any) {
    const iconStyles = () => {
      let zoom: any = this.map.getView().getZoom();
      zoom = Math.trunc(zoom);
      let name: any = feature.r_name;
      let number: any = feature.r_nummer;
      if (feature.r_name === null) { name = ''; }
      if (feature.r_nummer === null) { number = ''; }
      let font_size;
      let font_size2;
      switch (zoom) {
        case 25:
          font_size = 16;
          font_size2 = 12;
          break;
        case 24:
          font_size = 14;
          font_size2 = 11;
          break;
        case 23:
          font_size = 13;
          font_size2 = 10;
          break;
        case 22:
          font_size = 12;
          font_size2 = 9;
          break;
        case 21:
          font_size = 11;
          font_size2 = 8;
          break;
        case 20:
          font_size = 7;
          font_size2 = 0;
          break;
        default:
          font_size = 0;
          font_size2 = 0;
          break;
      }
      return [new Style({
        text: new Text({
          font: '' + font_size + 'px Roboto,sans-serif',
          text: name,
          offsetY: 0,
        }),
        fill: new Fill({
          color: 'rgb(29, 60, 131, 0.3)'
        })
      }),
      new Style({
          text: new Text({
            font: '' + font_size2 + 'px Roboto,sans-serif',
            text: number,
            offsetY: 12,
          }),
          fill: new Fill({
            color: 'rgb(29, 60, 131, 0.2)'
          })
        })];
    };
    return iconStyles;
  }

  showRoomsByDistrictOrStatus() {
    this.layer_cleaning_status.getSource().clear();
    const format = new WKT();
    // this.setting_show_cleaning_status = false;
    const requestBody = {'rb_revier': this.dropdown_selected_district, 'rb_status': this.dropdown_selected_status};
    this.restService.getRoomsByDistrictOrStatus(requestBody).then((data) => {
      log.debug('Room Cleaning Status by District', data);

      const thisref = this;
      const roomNameFeatures = [];
      roomNameFeatures.push(data);

      if (roomNameFeatures) {
        roomNameFeatures[0].forEach(function (eachObj: any) {
          let feature: any;
          const multiPolygon = eachObj.st_astext;
          const roomCleaningStatus = eachObj.rb_status;
          const roomTable = eachObj.table;
          feature = format.readFeatures(multiPolygon, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:3857'
          });

          const cleaned_style = new Style({
            fill: new Fill({
              color: 'rgba(39, 164, 68, 0.6)'
            })
          });

          const uncleaned_style = new Style({
            fill: new Fill({
              color: 'rgba(220, 53, 69, 0.6)'
            })
          });

          const flawed_style = new Style({
            fill: new Fill({
              color: 'rgba(139, 164, 68, 0.6)'
            })
          });

          const no_status_style = new Style({
            fill: new Fill({
              color: 'rgba(86, 86, 86, 0.6)'
            })
          });

          if (roomCleaningStatus === 'gereinigt') {
            feature[0].setStyle(cleaned_style);
          } else if (roomCleaningStatus === 'unrein') {
            feature[0].setStyle(uncleaned_style);
          } else if (roomCleaningStatus === 'maengel') {
            feature[0].setStyle(flawed_style);
          } else {
            feature[0].setStyle(no_status_style);
          }
          if (roomTable === thisref.activeRoomTable) {
            thisref.layer_cleaning_status.getSource().addFeatures(feature);
          } else {
            if (!(thisref.activeRoomsTable.indexOf(roomTable) !== -1)) {
              thisref.activeRoomsTable.push(roomTable);
            }
          }
        });
      }
    }, (error) => {
      log.debug('rest api getRoomsByDistrictOrStatus', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  toggleInfoWindow() {
    this.roomClicked = false;
    this.poiClicked = false;
    this.infowindowhidden = true;
    this.layer_selected_room.getSource().clear();
    this.layer_selected_POI.getSource().clear();
    if (this.searchSaved) {
      this.layer_search_results.setVisible(false);
    }
  }


  setNaviPoint(point_type: any, feature: any, featuretype: any) {

    // todo check if poly or point, if poly take middlepoint

    let extent: any;
    let attributes: any = [];

    // if we supply no feature, we choose the clicked room or clicked poi (used when button in roominfo is clicked)
    if (!feature) {
      if (featuretype === 'poi') {
        const feat = this.clickedPoiFeatureSubject.getValue();
        extent = feat.getGeometry().getExtent();
        attributes = feat.getProperties();
      }
      if (featuretype === 'room') {
        extent = this.clickedFeaturesOnMap.getGeometry().getExtent();
        attributes = this.clickedFeaturesOnMap.getProperties();
      }
    }

    // if we supply a 'feature' (from searchresults or favourites)
    if (feature) {
      // if we are on cordova, routing to a searchresult or fav always happens FROM users room
      if (this.isCordovaApp) {
        const zimmernummer = this.user_room;
        let feature2: any;

        // todo: put this global at startup so we dont have to run the restcall everytime we click the locate-me button or a search-result !
        this.restService.getRoomByRoomNumber(zimmernummer).then((data: any[]) => {

          if (data.length === 0) {
            this.toast.error('Ihr Zimmer konnte nicht gefunden werden', 'Fehler', {
              positionClass: 'toast-bottom-left',
            });
          }

          if (data.length !== 0) {
            const format = new WKT();
            const Polygon = data[0].st_astext;
            feature2 = format.readFeatures(Polygon, {
              featureProjection: 'EPSG:3857',
              dataProjection: 'EPSG:3857'
            });
            const extent_start = feature2[0].getGeometry().getExtent();
            const centerX_start = (extent_start[0] + extent_start[2]) / 2;
            const centerY_start = (extent_start[1] + extent_start[3]) / 2;
            this.NaviStart = [centerX_start, centerY_start];
            this.NaviStartFloor = +data[0].rb_ebene;
            this.NaviStartName = 'Mein Zimmer';
            // autostart if both set
            if (this.NaviStart && this.NaviEnd) {
              this.startNavi(false);
            }
          }
        }, (error) => {
          log.debug('rest api getpolydatageoserver error', error);
          this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
            positionClass: 'toast-bottom-left',
          });
        });
      }

      // we dont get a real feature from the search or favs, but we get st_as text
      // so we create a temp feature with geometry to calculate its center point

      if (feature !== 'userroom') {
        const format_temp = new WKT();
        const multipoly = feature.st_astext;
        const temp_feature = format_temp.readFeatures(multipoly);
        extent = temp_feature[0].getGeometry().getExtent();
        attributes.r_name = feature.r_name;
        attributes.rb_ebene = feature.rb_ebene;
      }
    }

    const centerX = (extent[0] + extent[2]) / 2;
    const centerY = (extent[1] + extent[3]) / 2;
    const centerCoord = [centerX, centerY];
    log.debug(centerCoord);

    if (point_type === 'start' && !feature) {
      this.NaviStart = centerCoord;
      this.NaviStartFloor = this.activeFloorNumber;
      this.NaviStartName = attributes.r_name;
      this.NaviStartNumber = attributes.r_nummer;
      if (featuretype === 'poi') { this.NaviStartName = attributes.name; this.NaviStartNumber = 'POI'; this.NaviStartFloor = attributes.ebene; }
      if (!attributes.r_name && !attributes.name) { this.NaviStartName = 'Kein Name vergeben'; }
      if (!attributes.r_nummer) { this.NaviStartNumber = ''; }
    }
    if (point_type === 'end') {
      this.NaviEnd = centerCoord;
      this.NaviEndFloor = attributes.rb_ebene;
      this.NaviEndName = attributes.r_name;
      this.NaviEndNumber = attributes.r_nummer;
      if (featuretype === 'poi') { this.NaviEndName = attributes.name; this.NaviEndNumber = 'POI'; this.NaviEndFloor = attributes.ebene; }
      if (!attributes.r_name && !attributes.name) { this.NaviEndName = 'Kein Name vergeben'; }
      if (!attributes.r_nummer) { this.NaviEndNumber = ''; }
    }



    // autostart navigation wenn anfangs und endpunkt gesetzt sind
    if (this.NaviStart && this.NaviEnd ) {
      if (this.NaviStart[0] === this.NaviEnd[0] && this.NaviStart[1] === this.NaviEnd[1] && +this.NaviStartFloor === +this.NaviEndFloor) {
        this.toast.error('Die Start- und Endpunkte sind gleich! Bitte wählen Sie einen anderen Punkt.', 'Fehler', {
          positionClass: 'toast-center-center',
        });
      } else {
        this.startNavi(false);
      }
    }

  }

  startNavi(live: any) {
    this.layerswitcher_loading = true;
    this.routeComponentshown = true;
    const thisref = this;
    if (this.NaviStart && this.NaviEnd) {
      // clear 2d
      thisref.layer_navigation_routes.getSource().clear();
      thisref.layer_navigation_routes_bg.getSource().clear();
      thisref.layer_route_icons.getSource().clear();
      thisref.activeRoomFloors = [];
      this.navigation_line_segment_features = [];

      // show route
     if (live) {
       this.restService.getLiveRoute(this.NaviStart[0], this.NaviStart[1], this.NaviStartFloor, this.NaviEnd[0], this.NaviEnd[1], this.NaviEndFloor, this.show_routeAccessible, this.show_routePublic, this.authService.credentials['userrole']).then((data: any) => {
         this.layerswitcher_loading = false;
         this.navigation_line_segment_features = data;
         const format = new GeoJSON();
         let features: Feature[];
         features = format.readFeatures(data, {
           featureProjection: 'EPSG:3857',
           dataProjection: 'EPSG:3857'
         });

         // add features to global
         this.NaviRouteSegments = features;

         let i = 0;

         if ( data['routelength']) { thisref.NaviLength = data['routelength'].toFixed(2); } else { thisref.NaviLength = ''; }
         if ( data['routewalktime']) { thisref.NaviTime = data['routewalktime'].toFixed(0); } else { thisref.NaviTime = ''; }

         if (thisref.NaviTime > 60) {
           thisref.NaviTime = Math.floor(thisref.NaviTime / 60) + ' Minute(n) ' + ((thisref.NaviTime) - (Math.floor(thisref.NaviTime / 60)) * 60) + ' Sekunden'; } else {
           thisref.NaviTime = thisref.NaviTime + ' Sekunden';
         }

         // add route icons
         const segmentsLength = features.length;
         const startcoords = data['start_point'];
         const temp =  data['features'][segmentsLength - 1]['geometry']['coordinates']; // coordinaten_array des letzten segmentes
         const endcoords = data['features'][segmentsLength - 1]['geometry']['coordinates'][temp.length - 1]; // koordinaten des letzten punktes des coordinaten_arrays des letzten segmentes



         const startMarker = new Feature(new Point([startcoords[0], startcoords[1]]));
         startMarker.set('type', 'RouteIcon');
         startMarker.set('floor',  data['start_floor']);
         startMarker.set('routeIconName',  'start');
         // todo layer mit aktuellem layer vergleichen
         thisref.layer_route_icons.getSource().addFeature( startMarker);



         const endMarker = new Feature(new Point([endcoords[0], endcoords[1]]));
         endMarker.set('type', 'RouteIcon');
         endMarker.set('floor',  data['end_floor']);
         endMarker.set('routeIconName',  'end');
         thisref.layer_route_icons.getSource().addFeature( endMarker);


         features.forEach(function (feat: any, index: any) {
           let stairMarker: any;
           // add floornames to array
           const fullfloorname = feat.get('fullfloorname');
           thisref.activeRoomFloors.push( fullfloorname );

           // add elevator icons
           if ( feat.get('type_id') === 1 && features[index - 1].get('type_id') === 1 ) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[0]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'elevator');
           }

           if ( index + 1 !== features.length && feat.get('type_id') === 1 && features[index + 1].get('type_id') === 1 && features[index - 1].get('type_id') !== 1) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[1]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'elevatorup');
             if (features[index + 1].get('floor') > features[index].get('floor')) { stairMarker.set('routeIconName',  'elevatorup'); } else {stairMarker.set('routeIconName',  'elevatordown'); }
           }

           if ( index !== 0 && feat.get('type_id') === 2 && features[index - 1].get('type_id') === 2 ) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[0]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'stair');
           }

           if ( index + 1 !== features.length && feat.get('type_id') === 2 && features[index + 1].get('type_id') === 2 && features[index - 1].get('type_id') !== 2) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[1]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'stair');
             if (features[index + 1].get('floor') > features[index].get('floor')) { stairMarker.set('routeIconName',  'stairup'); } else {stairMarker.set('routeIconName',  'stairdown'); }
           }

           if (stairMarker) { thisref.layer_route_icons.getSource().addFeature( stairMarker); }

         });


         // alert(data['routelength']);
         features.forEach(function (feat: any) {
           i++;
           setTimeout(() => {
             thisref.layer_navigation_routes.getSource().addFeature(feat);
             thisref.layer_navigation_routes_bg.getSource().addFeature(feat);
             // thisref.bounceanimation(feature);
           }, 60 * i);
         });
         thisref.directiveRef.scrollToY(670, 700) ;
       }).catch((error) => {
         log.debug('rest api getPolyDataGeoServer', error);
         this.layerswitcher_loading = false;
         this.toast.error('Die Route konnte nicht ermittelt werden!', 'Fehler', {
           positionClass: 'toast-bottom-left',
         });
       });
     } else {
       this.restService.getRoute(this.NaviStart[0], this.NaviStart[1], this.NaviStartFloor, this.NaviEnd[0], this.NaviEnd[1], this.NaviEndFloor, this.show_routeAccessible, this.show_routePublic, this.authService.credentials['userrole']).then((data: any) => {
         this.layerswitcher_loading = false;
         this.navigation_line_segment_features = data;
         const format = new GeoJSON();
         let features: Feature[];
         features = format.readFeatures(data, {
           featureProjection: 'EPSG:3857',
           dataProjection: 'EPSG:3857'
         });

         // add features to global
         this.NaviRouteSegments = features;

         let i = 0;

         if ( data['routelength']) { thisref.NaviLength = data['routelength'].toFixed(2); } else { thisref.NaviLength = ''; }
         if ( data['routewalktime']) { thisref.NaviTime = data['routewalktime'].toFixed(0); } else { thisref.NaviTime = ''; }

         if (thisref.NaviTime > 60) {
           thisref.NaviTime = Math.floor(thisref.NaviTime / 60) + ' Minute(n) ' + ((thisref.NaviTime) - (Math.floor(thisref.NaviTime / 60)) * 60) + ' Sekunden'; } else {
           thisref.NaviTime = thisref.NaviTime + ' Sekunden';
         }

         // add route icons
         const segmentsLength = features.length;
         const startcoords = data['start_point'];
         const temp =  data['features'][segmentsLength - 1]['geometry']['coordinates']; // coordinaten_array des letzten segmentes
         const endcoords = data['features'][segmentsLength - 1]['geometry']['coordinates'][temp.length - 1]; // koordinaten des letzten punktes des coordinaten_arrays des letzten segmentes



         const startMarker = new Feature(new Point([startcoords[0], startcoords[1]]));
         startMarker.set('type', 'RouteIcon');
         startMarker.set('floor',  data['start_floor']);
         startMarker.set('routeIconName',  'start');
         // todo layer mit aktuellem layer vergleichen
         thisref.layer_route_icons.getSource().addFeature( startMarker);



         const endMarker = new Feature(new Point([endcoords[0], endcoords[1]]));
         endMarker.set('type', 'RouteIcon');
         endMarker.set('floor',  data['end_floor']);
         endMarker.set('routeIconName',  'end');
         thisref.layer_route_icons.getSource().addFeature( endMarker);


         features.forEach(function (feat: any, index: any) {
           let stairMarker: any;
           // add floornames to array
           const fullfloorname = feat.get('fullfloorname');
           thisref.activeRoomFloors.push( fullfloorname );

           // add elevator icons
           if ( feat.get('type_id') === 1 && features[index - 1].get('type_id') === 1 ) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[0]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'elevator');
           }

           if ( index + 1 !== features.length && feat.get('type_id') === 1 && features[index + 1].get('type_id') === 1 && features[index - 1].get('type_id') !== 1) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[1]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'elevatorup');
             if (features[index + 1].get('floor') > features[index].get('floor')) { stairMarker.set('routeIconName',  'elevatorup'); } else {stairMarker.set('routeIconName',  'elevatordown'); }
           }

           if ( index !== 0 && feat.get('type_id') === 2 && features[index - 1].get('type_id') === 2 ) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[0]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'stair');
           }

           if ( index + 1 !== features.length && feat.get('type_id') === 2 && features[index + 1].get('type_id') === 2 && features[index - 1].get('type_id') !== 2) {
             const coords = feat.getGeometry().getCoordinates();
             stairMarker = new Feature(new Point(coords[1]));
             stairMarker.set('type', 'RouteIcon');
             stairMarker.set('floor', coords[0][2]);
             stairMarker.set('routeIconName',  'stair');
             if (features[index + 1].get('floor') > features[index].get('floor')) { stairMarker.set('routeIconName',  'stairup'); } else {stairMarker.set('routeIconName',  'stairdown'); }
           }

           if (stairMarker) { thisref.layer_route_icons.getSource().addFeature( stairMarker); }

         });


         // alert(data['routelength']);
         features.forEach(function (feat: any) {
           i++;
           setTimeout(() => {
             thisref.layer_navigation_routes.getSource().addFeature(feat);
             thisref.layer_navigation_routes_bg.getSource().addFeature(feat);
             // thisref.bounceanimation(feature);
           }, 60 * i);
         });
         thisref.directiveRef.scrollToY(670, 700) ;
       }).catch((error) => {
         log.debug('rest api getPolyDataGeoServer', error);
         this.layerswitcher_loading = false;
         this.toast.error('Die Route konnte nicht ermittelt werden!', 'Fehler', {
           positionClass: 'toast-bottom-left',
         });
       });

     }

    }

  }

  killRoute() {
    this.layer_route_icons.getSource().clear();
    this.layer_navigation_routes.getSource().clear();
    this.layer_navigation_routes_bg.getSource().clear();
    this.layer_route_icons.getSource().clear();
    this.NaviStart = null;
    this.NaviEnd = null;
    this.NaviEndName = '';
    this.NaviStartName = '';
    this.NaviEndNumber = '';
    this.NaviStartNumber = '';
    this.NaviRouteSegments = null;
    this.activeRoomFloors = [];
  }

  doSearchByString(searchInput: string) {
    if (this.searchFilterdFloor !== 'all') {
      this.searchFilterdFloor = this.activeRoomTable;
    }
    const userrole: any = this.authService.credentials['userrole']; // userrole kann bald global, wird aktuell in 2 funktionne verwendet
    this.restService.getSearchData(searchInput, this.searchFilterBy, this.searchSortBy, this.searchFilterdFloor, userrole).then((data) => {
      this.search_result_data = data;
      this.search_results_hidden = false;
      this.infowindowhidden = false;
      this.roomClicked = false;
      this.poiClicked = false;
      this.searchSaved = true;
      this.showSearchOnMap();
    }).catch((error) => {
      log.debug('rest api getSearchData', error);
      this.toast.error('Verbindung mit der Suche kann nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  refreshSearchResults() {
    if (this.searchFilterdFloor !== 'all') {
      this.searchFilterdFloor = this.activeRoomTable;
    }
    const userrole: any = this.authService.credentials['userrole'];
    this.restService.getSearchData(this.searchInputString, this.searchFilterBy, this.searchSortBy, this.searchFilterdFloor, userrole).then((data) => {
      this.search_result_data = data;
      this.showSearchOnMap();
    }).catch((error) => {
      log.debug('rest api getSearchData', error);
      this.toast.error('Verbindung mit der Suche kann nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  showSearchOnMap() {
    const format = new WKT();
    this.layer_search_results.getSource().clear();
    const thisref = this;
    this.activeRoomsTable = [];
    if (thisref.search_result_data) {
      // searchtype route einfügen - backend löst diesen aus wenn "weg nach ..." gesucht wird. saerchtype route nimmt das erste ergebniss der route und setzt dieses als navi endpunkt
      thisref.search_result_data.forEach(function (eachObj: any) {
        const floorTable = eachObj.table;
        if (eachObj.search_type === 'room') {
          const multiPolygon = eachObj.st_astext;
          if (floorTable === thisref.activeRoomTable) {
            const features = format.readFeatures(multiPolygon);
            thisref.layer_search_results.getSource().addFeatures(features);
          } else {
            if (!(thisref.activeRoomsTable.indexOf(floorTable) !== -1)) {
              thisref.activeRoomsTable.push(floorTable);
            }
          }
        } else if (eachObj.search_type === 'poi') {
          if (floorTable === thisref.activeRoomTable) {
            const formatPOI = new WKT();
            const point = eachObj.st_astext;
            const features = formatPOI.readFeatures(point);
            let i = 0;
            features.forEach(function (feature: any) {
              i++;
              feature.set('name', eachObj.r_name);
              feature.set('details', eachObj.details);
              feature.set('icon', eachObj.icon);
              feature.set('table', eachObj.table);
              feature.set('cat', eachObj.cat);
              feature.set('type', 'POI');
              feature.setStyle(thisref.getSearchresultPoiIconStyle(eachObj.icon));

              setTimeout(() => {
                thisref.layer_search_results.getSource().addFeature(feature);
                if (!thisref.isCordovaApp) {
                  // this.poiBounceAnimation(feature);
                } // animation nur auf desktop
              }, 30 * i);
            });
          } else {
            if (!(thisref.activeRoomsTable.indexOf(floorTable) !== -1)) {
              thisref.activeRoomsTable.push(floorTable);
            }
          }
        }
      });
    }
  }

  rotateMap(angle: any) {
    const view = this.map.getView();
    const rotation = view.getRotation() + (Math.PI / angle);
    view.animate({
      rotation: rotation
    });
  }

  zoomMap(zoomType: any, zoom: number) {
    const view = this.map.getView();
    let zoomFinal: number;
    if (zoomType === 'in') {
      zoomFinal = view.getZoom() + zoom;
    } else {
      zoomFinal = view.getZoom() - zoom;
    }
    view.animate({
      zoom: zoomFinal
    });
  }

  reloadSearchSuggestions() {
    this.restService.getSearchSuggestionArray().then((data) => {
      this.search_suggestions = data;
    });
  }

  getRoomsData() {
    this.sum = 100;
    this.lazyArray = [];
    this.restService.getRoombookData(this.roombooksearchSortBy, this.roombookFilterdFloor, this.onlyEmptyRooms).then((data) => {
      this.rooms_data = data;
      this.appendItems(0, this.sum);
    }).catch((error) => {
      log.debug('rest api getRoombookData', error);
      this.toast.error('Verbindung mit der Raumdaten Schnittstelle konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  appendItems(startIndex: number, endIndex: number) {
    this.addItems(startIndex, endIndex);
  }

  prependItems(startIndex: number, endIndex: number) {
    this.removeItems(startIndex, endIndex);
  }

  addItems(startIndex: number, endIndex: number) {
    const length = this.rooms_data.length;
    for (let i = startIndex; i < endIndex; ++i) {
      if (endIndex < length || (length < this.sum && startIndex < length)) {
        this.lazyArray.push(this.rooms_data[i]);
      }
    }
    this.lazyArray = this.lazyArray.filter(function(el: any) { return el; });
  }

  removeItems(startIndex: number, endIndex: number) {
    for (let i = startIndex; i < endIndex; ++i) {
      const length = this.lazyArray.length;
      if (100 < length) {
        this.lazyArray.splice(startIndex, 1);
      }
    }
  }

  // DK-90
  // DK-108 DK-109
  show_user_room(type: any) {

    this.user_room_shown = true;

    const zimmernummer = this.user_room;

    const draw_type = type;
    this.layer_user_rooms.getSource().clear();

    // restcall zimmernummer -> poly
    this.restService.getRoomByRoomNumber(zimmernummer).then((data: any[]) => {

      if (data.length === 0 && draw_type !== 'redraw') {
        this.toast.error('Ihr Zimmer konnte nicht gefunden werden', 'Fehler', {
          positionClass: 'toast-bottom-left',
        });
      }

      if (data.length !== 0) {
        const format = new WKT();
        const Polygon = data[0].st_astext;
        const feature = format.readFeatures(Polygon, {
          featureProjection: 'EPSG:3857',
          dataProjection: 'EPSG:3857'
        });

        // bei redraw feature nur adden wenn map auf gleicher etage wie feature in db
        if (draw_type === 'redraw') {
          if (data[0].table === this.activeRoomTable) {
            this.layer_user_rooms.getSource().addFeatures(feature);
          }
        } else { // typ:button oder typ:intent
          this.layer_user_rooms.getSource().addFeatures(feature);
          const ebene = +data[0].rb_ebene; // besser als parseint
          this.switchToLayer(ebene);
        }
        // zoom und flash bei allen typen ausser typ:redraw
        if (draw_type !== 'redraw') {
          this.zoomMapToCords(data[0].st_x, data[0].st_y, 20, () => {
            this.flashpoly(Polygon);
          });
        }

        // sidebar mit raum öffnen bei typ:button
        if (type === 'button') {
          this.roomClicked = true;
          this.roomName = 'Mein Zimmer';
          this.roomNumber = zimmernummer;
          this.infowindowhidden = false;
          this.toast.success('Ihr zugeordneter Patientenraum wird nun auf der Karte angezeigt.', '', {
            positionClass: 'toast-bottom-left',
          });
        }
      }
    }, (error) => {
      log.debug('rest api getpolydatageoserver error', error);
      this.toast.error('Verbindung mit dem IndoorPlan Server konnte nicht hergestellt werden', 'Fehler', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

  /* DEV FÜR WIFI LOCATION */
  locate_me() {
    setInterval(() => this.show_user_position(), 3000);
  }

  show_user_position() {

    const thisref = this;
    let bssid: any;
    let distance: any;

    // check if wifi connected first !?

    // clear layer
    thisref.layer_user_position.getSource().clear();

    // get bssid
    this.wifiwizard2.getConnectedBSSID().then( function( result: any ) {
      console.log( 'Found BSSID', result );
      bssid = result;

      // get rssi
      thisref.wifiwizard2.scan().then( function( results: any ) {
        console.log( 'Found networks', results );
        results.forEach((network: any, index: any) => {
          if (network.BSSID === bssid) {
            distance = network.level;
          }
        });
      }).catch( function( error: any ) {
        console.log( 'Error getting results!', error );
      });

      // send bssid to backend to get position

      thisref.toast.info('result ', result);

      thisref.restService.getBSSIDPosition(result).then((data: any[]) => {

        if (data.length === 0) {
          thisref.toast.error('Ihre Position konnte nicht gefunden werden', 'Fehler', {
            positionClass: 'toast-bottom-left',
          });
        }

        console.log(data);

        // create new feature
        const positionMarker = new Feature(new Point([data[0].st_x, data[0].st_y]));

        // enable tracking mode
        thisref.tracking_mode = false;

        // set ebene
        positionMarker.set('ebene', data[0].ebene);

        // draw position
        if (positionMarker.get('ebene') === this.activeFloorNumber) {
          thisref.layer_user_position.getSource().addFeature(positionMarker);
        }

        // accuracy circle = push distance to attribute
        positionMarker.set('distance', distance);

        // lock view

        // turn with compass

      });
    }).catch( function( error: any ) {
      console.log( 'Wifi Scan Fehler!', error );
      this.toast.error( 'Scan Fehler: ' + error.message , 'Fehler', {positionClass: 'toast-bottom-left'});
    });
  }

  SwitchNavigationMode() {
    this.navigationMode = true;
    this.infowindowhidden = true;
    // floor switch
    this.switchToLayer(this.navigation_line_segment_features['start_floor']);
    const view = this.map.getView();
    view.animate({
      zoom: 21,
      duration: 2000,
      center: [this.navigation_line_segment_features['start_point'][0], this.navigation_line_segment_features['start_point'][1]]
    });
    this.startNavi(true);
    // remove poi widget
    // auto turn map
    // clear route
    // get detailled route instructions

    // show location icon layer
    // mockup: add location item to layer at position navistart
    const positionMarker = new Feature(new Point([this.NaviStart[0], this.NaviStart[1]]));
    this.layer_user_position.getSource().addFeature(positionMarker);
    // mock research : add possibility to travel along path and preview instructions
// livemode ausstellen - button - logout neuberechnen der route, etc
  }
}
