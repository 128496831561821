import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-beacon-modal',
  templateUrl: './create-beacon-modal.component.html',
  styleUrls: ['./create-beacon-modal.component.scss']
})
export class CreateBeaconModalComponent {

  beaconName: string;
  beaconBSSID1: string;
  beaconBSSID2: string;
  beaconBSSID3: string;
  beaconBSSID4: string;
  beaconTXPower: string;
  beaconInactive: boolean;

  showErrorMissingBSSID: boolean = false;
  showErrorMissingTXPower: boolean = false;
  showErrorMissingName: boolean = false;

  constructor(public mapService: MapService, public activeModal: NgbActiveModal, private toast: ToastrService) {
  }


  save() {
    let errorOccured = false;
    // validate input
    if (this.beaconName === '' || !this.beaconName) {
      this.showErrorMissingName = true;
      errorOccured = true;
    } else {
      this.showErrorMissingName = false;
    }
    // if (this.beaconBSSID1 === '' || !this.beaconBSSID1) {
    //   this.showErrorMissingBSSID = true;
    //   errorOccured = true;
    // } else {
    //   this.showErrorMissingBSSID = false;
    // }
    // if (this.beaconTXPower === '' || !this.beaconTXPower) {
    //   this.showErrorMissingTXPower = true;
    //   errorOccured = true;
    // } else {
    //   this.showErrorMissingTXPower = false;
    // }
    if (errorOccured) {
      return;
    }

    this.mapService.createBeacon(this.beaconName, this.beaconBSSID1, this.beaconBSSID2, this.beaconBSSID3, this.beaconBSSID4, this.beaconTXPower, this.beaconInactive);
    this.activeModal.dismiss();
  }


}
