/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poi-category-switch-widget-patient.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./poi-category-switch-widget-patient.component";
import * as i4 from "../../../services/map.service";
import * as i5 from "../../../services/authentication/authentication.service";
var styles_PoiCategorySwitchWidgetPatientComponent = [i0.styles];
var RenderType_PoiCategorySwitchWidgetPatientComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PoiCategorySwitchWidgetPatientComponent, data: {} });
export { RenderType_PoiCategorySwitchWidgetPatientComponent as RenderType_PoiCategorySwitchWidgetPatientComponent };
function View_PoiCategorySwitchWidgetPatientComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "poi-item-details"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mapService.poiCardClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background": 0 }), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "poi-short-detail-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "poi-short-detail-category"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (("url(" + _co.poiImageURLs[_v.context.$implicit.get("cat")]) + ")")); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.get("name"); _ck(_v, 9, 0, currVal_1); var currVal_2 = _v.context.$implicit.get("cat"); _ck(_v, 13, 0, currVal_2); }); }
function View_PoiCategorySwitchWidgetPatientComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "poi-details-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "poi-details-sub-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoiCategorySwitchWidgetPatientComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mapService.displayed_poiFeatures; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PoiCategorySwitchWidgetPatientComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(1, 0, null, null, 49, "div", [["class", "poi-container"]], null, [[null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (_co.swipe($event, "start") !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (_co.swipe($event, "end") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "poi-container-toggle text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.mapService.poi_widget_open = !_co.mapService.poi_widget_open) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "toggle-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn poi-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      DianaKlinik erkunden\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 31, "div", [["class", "poi-toggles"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 28, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(16, 0, null, null, 7, "div", [["class", "col-4 p-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "button", [["class", "btn btn-freizeit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePoiCategory("Freizeit") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "active_cat": 0 }), (_l()(), i1.ɵeld(21, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Freizeit"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(25, 0, null, null, 7, "div", [["class", "col-4 p-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(27, 0, null, null, 4, "button", [["class", "btn btn-pflege"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePoiCategory("Pflege") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(29, { "active_cat": 0 }), (_l()(), i1.ɵeld(30, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pflege"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(34, 0, null, null, 7, "div", [["class", "col-4 p-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(36, 0, null, null, 4, "button", [["class", "btn btn-therapie"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePoiCategory("Therapie") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(37, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(38, { "active_cat": 0 }), (_l()(), i1.ɵeld(39, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Therapie"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(45, 0, null, null, 4, "div", [["class", "poi-container-cards"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoiCategorySwitchWidgetPatientComponent_1)), i1.ɵdid(48, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "poi-container"; var currVal_1 = (!_co.mapService.poi_widget_open ? "poi-container-open" : "poi-container-closed"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "btn btn-freizeit"; var currVal_3 = _ck(_v, 20, 0, (_co.mapService.setting_show_poi_freizeit && _co.mapService.show_poi)); _ck(_v, 19, 0, currVal_2, currVal_3); var currVal_4 = "btn btn-pflege"; var currVal_5 = _ck(_v, 29, 0, (_co.mapService.setting_show_poi_pflege && _co.mapService.show_poi)); _ck(_v, 28, 0, currVal_4, currVal_5); var currVal_6 = "btn btn-therapie"; var currVal_7 = _ck(_v, 38, 0, (_co.mapService.setting_show_poi_therapie && _co.mapService.show_poi)); _ck(_v, 37, 0, currVal_6, currVal_7); var currVal_8 = _co.mapService.displayed_poiFeatures; _ck(_v, 48, 0, currVal_8); }, null); }
export function View_PoiCategorySwitchWidgetPatientComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "poi-category-switch-widget-patient", [], null, null, null, View_PoiCategorySwitchWidgetPatientComponent_0, RenderType_PoiCategorySwitchWidgetPatientComponent)), i1.ɵdid(1, 114688, null, 0, i3.PoiCategorySwitchWidgetPatientComponent, [i4.MapService, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PoiCategorySwitchWidgetPatientComponentNgFactory = i1.ɵccf("poi-category-switch-widget-patient", i3.PoiCategorySwitchWidgetPatientComponent, View_PoiCategorySwitchWidgetPatientComponent_Host_0, {}, {}, []);
export { PoiCategorySwitchWidgetPatientComponentNgFactory as PoiCategorySwitchWidgetPatientComponentNgFactory };
