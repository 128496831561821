<div *ngIf="!onLine()" @aliveAnim>
  <div class="alivecheck red text-center">
    <i class="fas fa-times"></i> Netzwerkverbindung verloren...
  </div>
</div>
<div *ngIf="onLine() && downlink() < minimumDownlink" @aliveAnim>
  <div class="alivecheck orange text-center">
    <i class="fas fa-times"></i> Eingeschränkte Verbindungsqualität
  </div>
</div>

<!-- Route Edit Overlay -->
<ng-container *ngIf="mapService.setting_show_route_network">
  <div class="route-edit-overlay row">
    <div class="col-12 text-center" *ngIf="mapService.setting_show_route_network"><b>LEGENDE</b></div>
    <div class="col-3">
      <img class="legende-image" src="assets/img/legende_route_public.png" />
    </div>
    <div class="col-9">
      Öffentliche Routen
    </div>

    <div class="col-3">
      <img class="legende-image" src="assets/img/legende_route_private.png" />
    </div>
    <div class="col-9">
      Private Routen
    </div>

    <div class="col-3">
      <img class="legende-image" src="assets/img/legende_route_notaccessible.png" />
    </div>
    <div class="col-9">
      Nicht barrierefreie Routen
    </div>
  </div>
</ng-container >

<div class="stationinfo-overlay row" *ngIf="!authenticationService.credentials.settingsaccess && this.mapService.stationinfo != ''">
  <div class="col-12 text-center"><h2>Auf dieser Ebene</h2></div>
  <div class="col-12 text-center">
{{ this.mapService.stationinfo }}
  </div>
</div>


<map-loading *ngIf="mapService.loading && loadingScreenActive()" @loadingAnim2 @loadingAnim1></map-loading>
<map-tutorial *ngIf="mapService.isCordovaApp && mapService.tutorialActive"></map-tutorial>
<map-header></map-header>
<map-view></map-view>
<map-floor-layer-switch></map-floor-layer-switch>

<div *ngIf="!mapService.infowindowhidden" @closeButtonAnim>
  <button class="nav-item text-uppercase btn btn-close d-sx-none" (click)="mapService.toggleInfoWindow()" routerLinkActive="active" joyrideStep="sidebarCloseButton" title="Menu schließen" text="Hier können Sie das Hauptmenü schließen.">
    <i class="fal fa-chevron-left"></i>
  </button>
  <!-- für mobil -->
  <button class="nav-item text-uppercase btn btn-close-mobile d-md-none d-lg-none d-xl-none" (click)="mapService.toggleInfoWindow()" routerLinkActive="active">
    <i class="fal fa-times"></i>
  </button>
</div>

<map-info-window class="sidebar-left" *ngIf="!mapService.infowindowhidden" @flyInOut></map-info-window>
<poi-category-switch-widget-patient *ngIf="authenticationService.credentials.poiwidgetaccess && !mapService.navigationMode"></poi-category-switch-widget-patient>
<map-roombook-window class="sidebar-scond-left" *ngIf="mapService.setting_show_roombook && authenticationService.credentials.roombookwindowaccess" @flyInOut></map-roombook-window>
<!-- <cleaning-status-window *ngIf="mapService.roomClicked && authenticationService.credentials.roomcleaninginfoaccess && !mapService.setting_show_roombook" class="cleaninginfo" ></cleaning-status-window> -->
<map-poi-context-menu></map-poi-context-menu>
<app-map-controls></app-map-controls>
<map-live-navigation *ngIf="mapService.navigationMode"></map-live-navigation >
<map-streetview *ngIf="mapService.roomNumber === '6.679' || mapService.roomNumber === '6.670.2'" [ngClass]="{'visible-3d': (mapService.roomNumber === '6.679' || mapService.roomNumber === '6.670.2')  && !mapService.infowindowhidden, 'hidden-3d': mapService.roomNumber !== '6.679' && mapService.roomNumber !== '6.670.2' }" ></map-streetview>
