<!-- POI shortview Overlay todo: export to component-->
<div class="poi-container" [ngClass]="!mapService.poi_widget_open? 'poi-container-open': 'poi-container-closed'" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
  <div class="poi-container-toggle text-center" (click)="mapService.poi_widget_open = !mapService.poi_widget_open">
    <div class="toggle-icon"></div>
    <button class="btn poi-toggle" >
      DianaKlinik erkunden
    </button>
  </div>
  <div class="poi-toggles">
    <div class="row">
      <div class="col-4 p-0 text-center">
        <button class="btn btn-freizeit" [ngClass]="{'active_cat': this.mapService.setting_show_poi_freizeit && this.mapService.show_poi}" (click)="togglePoiCategory('Freizeit')" ><p>Freizeit</p></button>
      </div>
      <div class="col-4 p-0 text-center">
        <button class="btn btn-pflege" [ngClass]="{'active_cat': this.mapService.setting_show_poi_pflege && this.mapService.show_poi}" (click)="togglePoiCategory('Pflege')"><p>Pflege</p></button>
      </div>
      <div class="col-4 p-0 text-center">
        <button class="btn btn-therapie" [ngClass]="{'active_cat': this.mapService.setting_show_poi_therapie && this.mapService.show_poi}" (click)="togglePoiCategory('Therapie')"><p>Therapie</p></button>
      </div>
    </div>
  </div>
  <div class="poi-container-cards">
    <div *ngIf="mapService.displayed_poiFeatures" class="poi-details-container">
      <div class="poi-details-sub-container">
        <ng-container *ngFor="let item of mapService.displayed_poiFeatures; let index = index">
          <div class="poi-item-details" (click)="this.mapService.poiCardClick(item)" [ngStyle]="{'background': 'url('+this.poiImageURLs[item.get('cat')] + ')' }">
            <div class="col-12">
              <span class="poi-short-detail-name">{{item.get('name')}}</span><br />
              <span class="poi-short-detail-category">{{item.get('cat')}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
