<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title"><i class="fal fa-exclamation-triangle"></i> Melden</h6>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Melden</h1>
      </div>
      <div class="col-12 text">
        Möchten Sie diese Route melden ?
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="w-50 btn greenbtn" (click)="SentryreportRoute()">
    Melden
  </button>
  <button class="w-50 btn redbtn" (click)="activeModal.dismiss();">
    Abbrechen
  </button>
</div>
