<div class="live-navi">
  <div class="row">
    <div class="col-2 text-center">
      <i class="instructionicon" [ngClass]="mapService.NaviRouteSegments[1].get('icon')"></i>
    </div>
    <div class="col-3 text-center instructiondistance">
      <span class="dist">{{mapService.NaviRouteSegments[1].get('cost').toFixed(2)}} m</span>
    </div>
    <div class="col-6 text-center instruction">
      {{mapService.NaviRouteSegments[1].get('instruction')}}
    </div>
    <div class="col-1 text-center">
      <button (click)="mapService.navigationMode = false; mapService.infowindowhidden = false; mapService.layer_user_position.getSource().clear();" class="btn instructionkill"><i class="fa fa-times"></i></button>
    </div>
  </div>
</div>
