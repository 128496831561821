<div @s_background_imageAnim *ngIf="!Mapservice.roomClicked">
  <div  class="sidebar-background" >
  </div>
</div>
<div class="container">
  <div class="card" @componentAnim>
    <div class="">
      <div class="card-body">
          <div class="row userinfos">
            <div class="col-3 userimage component">
              <img class="img-fluid user-image-shadow rounded-circle" src="assets/img/person.jpg"/>
            </div>
            <div class="col-9 userinfo ">
              <div class="username component">Max Mustermann</div>
              <div class="team component">TEAM 1</div>
            </div>
            <div class="col-12 usertasks ">
              <div class="taskcontainer">
                <div class="item row">
                  <div class="col-12 component"><h6>Meine Aufgaben</h6></div>
                  <div class="col-4 mb-1">
                    <b>Name</b>
                  </div>
                  <div class="col-3 mb-1">
                    <b>Nummer</b>
                  </div>
                  <div class="col-3 mb-1">
                    <b>Status</b>
                  </div>
                </div>
                <div class="item row " *ngFor="let task of tasks;">
                  <div class="col-4 mb-1">
                    {{task.r_name}}
                  </div>
                  <div class="col-3 mb-1">
                    {{task.r_nummer}}
                  </div>
                  <div class="col-3 mb-1">
                    {{task.rb_status}}
                  </div>
                  <div class="col-2 mb-1">
                    <button (click)="Mapservice.zoomMapToCords(task.st_x, task.st_y, 20)" class="btn btn-goto"><i class="fal fa-angle-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container revierecontainer">
          <div class="row">
            <div class="col-12">
            <div class="row">
              <div class="col-12 component"><h6>Reviere</h6></div>
              <div class="col-6 pr-1 text-center">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-primary" id="dropdown-district" ngbDropdownToggle>
                    {{this.Mapservice.dropdown_selected_district}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdown-district">
                    <button *ngFor="let district of districts;" class="dropdown-item" (click)="setDistrict(district)">
                      {{district}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-4 pr-1 text-center">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-primary" id="dropdown-status" ngbDropdownToggle>
                    {{this.Mapservice.dropdown_selected_status}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBadropdownStatussic1">
                    <button *ngFor="let status of cleaningStatus;" class="dropdown-item"
                            (click)="setCleaningStatus(status)">
                      {{status}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2 text-center">
                <button (click)="resetSelection()" [ngClass]="this.Mapservice.cleaning_welcome_selection ? 'btn-primary' : 'btn-superlight'" class="btn"><i class="fas fa-times"></i></button>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
