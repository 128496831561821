<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title"><i class="fal fa-exclamation-triangle"></i> Warnung</h6>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
         <h1>Achtung !</h1>
      </div>
      <div class="col-12 text">
        Dies betrifft <b class="danger">{{count}}</b> Räume.<br /> Wollen Sie wirklich fortfahren ?
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="w-50 btn greenbtn" (click)="confirm()">
    OK
  </button>
  <button class="w-50 btn redbtn" (click)="activeModal.dismiss();">
    Abbrechen
  </button>
</div>
