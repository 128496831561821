<div class="roomimagecontainer">
  <img class="header-image" src="assets/img/roominfo/404.jpg" alt=""/>
</div>
<div class="roominfo-text">
  <div class="title-room roomtext">{{mapService.selected_route_segments.length}} Routensegmente</div>
  <ng-container *ngIf="this.mapService.selected_route_segments.length === 1">
   <div class="title-number roomtext">
     {{mapService.selected_route_segments[0].get('id')}}
   </div>
  </ng-container>
  <ng-container *ngIf="this.mapService.selected_route_segments.length > 1">
    <div class="title-number roomtext">
    {{mapService.selected_route_segments[0].get('id')}} , {{mapService.selected_route_segments[1].get('id')}} , ...
    </div>
  </ng-container>
</div>

<!-- single segment -->
<ng-container *ngIf="mapService.selected_route_segments.length === 1">
<div class="container">
  <form >
    <div class="card">
      <div class="card-header">
        <h5><i class="fas fa-map"></i> Routen</h5>
      </div>
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Öffentlich</label>
          </div>
          <div class="col-6 mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" name="roomPublic" class="switch" id="switch-sm11" [ngModel]="mapService.selected_route_segments[0].get('public')" (ngModelChange)="route_public($event)" />
              <label class="mb-0" for="switch-sm11"></label>
            </span>
          </div>
          <div class="col-6 mb-2 item">
            <label class="col-form-label">Barrierefrei</label>
          </div>
          <div class="col-6 mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" name="roomaccessible" class="switch" id="switch-sm12" [ngModel]="mapService.selected_route_segments[0].get('accessible')" (ngModelChange)="route_accessible($event)" />
              <label class="mb-0" for="switch-sm12"></label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
</ng-container>

<!-- multiple segments -->
<ng-container *ngIf="mapService.selected_route_segments.length > 1">
  <div class="container">
    <form >
      <div class="card">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-6 mb-2 item">
              <label class="col-form-label">Öffentlich</label>
            </div>
            <div class="col-6 mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" name="roomPublic" class="switch" id="switch-sm13" [checked]="mapService.selected_route_segments[0].get('public')" (change)="route_public($event)" />
              <label class="mb-0" for="switch-sm13"></label>
            </span>
            </div>
            <div class="col-6 mb-2 item">
              <label class="col-form-label">Barrierefrei</label>
            </div>
            <div class="col-6 mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" name="roomaccessible" class="switch" id="switch-sm14" [checked]="mapService.selected_route_segments[0].get('accessible')" (change)="route_accessible($event)" />
              <label class="mb-0" for="switch-sm14"></label>
            </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
