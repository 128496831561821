import { Component, OnInit, ViewChild } from '@angular/core';
import {JoyrideService} from 'ngx-joyride';
import {MapService} from '@app/core/services/map.service';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'map-tutorial',
  templateUrl: './map-tutorial.component.html',
  styleUrls: ['./map-tutorial.component.scss'],
  providers: [NgbCarouselConfig]
})
export class MapTutorialComponent {

  @ViewChild('onboardingcarousel') carousel: NgbCarousel;

  constructor(private mapService: MapService, private readonly joyrideService: JoyrideService, config: NgbCarouselConfig) {
    config.interval = 20000;
    config.wrap = false;
    // config.keyboard = false;
    // config.pauseOnHover = false;
  }

  moveNext() {
    this.carousel.next();
  }

  cancelTour() {
    this.mapService.tutorialActive = false;
    localStorage.setItem('tutorial_done', 'true');
  }

  startTour () {
    this.mapService.tutorialActive = false;
    this.joyrideService.startTour(
      {
        themeColor: '#000000',
        steps: [
          'homeButton',
          'suchFeld@map',
          'suchButton@map',
          'recordButton@map',
          'poiCategorys@map',
          'sidebarCloseButton@map',
          'meinraumButton@map',
          'rotationButton@map',
          'zoominButton@map',
          'zoomoutButton@map',
          'layerSwitcher@map'
        ]
      }
    ).subscribe(
      (step) => {
        // je nachdem welcher step aufgerufen wird, kann man aktionen ausführen
        // wenn elemte aufgerufen werden sollen, die bei appstart mit *ngIf verdeckt sind,
        // muss möglicherweise der joyride array neu geladen werden  this.joyrideService.refresh();
        if (step.name === 'meinraumButton') {
          this.mapService.infowindowhidden = true;
        }
      }
    );
  }

}
