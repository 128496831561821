<div class="container">
  <div class="card">
    <div class="card-foto">
    </div>
    <div class="card-header">
      <h5><i class="fas fa-map"></i> Karteneinstellungen</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-9">
          <label class="col-form-label">Hintergrundkarte</label>
        </div>
        <div class="col-3 text-right">
          <div class="form-group col-form-label">
            <span class="switch switch-sm">
                  <input type="checkbox" class="switch" id="switch-sm00" [checked]="this.mapService.show_backgroundmap" (change)="this.mapService.toggleBackgroundmap()" />
                  <label class="mb-0" for="switch-sm00"></label>
            </span>
          </div>
        </div>
        <div class="col-9">
          <label class="col-form-label">Points of Interest</label>
        </div>
        <div class="col-3 text-right">
          <div class="form-group col-form-label">
            <span class="switch switch-sm">
                  <input type="checkbox" class="switch" id="switch-sm0" [checked]="this.mapService.show_poi" (change)="this.mapService.togglePoi()" />
                  <label class="mb-0" for="switch-sm0"></label>
            </span>
          </div>
        </div>
        <div class="col-9">
          <label class="col-form-label">Raumnamen</label>
        </div>
        <div class="col-3 text-right">
          <div class="form-group col-form-label">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="switch-sm1" [checked]="this.mapService.setting_show_room_names" (change)="this.mapService.toggleRoomNames()" />
              <label class="mb-0" for="switch-sm1"></label>
            </span>
          </div>
        </div>
        <div class="col-9">
          <label class="col-form-label">Wegenetz</label>
        </div>
        <div class="col-3 text-right">
          <div class="form-group col-form-label">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="switch-sm2" [checked]="this.mapService.setting_show_route_network" (change)="this.mapService.toggleRouteNetworkLayer()" />
              <label class="mb-0" for="switch-sm2"></label>
            </span>
          </div>
        </div>
        <div class="col-9">
          <label class="col-form-label">Reinigungsstatus</label>
        </div>
        <div class="col-3 text-right">
          <div class="form-group col-form-label">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="switch-sm3" [checked]="this.mapService.setting_show_cleaning_status" (change)="this.mapService.toggleRoomCleaningStatus()" />
              <label class="mb-0" for="switch-sm3"></label>
            </span>
          </div>
        </div>
        <ng-container *ngIf="authService.credentials.roombookwindowaccess">
          <div class="col-9">
            <label class="col-form-label">Raumbuch</label>
          </div>
          <div class="col-3 text-right">
            <div class="form-group col-form-label">
              <span class="switch switch-sm">
                <input type="checkbox" class="switch" id="switch-sm4" [checked]="this.mapService.setting_show_roombook" (change)="this.mapService.setting_show_roombook = !this.mapService.setting_show_roombook; this.mapService.infowindowhidden = true" />
                <label class="mb-0" for="switch-sm4"></label>
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="authService.credentials.roombookwindowaccess">
          <div class="col-9">
            <label class="col-form-label">WLAN-Accesspoints</label>
          </div>
          <div class="col-3 text-right">
            <div class="form-group col-form-label">
              <span class="switch switch-sm">
                <input type="checkbox" class="switch" id="switch-sm5" [checked]="this.mapService.show_beacons" (change)="this.mapService.toggleBeacons()"/>
                <label class="mb-0" for="switch-sm5"></label>
              </span>
            </div>
          </div>
        </ng-container>
        <!-- ENTFERNT FÜR DEMO AM MITTWOCH
<div class="col-9">
  <label class="col-form-label">Aussengelände</label>
</div>
<div class="col-3 text-right">
  <div class="form-group col-form-label">
    <span class="switch switch-sm">
      <input type="checkbox" checked class="switch" id="switch-sm2">
      <label class="mb-0" for="switch-sm2"></label>
    </span>
  </div>
</div>
-->
      </div>
    </div>
  </div>
</div>
