<div class="roomimagecontainer">
  <img class="header-image" [src]="poiImageURL" alt=""/>
</div>
<div class="roominfo-text">
  <div class="title-room roomtext">{{mapService.poiName}}</div>
  <div class="title-number roomtext">{{mapService.poiCat}}</div>
</div>

<div class="container">
  <div class="card">
    <div class="card-body p-0">
        <div *ngIf="mapService.poiClicked">
          <div class="col-12 p-0">
            <h3>Interessanter Ort</h3>
          </div>
          <div class="col-12">
            <div class="item row">
              <div class="col-6 col-label mb-1 ">
                <i class="item fal fa-hospital-alt"></i>
                <b>Name</b>
              </div>
              <div class="col-6 col-value mb-1">
                {{mapService.poiName}}
              </div>
            </div>
            <div class="item row">
              <div class="col-6 col-label mb-1">
                <i class="item fal fa-calendar-alt"></i>
                <b>Kategorie</b>
              </div>
              <div class="col-6 col-value mb-1">
                {{mapService.poiCat}}
              </div>
            </div>
            <div class="item row">
              <div class="col-12 col-label mb-1">
                <i class="fal fa-info"></i>
                <b>Beschreibung</b>
              </div>
              <div class="col-12 col-value mb-1">
                {{mapService.poiDetails}}
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
