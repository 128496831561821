import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MapService} from '@app/core/services/map.service';
import {ConfigService} from '@app/core/services/config.service';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('MapInfoWindowComponent');
import {RestService} from '@app/core/services/rest.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  data: any;
  type: any;
  count: any;
  oldcat: any;
  zoom: any;
  view: any;
  constructor(public mapService: MapService, public activeModal: NgbActiveModal, private restService: RestService) { }

  ngOnInit() {
    this.checkAmountOfChanges();
  }

  checkAmountOfChanges() {
    let postObj: any = {};
    postObj = {cat: this.oldcat};
    this.restService.checkAmountOfChanges(postObj).then((data) => {
      console.log(data);
      this.count = data[0]['count'];
    });
  }

  confirm() {
    let postObj: any = {};
    switch (this.type) {
      case 'save':
        postObj = {cat: this.data.kategorie, farbe: this.data.farbe, id: this.data.id, oldcat: this.oldcat};
        this.restService.updateCategoriesAndColors(postObj).then((data) => {
          console.log(data);
          this.mapService.infowindowhidden = true;
          this.activeModal.dismiss();
          this.reloadtiles(() => this.zoomin());
          // get new data in component , redraw map
        });
        break;
      case 'delete':
        postObj = { id: this.data.id, oldcat: this.oldcat};
        this.restService.deleteCategoriesAndColors(postObj).then((data) => {
          console.log(data);
          this.mapService.infowindowhidden = true;
          this.activeModal.dismiss();
          // get new data in component , redraw map
        });
        this.reloadtiles(() => this.zoomin());
        break;
    }
  }

  reloadtiles(cb: any) {
    this.view = this.mapService.map.getView();
    this.zoom = this.view.getZoom();
    this.view.setZoom(30);
    const cache = this.mapService.activeWMS.tileCache;
    while (cache.count_ > 0) {
      cache.pop().dispose();
    }
      setTimeout(() => {cb(); }, 100);
  }

  zoomin() {
    const view = this.mapService.map.getView();
    view.setZoom(this.zoom);
  }

}
