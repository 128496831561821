import { Component, OnInit } from '@angular/core';
import { MapService} from '@app/core/services/map.service';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('RoomBookInfoWindowComponent');

@Component({
  selector: 'room-book-info-window',
  templateUrl: './room-book-info-window.component.html',
  styleUrls: ['./room-book-info-window.component.scss'],
  animations: [
    trigger('roombookAnim', [
      transition('* => *', [
        query('.item', style({ opacity: 0, transform: 'translateX(-40px)' })),

        query('.item', stagger('50ms', [
          animate('0.2s ease-out', keyframes([
            style({opacity: 0, transform: 'translateX(-109px)',     offset: 0}),
            style({opacity: 1, transform: 'translateX(0px)',     offset: 0.5}),
            style({opacity: 1, transform: 'translateX(10px)', offset: 0.75}),
            style({opacity: 1, transform: 'translateX(0)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('text_iconAnim', [
      transition('* => *', [
        query('.fal', style({ opacity: 0, transform: 'scale(2)' })),

        query('.fal', stagger('500ms', [
          animate('0.3s ease-out', keyframes([
            style({opacity: 1, transform: 'scale(1)',     offset: 1})
          ]))
        ])),
      ])
    ])
  ]
})
export class RoomBookInfoWindowComponent implements OnInit {


  constructor(public mapService: MapService) {  }


  ngOnInit() {

  }
}
