import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('MapLoadingComponent');

@Component({
  selector: 'map-loading',
  templateUrl: './map-loading.component.html',
  styleUrls: ['./map-loading.component.scss']
})
export class MapLoadingComponent {

  constructor(public mapService: MapService) { }

}
