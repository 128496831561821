import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {I18nService} from '../../../services/i18n/i18n.service';
import {MapService} from '@app/core/services/map.service';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Logger} from '@app/core/services/logger/logger.service';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
import {NgbTypeahead, NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('MapHeaderComponent');

@Component({
  selector: 'map-header',
  templateUrl: './map-header.component.html',
  styleUrls: ['./map-header.component.scss'],
  animations: [
    trigger('home_button_anim', [
      transition('void => *', [
        query('.btn', style({opacity: 1, transform: ' scaleX(5) scaleY(5)'})),
        query('.btn', stagger('0ms', [
          animate('0.4s 0.4s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 0, transform: ' scaleX(5) scaleY(5)', offset: 0}),
            style({opacity: 1, transform: 'scaleX(1.5) scaleY(0.5)', offset: 0.5}),
            style({opacity: 1, transform: ' scaleX(1.2) scaleY(0.8)', offset: 0.7}),
            style({opacity: 1, transform: ' scaleX(1) scaleY(1)', offset: 1.0})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.btn', style({opacity: 0})),
        query('.btn', stagger('0ms', [
          animate('0.5s 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 1, transform: ' scaleX(1) scaleY(1)', offset: 0}),
            style({opacity: 1, transform: 'scaleX(1.8) scaleY(0.2)', offset: 0.5}),
            style({opacity: 1, transform: ' scaleX(1.5) scaleY(0.5)', offset: 0.7}),
            style({opacity: 0, transform: ' scaleX(5) scaleY(5)', offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('home_button_anim_sidebaropen', [
      transition('void => *', [
        query('.btn', style({opacity: 0, filter: 'blur(6px)'})),
        query('.btn', stagger('0ms', [
          animate('0.7s 0.7s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 1, filter: 'blur(0px)', offset: 1.0})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.btn', style({opacity: 1, filter: 'blur(0px)'})),
        query('.btn', stagger('0ms', [
          animate('0.7s 0.7s cubic-bezier(0.680, -0.550, 0.265, 1.550)', keyframes([
            style({opacity: 0, filter: 'blur(9px)', offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('searchbaranim', [
      transition('void => *', [
        query('.test', style({opacity: 0, width: '0px'})),
        query('.test', stagger('0ms', [
          animate('0.3s 0.3s ease-out', keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, width: '266px', offset: 1.0})
          ]))
        ])),
      ]),
      transition('* => void', [
        query('.test', style({opacity: 1, width: '266px'})),
        query('.test', stagger('0ms', [
          animate('0.3s 0.3s ease-out', keyframes([
            style({opacity: 0, width: '0px', offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('recordbuttonanim', [
      transition('void => *', [
        query('.recordbutton', style({opacity: 0, transform: 'scale(0)'}), { optional: true }),
        query('.recordbutton', stagger('0ms', [
          animate('0.2s 0.2s ease-out', keyframes([
            style({opacity: 1, transform: 'scale(1)', offset: 1.0})
          ]))
        ]), { optional: true }),
      ]),
      transition('* => void', [
        query('.recordbutton', style({opacity: 0, transform: 'scale(1)'}), { optional: true }),
        query('.recordbutton', stagger('0ms', [
          animate('0.3s 0.3s ease-out', keyframes([
            style({opacity: 1, transform: 'scale(0)', offset: 1.0})
          ]))
        ]), { optional: true }),
      ])
    ])
  ]
})
export class MapHeaderComponent implements OnInit {


  @Input()
  recognition: any;
  recording: any = false;
  searchInput: any;
  options: any;
  errorShown: any = false;
  SpeechRecognition_windows: any;
  SpeechRecognition_cordova: any;
  Speech_granted: any;
  isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
  isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  @ViewChild('searchInputInstance') instance: NgbTypeahead;

  searchTypeAhead = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.mapService.search_suggestions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private i18nService: I18nService,
              public mapService: MapService,
              ngBoostrapConfig: NgbTypeaheadConfig) {
    ngBoostrapConfig.focusFirst = false;
  }

  ngOnInit() {
    if (!this.isCordovaApp) {
      this.SpeechRecognition_windows = (<any> window).SpeechRecognition || (<any> window).webkitSpeechRecognition;
    }
    if (this.isCordovaApp) {
      (<any> window)['speechRecognition'] = {
        hasPermission: function() {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.hasPermission(function (isGranted: any) {
              resolve(isGranted);
            }, function(err: any) {
              reject(err);
            });
          });
        },
        requestPermission: function() {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.requestPermission(function () {
              resolve();
            }, function (err: any) {
              reject();
            });
          });
        },
        startRecognition: function(settings: any) {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.startListening(function(result: any) {
              resolve(result);
            }, function(err: any) {
              reject(err);
            }, settings);
          });
        },
        getSupportedLanguages: function() {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.getSupportedLanguages(function(result: any) {
              resolve(result);
            }, function(err: any) {
              reject(err);
            });
          });
        },
        isRecognitionAvailable: function() {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.isRecognitionAvailable(function(available: any) {
              resolve(available);
            }, function(err: any) {
              reject(err);
            });
          });
        },
        stopListening: function() {
          return new Promise(function(resolve: any, reject: any) {
            (<any> window).plugins.speechRecognition.stopListening(function() {
              resolve();
            }, function(err: any) {
              reject(err);
            });
          });
        }
      };
    }

    if (this.SpeechRecognition_windows ) {
      this.recognition = new this.SpeechRecognition_windows();
      this.recognition.continuous = true;
      this.recognition.lang = 'de-DE';
      const thisref = this;

      this.recognition.addEventListener('start',  function (event: any) {
        thisref.recording = true;
      });

      this.recognition.addEventListener('end', function (event: any) {
        thisref.recording = false;
      });

      this.recognition.addEventListener('result', function (event: any) {
        const current = event.resultIndex;
        const transcript = event.results[current][0].transcript;

        thisref.searchInput = transcript;
        thisref.mapService.searchInputString = transcript;
        thisref.mapService.doSearchByString(transcript);
        thisref.recognition.stop();
      });
    }
  }

  startrecording() {
    if (this.isCordovaApp) {

      const thisref = this;
      (<any> window).speechRecognition.isRecognitionAvailable().then(function(available: any) {
        if (available) {
          return (<any> window).speechRecognition.hasPermission();
        }
      }).then(function(hasPermission: any) {
        function startRecognition() {
          return (<any> window).speechRecognition.startRecognition({
            language: 'de-DE',
            showPopup: false
          }).then(function(data: any) {
            const transcript = data[0];
            thisref.searchInput = transcript;
            thisref.mapService.searchInputString = transcript;
            thisref.mapService.doSearchByString(transcript);
            thisref.recording = false;
          }).catch(function(err: any) {
            alert( 'Wir konnten Sie nicht verstehen. Bitte Versuchen Sie es erneut');
          });
        }
        if (!hasPermission) {
          (<any> window).speechRecognition.requestPermission().then(function() {
            thisref.recording = true;
            startRecognition();
          }).catch(function(err: any) {
            alert( 'Sie müssen den Zugriff auf das Mikrofon erlauben, damit die Sprachsuche funktioniert !');
          });
        } else {
          thisref.recording = true;
          startRecognition();
        }
      }).catch(function(err: any) {
        console.error(err);
      });
    }
    if (!this.isCordovaApp) {
      this.recognition.start();
    }
  }

  home() {
    this.mapService.search_results_hidden = true;
    this.mapService.roomClicked = false;
    this.mapService.layer_selected_room.getSource().clear();
    this.mapService.poiClicked = false;
    this.mapService.infowindowhidden = true;
    this.mapService.searchbaractive = false;
    this.toggleSidebar();
  }

  toggleSidebar() {
    this.mapService.infowindowhidden = !this.mapService.infowindowhidden;
  }

  toggleSearchbar() {
    this.mapService.searchbaractive = !this.mapService.searchbaractive;
  }

  search(event: any) {

    event.preventDefault();
    this.errorShown = false;
    // bug - wenn kein input, aber button wird gedrückt - console error
    if (this.searchInput) {
      // typeahead dropdown schließen
      this.instance.dismissPopup();
      this.searchInput = this.searchInput.toString();
      // validation: es darft nicht nach strings mit einer länge von 1 gesucht werden , oder strings die nur aus leerzeichen oder zeilenumbrüchen bestehen
      if (this.searchInput.length > 1 && this.searchInput.replace(/\s/g, '').length ) {
        this.mapService.searchInputString = this.searchInput;
        this.mapService.doSearchByString(this.searchInput);
        if (event.item) {
          this.searchInput = event.item;
        }
      } else {
        this.errorShown = true;
      }
    }
  }

  backToSearchResults() {
    this.mapService.layer_selected_room.getSource().clear();
    this.mapService.layer_search_results.setVisible(true);
    this.mapService.roomClicked = false;
    this.mapService.poiClicked = false;
    this.mapService.activeRoomNextFloorTable = '';
    this.mapService.search_results_hidden = false;
    this.mapService.infowindowhidden = false;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authenticationService.logout()
       .subscribe(() => this.router.navigate(['/#/login/'], {replaceUrl: true}));
    // reset vars
    this.mapService.roomClicked = false;
    this.mapService.poiClicked = false;
    this.mapService.NaviStart = undefined;
    this.mapService.NaviEnd = undefined;
    this.mapService.setting_show_room_names = false;
    this.mapService.setting_show_room_names = false;
    this.mapService.show_poi = false;
    this.mapService.setting_show_poi_freizeit = false;
    this.mapService.setting_show_poi_pflege = false;
    this.mapService.setting_show_poi_therapie = false;
    this.mapService.setting_show_poi_eingaenge_ausgaenge = false;
    this.mapService.setting_show_poi_treppen_aufzuege = false;
    this.mapService.setting_show_route_network = false;
    this.mapService.setting_show_cleaning_status = false;
    this.mapService.displayed_poiFeatures = [];
    this.mapService.search_result_data = [];
    this.mapService.searchInputString = undefined;
    this.mapService.search_results_hidden = true;
    this.mapService.activeRoomFloors = [];
    this.mapService.navigationMode = false;
    this.mapService.routeSegmentClicked = false;

  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }

  clearSearch() {
    this.searchInput = null;
    this.mapService.searchSaved = false;
    this.mapService.search_results_hidden = true;
    this.mapService.layer_search_results.getSource().clear();
    this.mapService.activeRoomsTable = [];
    this.errorShown = false;
    this.mapService.search_result_data = [];
  }
}
