import {Component, OnInit} from '@angular/core';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
const log = new Logger('PoiCategorySwitchWidgetComponent');

@Component({
  selector: 'poi-widget',
  templateUrl: './poi-category-switch-widget.component.html',
  styleUrls: ['./poi-category-switch-widget.component.scss'],
  animations: [
    trigger('explainerAnim', [
      transition('void => *', [
        query('.col-4', style({ opacity: 1, transform: 'translateX(-500px)' })),
        query('.col-4', stagger('-100ms', [
          animate('0.2s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
        ]))
      ])
    ])
  ]
})
export class PoiCategorySwitchWidgetComponent implements OnInit {

  admin_role: any;

  constructor(public mapService: MapService,
              private authService: AuthenticationService) { }

  ngOnInit() {
    if (this.authService.credentials) {
      if (this.authService.credentials['userrole'] === 'patient') {
        this.mapService.show_poi = true;
        this.mapService.setting_show_poi_freizeit = true;
        this.mapService.setting_show_poi_pflege = true;
        this.mapService.setting_show_poi_therapie = true;
        this.mapService.setting_show_poi_eingaenge_ausgaenge = true;
        this.mapService.setting_show_poi_treppen_aufzuege = true;
      }
      if (this.authService.credentials['userrole'] === 'admin') {
        this.admin_role = true;
      }
    }
  }

  togglePoiCategory(category: string) {
    this.mapService.togglePoiCategory(category);
  }

}
