import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MapService} from '@app/core/services/map.service';
import {ConfigService} from '@app/core/services/config.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {ToastrService} from 'ngx-toastr';
const log = new Logger('MapInfoWindowComponent');

@Component({
  selector: 'app-create-poi-modal',
  templateUrl: './create-poi-modal.component.html',
  styleUrls: ['./create-poi-modal.component.scss']
})
export class CreatePoiModalComponent {

  poiName: string;
  poiDescription: string;
  poiCategory = 'Kategorie auswählen';
  poiSelectedIconName: string;

  showErrorMissingName: boolean = false;
  showErrorMissingCategory: boolean = false;
  showErrorMissingIcon: boolean = false;

  constructor(public mapService: MapService, public activeModal: NgbActiveModal, private toast: ToastrService) { }

  setCategory(category: string) {
    this.poiCategory = category;
  }

  selectIcon(name: string) {
    this.poiSelectedIconName = name;
  }

  save() {
    let errorOccured = false;
    // validate input
    if (this.poiCategory === 'Kategorie auswählen') {
      this.showErrorMissingCategory = true;
      errorOccured = true;
    } else {
      this.showErrorMissingCategory = false;
    }
    if (this.poiName === '' || !this.poiName) {
      this.showErrorMissingName = true;
      errorOccured = true;
    } else {
      this.showErrorMissingName = false;
    }
    if (this.poiSelectedIconName === '' || !this.poiSelectedIconName) {
      this.showErrorMissingIcon = true;
      errorOccured = true;
    } else {
      this.showErrorMissingIcon = false;
    }
    if (errorOccured) {
      return;
    }
    log.debug('saving new poi');
    log.debug('poi name', this.poiName);
    log.debug('poi description', this.poiDescription);
    log.debug('poi icon', this.poiSelectedIconName);
    log.debug('poi category_value', this.poiCategory);
    this.mapService.createPOI(this.poiName, this.poiDescription, this.poiSelectedIconName, this.poiCategory);
    this.activeModal.dismiss();
  }


}
