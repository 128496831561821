import { Component, OnInit } from '@angular/core';
import { MapService} from '@app/core/services/map.service';
import {animate, query, stagger, style, transition, trigger, keyframes} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('WelcomeWidgetComponent');

@Component({
  selector: 'welcome-widget-admin',
  templateUrl: './welcome-widget-admin.component.html',
  styleUrls: ['./welcome-widget-admin.component.scss'],
  animations: [
    trigger('componentAnim', [
      transition('* => *', [
        query('.component', style({ opacity: 0, transform: 'translateY(-40px)' })),

        query('.component', stagger('300ms', [
          animate('400ms 0.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ])),


      ])
    ]),
    trigger('s_background_imageAnim', [
      transition('* => *', [
        query('.sidebar-background', style({ opacity: 0, filter: 'blur(5px)'})),

        query('.sidebar-background', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, filter: 'blur(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('s_background_textAnim', [
      transition('* => *', [
        query('.logotext', style({ opacity: 0, transform: 'translateX(-500px)' })),

        query('.logotext', stagger('300ms', [
          animate('0.4s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(20px)',  offset: 0.5}),
            style({opacity: 1, transform: 'translateX(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ])
  ]
})
export class WelcomeWidgetAdminComponent implements OnInit {

  constructor(public mapService: MapService) {  }

  ngOnInit() {

  }

}
