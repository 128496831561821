<div [ngClass]="!mapService.infowindowhidden? 'margin-active': 'margin-not-active'">
  <div class="sidebar-second-container px-3 pt-3 pb-0">
    <div class="mb-2">
      <div class="d-flex justify-content-between">
      <div><h5>Raumbuch</h5></div>
        <div><button class="btn btn-light" (click)="closeRoombook()"><i class="fas fa-times"></i></button></div>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <div ngbDropdown class="d-inline-block">
            <button [ngClass]="search_filter_active ? 'btn-primary' : 'btn-superlight'" class="btn" id="dropdownbasic1"
                    ngbDropdownToggle><i class="fal fa-filter"></i>
              Filter
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="custom-dropdown-item">
                <span class="switch switch-sm">
                  <input type="checkbox" class="switch" (click)="setFilterOnlyCurrentFloor()"
                         [checked]="filter_only_current_floor" id="switch-filter-only-current-floor"/>
                  <label class="mb-0" for="switch-filter-only-current-floor">nur aktuelle Etage</label>
                </span>
              </button>
            </div>
          </div>
          <button *ngIf="mapService.roombooksearchSortBy === 'asc'" (click)="setSearchSorting('desc')" class="btn btn-superlight"><i
            class="fal fa-sort-amount-up"></i></button>
          <button *ngIf="mapService.roombooksearchSortBy === 'desc'" (click)="setSearchSorting('asc')" class="btn btn-superlight"><i
            class="fal fa-sort-amount-down"></i></button>
          <button [ngClass]="mapService.onlyEmptyRooms ? 'btn-primary' : 'btn-superlight'" (click)="toggleRoomswithoutData()" class="btn"><i class="fal fa-times-circle"></i>&nbsp;&nbsp;Räume ohne Daten </button>
          <!--<button (click)="toggleSearchSorting()" class="btn btn-light"><i [ngClass]="mapService.searchSortBy === 'asc' ? 'fa-sort-amount-up' : 'fa-sort-amount-down'" class="fal"></i></button>-->
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-superlight" (click)="resetFilter()">
            <i class="far fa-trash-alt"></i>
            zurücksetzen
          </button>
        </div>
      </div>
    </div>
    <div class="row no-gutters mx-0">
      <div class="col-2 custom-th">
        Name
      </div>
      <div class="col-1 custom-th">
        Nummer
      </div>
      <div class="col-1 custom-th">
        Ebene
      </div>
      <div class="col-1 custom-th">
        Belag
      </div>
      <div class="col-1 custom-th">
        Grösse
      </div>
      <div class="col-1 custom-th">
        Revier
      </div>
      <div class="col-1 custom-th">
        Turnus
      </div>
      <div class="col-1 custom-th">
        Status
      </div>
      <div class="col-2 custom-th">
        Kategorie
      </div>
      <div class="col-1 custom-th">
        Sichtbarkeit
      </div>
    </div>
    <div infinite-scroll
         [infiniteScrollDistance]="mapService.scrollDistance"
         [infiniteScrollUpDistance]="mapService.scrollUpDistance"
         [infiniteScrollThrottle]="mapService.throttle" (scrolled)="onScrollDown($event)" (scrolledUp)="onScrollUp($event)" [scrollWindow]="false" class="scroll-container">
      <ng-container *ngFor="let item of mapService.lazyArray; let index = index; let isEven = even;">
        <div [class.striped]="isEven" [class.active]="mapService.clicked_row === index" class="row roombook-row mx-0" (click)="inputEditToggle(index)">
          <ng-container *ngIf="inputClicked === index">
          <div class="col-2 align-self-center custom-td">
            <input (change)="setInputData($event)" name="r_name" type="text" class="form-control" [(ngModel)]="mapService.clickedResult.r_name" value="{{mapService.clickedResult.r_name}}" />
          </div>
          <div class="col-1 align-self-center custom-td">
            <input (change)="setInputData($event)" name="r_nummer" type="text" class="form-control" [(ngModel)]="mapService.clickedResult.r_nummer" value="{{mapService.clickedResult.r_nummer}}" />
          </div>
          <div class="col-1 align-self-center custom-td">
            <input (change)="setInputData($event)" [attr.disabled]="'disabled'" name="rb_ebene" type="text" class="form-control input-disabled" [(ngModel)]="mapService.clickedResult.rb_ebene" value="{{mapService.clickedResult.rb_ebene}}" />
          </div>
          <div class="col-1 align-self-center custom-td">
            <input (change)="setInputData($event)" name="rb_belag" type="text" class="form-control" [(ngModel)]="mapService.clickedResult.rb_belag" value="{{mapService.clickedResult.rb_belag}}" />
          </div>
          <div class="col-1 align-self-center custom-td">
            <input (change)="setInputData($event)" name="rb_groesse" type="text" class="form-control" [(ngModel)]="mapService.clickedResult.rb_groesse" value="{{mapService.clickedResult.rb_groesse}}" />
          </div>
          <div class="col-1 align-self-center custom-td">
            <div ngbDropdown ngbDropdownReposition class="dropdown-custom">
              <button class="btn btn-dropdown" id="dropdown-district" ngbDropdownToggle>{{mapService.clickedResult.rb_revier  ? mapService.clickedResult.rb_revier  : 'kein Revier'}}</button>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-district">
                <button *ngFor="let district of districts" (click)="setCleaningDistrict(district)" class="dropdown-item" >{{district}}</button>
              </div>
            </div>
          </div>
          <div class="col-1 align-self-center custom-td">
            <div ngbDropdown ngbDropdownReposition class="dropdown-custom">
              <button class="btn btn-dropdown" id="dropdown-turnus" ngbDropdownToggle>{{mapService.clickedResult.rb_turnus  ? mapService.clickedResult.rb_turnus  : 'keine Reinigung'}}</button>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-status">
                <button *ngFor="let intervals of cleaningintervals" (click)="setCleaningInterval(intervals)" class="dropdown-item" >{{intervals}}</button>
              </div>
            </div>
          </div>
          <!--<div class="col-1 align-self-center custom-td"><i *ngIf="item.rb_status === 'gereinigt'" class="fas fa-check text-success"></i><i *ngIf="item.rb_status === 'unrein'" class="fas fa-times text-danger"></i><i *ngIf="item.rb_status === 'meangel'" class="fas fa-exclamation-triangle text-warning"></i> {{item.rb_status}}</div>-->
          <div class="col-1 align-self-center custom-td">
            <div ngbDropdown ngbDropdownReposition class="dropdown-custom">
              <button class="btn btn-dropdown" id="dropdown-status" ngbDropdownToggle>{{mapService.clickedResult.rb_status  ? mapService.clickedResult.rb_status  : 'kein Status'}}</button>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-status">
                <button (click)="setCleaningStatus('gereinigt')" class="dropdown-item" >gereinigt</button>
                <button (click)="setCleaningStatus('meangel')" class="dropdown-item" >mängel</button>
                <button (click)="setCleaningStatus('ungereinigt')" class="dropdown-item" >ungereinigt</button>
              </div>
            </div>
          </div>
          <div class="col-2 align-self-center custom-td">
            <div ngbDropdown ngbDropdownReposition class="dropdown-custom">
              <button class="btn btn-dropdown" id="dropdown-cat" ngbDropdownToggle>{{mapService.clickedResult.r_cat  ? mapService.clickedResult.r_cat  : 'keine Kategorie'}}</button>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-cat">
                <button (click)="setCategory('Keine Kategorie')" class="dropdown-item" >Keine Kategorie</button>
                <button (click)="setCategory('Patientenzimmer')" class="dropdown-item" >Patientenzimmer</button>
                <button (click)="setCategory('Diagnostik')" class="dropdown-item" >Diagnostik</button>
                <button (click)="setCategory('Pflegestützpunkt/Büro')" class="dropdown-item" >Pflegestützpunkt/Büro</button>
                <button (click)="setCategory('Arztzimmer')" class="dropdown-item" >Arztzimmer</button>
                <button (click)="setCategory('Gruppenraum')" class="dropdown-item" >Gruppenraum</button>
                <button (click)="setCategory('Therapie')" class="dropdown-item" >Therapie</button>
                <button (click)="setCategory('Lager')" class="dropdown-item" >Lager</button>
              </div>
            </div>
          </div>
          <div class="col-1 align-self-center custom-td">
            <div class="form-group col-form-label text-center">
              <span class="switch switch-sm">
                <input type="checkbox" class="switch" id="switch-public" [checked]="mapService.clickedResult.r_public" (change)="setVisibility()" />
                <label class="mb-0" for="switch-public"></label>
              </span>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="inputClicked !== index">
            <div class="col-2 align-self-center custom-td">
              <div class="custom-td-text">
                {{item.r_name ? item.r_name : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.r_nummer ? item.r_nummer : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_ebene}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_belag ? item.rb_belag : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_groesse ? item.rb_groesse : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_revier ? item.rb_revier : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_turnus ? item.rb_turnus: '-'}}
              </div>
            </div>
            <!--<div class="col-1 align-self-center custom-td"><i *ngIf="item.rb_status === 'gereinigt'" class="fas fa-check text-success"></i><i *ngIf="item.rb_status === 'unrein'" class="fas fa-times text-danger"></i><i *ngIf="item.rb_status === 'meangel'" class="fas fa-exclamation-triangle text-warning"></i> {{item.rb_status}}</div>-->
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.rb_status  ? item.rb_status  : 'kein Status'}}
              </div>
            </div>
            <div class="col-2 align-self-center custom-td">
              <div class="custom-td-text">
              {{item.r_cat  ? item.r_cat  : '-'}}
              </div>
            </div>
            <div class="col-1 align-self-center custom-td">
              <div class="custom-td-text">
                <div class="form-group col-form-label text-center">
              <span class="switch switch-sm">
                <input type="checkbox" [attr.id]="'switch-public-' + index" class="switch" [checked]="item.r_public"/>
                <label class="mb-0" [attr.for]="'switch-public-' + index"></label>
              </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
