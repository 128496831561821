import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { MapService } from '@app/core/services/map.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Logger } from '@app/core/services/logger/logger.service';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
var log = new Logger('MapHeaderComponent');
var MapHeaderComponent = /** @class */ (function () {
    function MapHeaderComponent(router, authenticationService, i18nService, mapService, ngBoostrapConfig) {
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.i18nService = i18nService;
        this.mapService = mapService;
        this.recording = false;
        this.errorShown = false;
        this.isCordovaApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
        this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        this.searchTypeAhead = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) { return term.length < 2 ? []
                : _this.mapService.search_suggestions.filter(function (v) { return v.toLowerCase().indexOf(term.toLowerCase()) > -1; }).slice(0, 10); }));
        };
        ngBoostrapConfig.focusFirst = false;
    }
    MapHeaderComponent.prototype.ngOnInit = function () {
        if (!this.isCordovaApp) {
            this.SpeechRecognition_windows = window.SpeechRecognition || window.webkitSpeechRecognition;
        }
        if (this.isCordovaApp) {
            window['speechRecognition'] = {
                hasPermission: function () {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.hasPermission(function (isGranted) {
                            resolve(isGranted);
                        }, function (err) {
                            reject(err);
                        });
                    });
                },
                requestPermission: function () {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.requestPermission(function () {
                            resolve();
                        }, function (err) {
                            reject();
                        });
                    });
                },
                startRecognition: function (settings) {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.startListening(function (result) {
                            resolve(result);
                        }, function (err) {
                            reject(err);
                        }, settings);
                    });
                },
                getSupportedLanguages: function () {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.getSupportedLanguages(function (result) {
                            resolve(result);
                        }, function (err) {
                            reject(err);
                        });
                    });
                },
                isRecognitionAvailable: function () {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.isRecognitionAvailable(function (available) {
                            resolve(available);
                        }, function (err) {
                            reject(err);
                        });
                    });
                },
                stopListening: function () {
                    return new Promise(function (resolve, reject) {
                        window.plugins.speechRecognition.stopListening(function () {
                            resolve();
                        }, function (err) {
                            reject(err);
                        });
                    });
                }
            };
        }
        if (this.SpeechRecognition_windows) {
            this.recognition = new this.SpeechRecognition_windows();
            this.recognition.continuous = true;
            this.recognition.lang = 'de-DE';
            var thisref_1 = this;
            this.recognition.addEventListener('start', function (event) {
                thisref_1.recording = true;
            });
            this.recognition.addEventListener('end', function (event) {
                thisref_1.recording = false;
            });
            this.recognition.addEventListener('result', function (event) {
                var current = event.resultIndex;
                var transcript = event.results[current][0].transcript;
                thisref_1.searchInput = transcript;
                thisref_1.mapService.searchInputString = transcript;
                thisref_1.mapService.doSearchByString(transcript);
                thisref_1.recognition.stop();
            });
        }
    };
    MapHeaderComponent.prototype.startrecording = function () {
        if (this.isCordovaApp) {
            var thisref_2 = this;
            window.speechRecognition.isRecognitionAvailable().then(function (available) {
                if (available) {
                    return window.speechRecognition.hasPermission();
                }
            }).then(function (hasPermission) {
                function startRecognition() {
                    return window.speechRecognition.startRecognition({
                        language: 'de-DE',
                        showPopup: false
                    }).then(function (data) {
                        var transcript = data[0];
                        thisref_2.searchInput = transcript;
                        thisref_2.mapService.searchInputString = transcript;
                        thisref_2.mapService.doSearchByString(transcript);
                        thisref_2.recording = false;
                    }).catch(function (err) {
                        alert('Wir konnten Sie nicht verstehen. Bitte Versuchen Sie es erneut');
                    });
                }
                if (!hasPermission) {
                    window.speechRecognition.requestPermission().then(function () {
                        thisref_2.recording = true;
                        startRecognition();
                    }).catch(function (err) {
                        alert('Sie müssen den Zugriff auf das Mikrofon erlauben, damit die Sprachsuche funktioniert !');
                    });
                }
                else {
                    thisref_2.recording = true;
                    startRecognition();
                }
            }).catch(function (err) {
                console.error(err);
            });
        }
        if (!this.isCordovaApp) {
            this.recognition.start();
        }
    };
    MapHeaderComponent.prototype.home = function () {
        this.mapService.search_results_hidden = true;
        this.mapService.roomClicked = false;
        this.mapService.layer_selected_room.getSource().clear();
        this.mapService.poiClicked = false;
        this.mapService.infowindowhidden = true;
        this.mapService.searchbaractive = false;
        this.toggleSidebar();
    };
    MapHeaderComponent.prototype.toggleSidebar = function () {
        this.mapService.infowindowhidden = !this.mapService.infowindowhidden;
    };
    MapHeaderComponent.prototype.toggleSearchbar = function () {
        this.mapService.searchbaractive = !this.mapService.searchbaractive;
    };
    MapHeaderComponent.prototype.search = function (event) {
        event.preventDefault();
        this.errorShown = false;
        // bug - wenn kein input, aber button wird gedrückt - console error
        if (this.searchInput) {
            // typeahead dropdown schließen
            this.instance.dismissPopup();
            this.searchInput = this.searchInput.toString();
            // validation: es darft nicht nach strings mit einer länge von 1 gesucht werden , oder strings die nur aus leerzeichen oder zeilenumbrüchen bestehen
            if (this.searchInput.length > 1 && this.searchInput.replace(/\s/g, '').length) {
                this.mapService.searchInputString = this.searchInput;
                this.mapService.doSearchByString(this.searchInput);
                if (event.item) {
                    this.searchInput = event.item;
                }
            }
            else {
                this.errorShown = true;
            }
        }
    };
    MapHeaderComponent.prototype.backToSearchResults = function () {
        this.mapService.layer_selected_room.getSource().clear();
        this.mapService.layer_search_results.setVisible(true);
        this.mapService.roomClicked = false;
        this.mapService.poiClicked = false;
        this.mapService.activeRoomNextFloorTable = '';
        this.mapService.search_results_hidden = false;
        this.mapService.infowindowhidden = false;
    };
    MapHeaderComponent.prototype.setLanguage = function (language) {
        this.i18nService.language = language;
    };
    MapHeaderComponent.prototype.logout = function () {
        var _this = this;
        this.authenticationService.logout()
            .subscribe(function () { return _this.router.navigate(['/#/login/'], { replaceUrl: true }); });
        // reset vars
        this.mapService.roomClicked = false;
        this.mapService.poiClicked = false;
        this.mapService.NaviStart = undefined;
        this.mapService.NaviEnd = undefined;
        this.mapService.setting_show_room_names = false;
        this.mapService.setting_show_room_names = false;
        this.mapService.show_poi = false;
        this.mapService.setting_show_poi_freizeit = false;
        this.mapService.setting_show_poi_pflege = false;
        this.mapService.setting_show_poi_therapie = false;
        this.mapService.setting_show_poi_eingaenge_ausgaenge = false;
        this.mapService.setting_show_poi_treppen_aufzuege = false;
        this.mapService.setting_show_route_network = false;
        this.mapService.setting_show_cleaning_status = false;
        this.mapService.displayed_poiFeatures = [];
        this.mapService.search_result_data = [];
        this.mapService.searchInputString = undefined;
        this.mapService.search_results_hidden = true;
        this.mapService.activeRoomFloors = [];
        this.mapService.navigationMode = false;
        this.mapService.routeSegmentClicked = false;
    };
    Object.defineProperty(MapHeaderComponent.prototype, "currentLanguage", {
        get: function () {
            return this.i18nService.language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapHeaderComponent.prototype, "languages", {
        get: function () {
            return this.i18nService.supportedLanguages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapHeaderComponent.prototype, "username", {
        get: function () {
            var credentials = this.authenticationService.credentials;
            return credentials ? credentials.username : null;
        },
        enumerable: true,
        configurable: true
    });
    MapHeaderComponent.prototype.clearSearch = function () {
        this.searchInput = null;
        this.mapService.searchSaved = false;
        this.mapService.search_results_hidden = true;
        this.mapService.layer_search_results.getSource().clear();
        this.mapService.activeRoomsTable = [];
        this.errorShown = false;
        this.mapService.search_result_data = [];
    };
    return MapHeaderComponent;
}());
export { MapHeaderComponent };
