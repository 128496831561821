import { Component, OnInit } from '@angular/core';
import {RestService} from '@app/core/services/rest.service';
import {ConfirmModalComponent} from '@app/core/pages/map/modals/confirm-modal/confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'settings-info-window-admin',
  templateUrl: './settings-info-window-admin.component.html',
  styleUrls: ['./settings-info-window-admin.component.scss']
})
export class SettingsInfoWindowAdminComponent implements OnInit {

  katdata: any;
  ConfirmModalComponentReference: any;

  constructor(private restService: RestService, private modalService: NgbModal) {}

  ngOnInit() {
    this.getCatandColor();
  }

  getCatandColor() {
    this.restService.getCategoriesAndColors().then((data) => {
    console.log(data);
    this.katdata = data;
    console.log(this.katdata);
    });
  }

  addCat() {
    this.katdata.push( {1: 'new', cat: '', farbe: '#1183d3', id: 'new'} );
  }

  openConfirmModal(action: string, data: any, index: any) {
    this.ConfirmModalComponentReference = this.modalService.open(ConfirmModalComponent);
    const instance = this.ConfirmModalComponentReference.componentInstance;
    instance.data = data;
    instance.type = action;
    instance.oldcat = data[1];
  }
}
