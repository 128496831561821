import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'; // laut doc ok. muss aus examples geladen werden
import * as FBXLoader from 'three-fbxloader-offical';
import * as TWEEN from '@tweenjs/tween.js';

@Component({
  selector: 'map-streetview',
  templateUrl: './map-streetview.component.html',
  styleUrls: ['./map-streetview.component.scss']
})
export class MapStreetviewComponent implements OnInit {

  camera: any
  scene: any;
  renderer: any;
  isUserInteracting = false;
  onMouseDownMouseX: any = 0;
  onMouseDownMouseY: any = 0;
  lon: any = 0;
  onMouseDownLon: any = 0;
  lat: any = 0;
  onMouseDownLat: any = 0;
  phi: any = 0;
  theta: any = 0;
  mesh;
  container;
  animateCallback: any;
  controls: any;



  constructor(public mapService: MapService) { }

  ngOnInit() {
    this.init();
    this.animateCallback = {
      callAnimate: (this.animate).bind(this)
    };
    this.animateCallback.callAnimate();
  }

  init() {



    this.container = document.getElementById( 'streetview' );

    this.camera = new THREE.PerspectiveCamera( 75, ((window.innerWidth / 100) * 30) / ((window.innerHeight / 100) * 40), 1, 20100 );
    this.camera.target = new THREE.Vector3( 0, 0, 0 );

    this.scene = new THREE.Scene();

    const geometry = new THREE.SphereBufferGeometry( 700, 60, 40 );
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale( - 1, 1, 1 );

    const texture = new THREE.TextureLoader().load( this.mapService.RoomTexture );
    const material = new THREE.MeshBasicMaterial( { map: texture } );

    this.mesh = new THREE.Mesh( geometry, material );

    this.scene.add( this.mesh );

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( (window.innerWidth / 100) * 30 , (window.innerHeight / 100) * 40 );
    this.container.appendChild( this.renderer.domElement );

    this.controls = new OrbitControls( this.camera, this.renderer.domElement);
    this.controls.target.set( 1, 1, 1 );
    this.controls.update();

    document.addEventListener( 'mousedown', this.onPointerStart, false );
    document.addEventListener( 'mousemove', this.onPointerMove, false );
    document.addEventListener( 'mouseup', this.onPointerUp, false );

    document.addEventListener( 'wheel', this.onDocumentMouseWheel, false );

    document.addEventListener( 'touchstart', this.onPointerStart, false );
    document.addEventListener( 'touchmove', this.onPointerMove, false );
    document.addEventListener( 'touchend', this.onPointerUp, false );

    //

    document.addEventListener( 'dragover', function ( event ) {

      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';

    }, false );

    document.addEventListener( 'dragenter', function () {

      document.body.style.opacity = '0.5';

    }, false );

    document.addEventListener( 'dragleave', function () {

      document.body.style.opacity = '1';

    }, false );
    console.log('test');

    //

    //window.addEventListener( 'resize', onWindowResize, false );

  }
  /*
  function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

  }
*/
  onPointerStart( event ) {

    this.isUserInteracting = true;

    var clientX = event.clientX || event.touches[ 0 ].clientX;
    var clientY = event.clientY || event.touches[ 0 ].clientY;

    this.onMouseDownMouseX = clientX;
    this.onMouseDownMouseY = clientY;

    this.onMouseDownLon = this.lon;
    this.onMouseDownLat = this.lat;

  }

  onPointerMove( event ) {

    if ( this.isUserInteracting === true ) {

      var clientX = event.clientX || event.touches[ 0 ].clientX;
      var clientY = event.clientY || event.touches[ 0 ].clientY;

      this.lon = ( this.onMouseDownMouseX - clientX ) * 0.1 + this.onMouseDownLon;
      this.lat = ( clientY - this.onMouseDownMouseY ) * 0.1 + this.onMouseDownLat;

    }

  }

  onPointerUp() {

    this.isUserInteracting = false;

  }

  onDocumentMouseWheel( event ) {

    // var fov = this.camera.fov + event.deltaY * 0.05;

    //camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );

    //this.camera.updateProjectionMatrix();

  }


  protected animate() {


    requestAnimationFrame( this.animateCallback.callAnimate );
    this.renderer.render( this.scene, this.camera );
    // console.log(this.mapService.threeControls.position.x);
    this.controls.update();
    //this.update();
  }

  update() {

    if ( this.isUserInteracting === false ) {

      this.lon += 0.1;

    }

    this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
    // this.phi = THREE.MathUtils.degToRad( 90 - this.lat );
    // this.theta = THREE.MathUtils.degToRad( this.lon );

    this.camera.target.x = 500 * Math.sin( this.phi ) * Math.cos( this.theta );
    this.camera.target.y = 500 * Math.cos( this.phi );
    this.camera.target.z = 500 * Math.sin( this.phi ) * Math.sin( this.theta );

    this.camera.lookAt( this.camera.target );

    /*
            // distortion
            camera.position.copy( camera.target ).negate();
            */

    this.renderer.render( this.scene, this.camera );

  }

}
