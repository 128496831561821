<div class="roomimagecontainer"  @s_background_imageAnim>
  <div class="fadeout"></div>
  <img class="header-image" [src]="roomImageURL" alt="" />
</div>
  <div class="roominfo-text" @s_background_textAnim>
    <div class="title-room roomtext">{{mapService.roomName}}</div>
    <div class="title-number roomtext">{{mapService.roomNumber}}</div>
</div>




