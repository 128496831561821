<header>
  <nav class="navbar navbar-expand-lg bg-transparent">
    <button class="navbar-toggler" type="button" aria-controls="navbar-menu"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="navbar-collapse float-xs-none">
      <div class="navbar-nav" >
        <div *ngIf="!mapService.infowindowhidden"  @home_button_anim_sidebaropen>
          <button (click)="home()"  class="btn activebtn btn-toggle-sidebar" joyrideStep="homeButton" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton" title="Hauptmenü" text="Tippen Sie hier, um ins Hauptmenü zu gelangen."><i class="fal fa-home"></i></button>
        </div>
        <div *ngIf="mapService.infowindowhidden" @home_button_anim>
          <button (click)="home()" class="btn activebtn btn-toggle-sidebar-hidden"><i class="fal fa-home"></i></button>
        </div>
        <div *ngIf="!mapService.setting_show_roombook" class="d-flex" >
          <button  *ngIf="!mapService.searchbaractive && mapService.infowindowhidden" (click)="toggleSearchbar()" class="btn btn-toggle-sidebar-hidden-search"><i class="fal fa-search"></i></button>
          <button *ngIf="!mapService.infowindowhidden || !mapService.searchSaved && mapService.searchbaractive" (click)="search($event)" class="btn btn-toggle-sidebar-hidden-search" joyrideStep="suchButton" title="Suche bestätigen" text="Tippen Sie anschließen hier oder bestätigen Sie mit Enter um die Suche zu starten."><i class="fal fa-search"></i></button>
          <button *ngIf="mapService.searchSaved && mapService.searchbaractive" (click)="backToSearchResults($event);" class="btn btn-toggle-sidebar-hidden-search"><i class="fas fa-search white"></i></button>
          <form (submit)="search($event)" >
            <div class="searchinputwrapper" *ngIf="!mapService.infowindowhidden || mapService.searchbaractive" @searchbaranim @recordbuttonanim>
            <!-- change noch verbessern, da man erst aus dem fenster rausclicken muss damit change feuert, wenns nicht geht backtosearch result weglassen und neues request feuern halt -->
            <input joyrideStep="suchFeld" title="Suchfunktion" text="Hier können Sie nach Räumen und interessanten Orten suchen. Tippen Sie dazu auf dieses Feld und tragen Sie ein Suchbegriff ein." #searchInputInstance="ngbTypeahead" name="searchInput" translate (selectItem)="search($event)" [(ngModel)]="searchInput" class="searchinputbar test form-control" [ngbTypeahead]="searchTypeAhead" (change)="mapService.searchSaved = false" type="search" placeholder="{{'Search' | translate}}" />
            <div *ngIf="searchInput" (click)="clearSearch()" class="searchdeletebutton" ><i class="fas fa-times"></i></div>
              <div *ngIf="errorShown" class="errorbox"> Es müssen mindestens zwei Zeichen eingegeben werden.</div>
              <div joyrideStep="recordButton" title="Sprachsuche" text="Hier können Sie per Sprache suchen. Tippen Sie dazu Hier und Sprechen nach dem Ton." *ngIf="!searchInput && isChrome || !searchInput && isCordovaApp" (click)="startrecording()" class="recordbutton"  [ngClass]="{'pulse': recording }" ><i class="fas fa-microphone"></i></div>
            </div>
          </form>
         </div>
       </div>
       <div *ngIf="!mapService.setting_show_roombook && !mapService.isCordovaApp" class="navbar-nav ml-auto">
         <div class="nav-item btn btn-user" ngbDropdown placement="bottom-right">
           <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
             <i class="fal fa-user-circle"></i>
           </a>
           <div ngbDropdownMenu aria-labelledby="user-dropdown">
             <h6 class="dropdown-header">
               <span translate>Logged in as</span> <b> {{username}}</b></h6>
             <div class="dropdown-divider"></div>
             <a *ngIf="authenticationService.credentials.userrole === 'admin'" class="dropdown-item" href="assets/Handbuch Gesamt v1.3.pdf" target="_blank" translate><i class="fal fa-file-pdf"></i> Handbuch</a>
             <a *ngIf="authenticationService.credentials.userrole === 'patient'" class="dropdown-item" href="assets/Handbuch Patient v1.3.pdf" target="_blank" translate><i class="fal fa-file-pdf"></i> Handbuch</a>
             <button class="dropdown-item" (click)="logout()" translate><i class="fal fa-sign-out"></i> Logout</button>
           </div>
         </div>
       </div>
     </div>
   </nav>
 </header>

<ng-template #prevButton>
  <button class="btn btn-primary"> ZURÜCK</button>
</ng-template>
<ng-template #nextButton>
  <button class="btn btn-primary"> NÄCHSTER</button>
</ng-template>
<ng-template #doneButton>
  <button class="btn btn-success"> FERTIG</button>
</ng-template>
