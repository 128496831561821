import { Component, OnInit } from '@angular/core';
import {MapService} from '@app/core/services/map.service';

@Component({
  selector: 'map-live-navigation',
  templateUrl: './map-live-navigation.component.html',
  styleUrls: ['./map-live-navigation.component.scss']
})
export class MapLiveNavigationComponent implements OnInit {

  constructor(public mapService: MapService) { }

  ngOnInit() {
  }

}
