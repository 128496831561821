/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./report-modal.component";
import * as i3 from "../../../../services/map.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i5 from "ngx-toastr";
var styles_ReportModalComponent = [i0.styles];
var RenderType_ReportModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportModalComponent, data: {} });
export { RenderType_ReportModalComponent as RenderType_ReportModalComponent };
export function View_ReportModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h6", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fal fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Melden"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(13, 0, null, null, 16, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(15, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(17, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Melden"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "col-12 text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        M\u00F6chten Sie diese Route melden ?\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(31, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "w-50 btn greenbtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SentryreportRoute() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Melden\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "button", [["class", "w-50 btn redbtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Abbrechen\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_ReportModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-modal", [], null, null, null, View_ReportModalComponent_0, RenderType_ReportModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReportModalComponent, [i3.MapService, i4.NgbActiveModal, i5.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportModalComponentNgFactory = i1.ɵccf("app-report-modal", i2.ReportModalComponent, View_ReportModalComponent_Host_0, {}, {}, []);
export { ReportModalComponentNgFactory as ReportModalComponentNgFactory };
