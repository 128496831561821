<div class="container">
  <div class="card">
    <div class="card-foto">
    </div>
    <div class="card-body">
      <div class="row">
        <div class="container" *ngIf="mapService.roomClicked" @roombookAnim>
          <div class="col-12 px-1">
          <h5>Raumbuch</h5>
        </div>
          <div class="col-12">
          <div class="item row">
            <div class="col-6 col-label mb-1 " @text_iconAnim>
              <i class="item fal fa-hospital-alt"></i>
              <b>Reinigungs Revier</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomRevier ? mapService.roomRevier : 'nicht zugeteilt'}}
            </div>
          </div>
          <div class="item row">
            <div class="col-6 col-label mb-1" @text_iconAnim>
              <i class="item fal fa-calendar-alt"></i>
              <b>Turnus</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomCleaningInterval ? mapService.roomCleaningInterval: 'nicht zugeteilt'}}
            </div>
          </div>
          <div class="item row">
            <div class="col-6 col-label mb-1" @text_iconAnim>
              <i class="item fal fa-arrows-h"></i>
              <b>Raumgrösse</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomSize ? mapService.roomSize : 'keine Information'}}
            </div>
          </div>
          <div class="item row">
            <div class="col-6 col-label mb-1" @text_iconAnim>
              <i class="item fal fa-broom"></i>
              <b>Bodenbelag</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomSurface ? mapService.roomSurface : 'keine Information'}}
            </div>
          </div>
          <div class="item row">
            <div class="col-6 col-label mb-1" @text_iconAnim>
              <i class="item fal fa-eye"></i>
              <b>Sichtbarkeit</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomPublic === 'true' ? 'öffentlich' : 'privat'}}
            </div>
          </div>
          <div class="item row">
            <div class="col-6 col-label mb-1" @text_iconAnim>
              <i class="item fal fa-sliders-v-square"></i>
              <b>Kategorie</b>
            </div>
            <div class="col-6 col-value mb-1">
              {{mapService.roomCategory ? mapService.roomCategory : 'nicht zugeteilt'}}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
