<div class="loadingscreen anim2">
  <svg xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     width="300px" height="200px" viewBox="0 0 300 200" enable-background="new 0 0 300 200" xml:space="preserve">
<g class="logotext">
    <path fill="#ffffff" d="M17.875,191.409v-34.125h12.492c4.016,0,7.375,1.309,10.078,3.926s4.055,5.973,4.055,10.066v6.141
        c0,4.109-1.352,7.469-4.055,10.078s-6.063,3.914-10.078,3.914H17.875z M25.797,163.378v21.938h4.57
        c1.797,0,3.277-0.734,4.441-2.203s1.746-3.367,1.746-5.695v-6.188c0-2.297-0.582-4.18-1.746-5.648s-2.645-2.203-4.441-2.203H25.797
        z"/>
    <path fill="#ffffff" d="M57.625,191.409h-7.898v-34.125h7.898V191.409z"/>
    <path fill="#ffffff" d="M81.836,184.776H71.688l-1.969,6.633h-8.344l11.133-34.125h4.242v0.023l0.023-0.023h4.242l11.156,34.125
        h-8.344L81.836,184.776z M73.516,178.683h6.492l-3.188-10.594H76.68L73.516,178.683z"/>
    <path fill="#ffffff" d="M123.063,191.409h-7.898l-12.492-21.469l-0.141,0.047v21.422H94.61v-34.125h7.922l12.492,21.445
        l0.141-0.047v-21.398h7.898V191.409z"/>
    <path fill="#ffffff" d="M146.782,184.776h-10.148l-1.969,6.633h-8.344l11.133-34.125h4.242v0.023l0.023-0.023h4.242l11.156,34.125
        h-8.344L146.782,184.776z M138.461,178.683h6.492l-3.188-10.594h-0.141L138.461,178.683z"/>
    <path fill="#ffffff" d="M177.485,189.394h0.141l14.25-32.109h1.43v34.125h-1.266v-31.008l-0.141-0.023l-13.828,31.031h-1.008
        l-14.133-31.711l-0.141,0.023v31.688h-1.266v-34.125h1.758L177.485,189.394z"/>
    <path fill="#ffffff" d="M221.079,181.214h-16.664l-3.82,10.195h-1.383l12.75-34.125h1.547l12.773,34.125h-1.383L221.079,181.214z
         M204.883,179.948h15.727l-7.148-19.102l-0.656-1.992h-0.141l-0.633,1.992L204.883,179.948z"/>
    <path fill="#ffffff" d="M233.032,176.761v14.648h-1.266v-34.125h10.664c3.531,0,6.25,0.887,8.156,2.66
        c1.906,1.773,2.859,4.129,2.859,7.066c0,2.969-0.949,5.336-2.848,7.102c-1.898,1.766-4.621,2.648-8.167,2.648H233.032z
         M233.032,175.495h9.398c3.234,0,5.668-0.805,7.3-2.414c1.633-1.609,2.449-3.617,2.449-6.023c0-2.469-0.813-4.504-2.438-6.105
        c-1.625-1.602-4.062-2.402-7.312-2.402h-9.398V175.495z"/>
    <path fill="#ffffff" d="M280.75,183.37c0-1.953-0.801-3.527-2.402-4.723s-4.332-2.426-8.191-3.691
        c-3.844-1.172-6.676-2.414-8.496-3.727s-2.73-3.258-2.73-5.836c0-2.563,1.066-4.637,3.199-6.223s4.84-2.379,8.121-2.379
        c3.406,0,6.145,0.875,8.215,2.625s3.074,4.195,3.012,7.336l-0.047,0.141h-1.078c0-2.547-0.938-4.656-2.813-6.328
        s-4.305-2.508-7.289-2.508c-3.078,0-5.523,0.723-7.336,2.168s-2.719,3.152-2.719,5.121s0.73,3.566,2.191,4.793
        s4.184,2.426,8.168,3.598c3.875,1.219,6.758,2.484,8.648,3.797s2.836,3.242,2.836,5.789c0,2.594-1.117,4.672-3.352,6.234
        s-4.992,2.344-8.273,2.344c-3.422,0-6.367-0.801-8.836-2.402s-3.664-4.121-3.586-7.559l0.047-0.141h1.055
        c0,3.094,1.141,5.34,3.422,6.738s4.914,2.098,7.898,2.098s5.453-0.691,7.406-2.074S280.75,185.448,280.75,183.37z"/>
</g>
    <g class="logo">
<path fill="none" stroke="#ffffff" stroke-width="4" stroke-miterlimit="10" d="M181.614,40.217
    c0,16.968-30.723,46.699-30.723,46.699s-30.723-29.731-30.723-46.699s13.755-30.723,30.723-30.723S181.614,23.249,181.614,40.217z"
    />
      </g>
</svg>
  <div class="anim circle">
  </div>
</div>
