import {Component, AfterViewInit, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';
import {RestService} from '@app/core/services/rest.service';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('MapViewComponent');

@Component({
  selector: 'map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, AfterViewInit {

  constructor(public mapService: MapService,
              private authService: AuthenticationService,
              private restService: RestService) {
  }

  ngOnInit() {
    // todo: würde ich in den map service im on init prüfen...
    if (this.authService.credentials) {
      if (this.authService.credentials['userrole'] === 'cleaner' ||
        this.authService.credentials['userrole'] === 'admin' ||
        this.authService.credentials['userrole'] === 'employee') {
        this.mapService.source_wms_eg00_params = {'LAYERS': 'Diana:eg0komplett'};
        this.mapService.source_wms_eg01_params = {'LAYERS': 'Diana:eg01komplett'};
        this.mapService.source_wms_og01_params = {'LAYERS': 'Diana:og01komplett'};
        this.mapService.source_wms_og02_params = {'LAYERS': 'Diana:og02komplett'};
        this.mapService.source_wms_og03_params = {'LAYERS': 'Diana:og03komplett'};
        this.mapService.source_wms_og04_params = {'LAYERS': 'Diana:og04komplett'};
        this.mapService.source_wms_og05_params = {'LAYERS': 'Diana:og05komplett'};
        this.mapService.source_wms_og06_params = {'LAYERS': 'Diana:og06komplett'};
        this.mapService.source_wms_og07_params = {'LAYERS': 'Diana:og07komplett'};
        this.mapService.source_wms_og08_params = {'LAYERS': 'Diana:og08komplett'};
        this.mapService.source_wms_og09_params = {'LAYERS': 'Diana:og09komplett'};
        this.mapService.source_wms_og10_params = {'LAYERS': 'Diana:og10komplett'};
        this.mapService.source_wms_og11_params = {'LAYERS': 'Diana:og11komplett'};
        this.mapService.outline_eg01_params = {'LAYERS': 'Diana:UMRISS_EG1_PUBLIC_GRP'};
        this.mapService.outline_eg00_params = {'LAYERS': 'Diana:UMRISS_EG0_GRP'};
      } else {
        this.mapService.source_wms_eg00_params = {'LAYERS': 'Diana:DIANA_EG00_POLY_public'};
        this.mapService.source_wms_eg01_params = {'LAYERS': 'Diana:DIANA_EG01_POLY_public'};
        this.mapService.source_wms_og01_params = {'LAYERS': 'Diana:DIANA_OG01_POLY_public'};
        this.mapService.source_wms_og02_params = {'LAYERS': 'Diana:DIANA_OG02_POLY_public'};
        this.mapService.source_wms_og03_params = {'LAYERS': 'Diana:DIANA_OG03_POLY_public'};
        this.mapService.source_wms_og04_params = {'LAYERS': 'Diana:DIANA_OG04_POLY_public'};
        this.mapService.source_wms_og05_params = {'LAYERS': 'Diana:DIANA_OG05_POLY_public'};
        this.mapService.source_wms_og06_params = {'LAYERS': 'Diana:DIANA_OG06_POLY_public'};
        this.mapService.source_wms_og07_params = {'LAYERS': 'Diana:DIANA_OG07_POLY_public'};
        this.mapService.source_wms_og08_params = {'LAYERS': 'Diana:DIANA_OG08_POLY_public'};
        this.mapService.source_wms_og09_params = {'LAYERS': 'Diana:DIANA_OG09_POLY_public'};
        this.mapService.source_wms_og10_params = {'LAYERS': 'Diana:DIANA_OG10_POLY_public'};
        this.mapService.source_wms_og11_params = {'LAYERS': 'Diana:DIANA_OG11_POLY_public'};
        this.mapService.outline_eg01_params = {'LAYERS': 'Diana:UMRISS_EG1_PUBLIC_GRP'};
        this.mapService.outline_eg00_params = {'LAYERS': 'Diana:UMRISS_EG0_GRP'};

      }
      this.restService.getSearchSuggestionArray().then((data) => {
        this.mapService.search_suggestions = data;
      });
    } else {

    }

  }

  ngAfterViewInit() {
    this.mapService.initMap();
    // todo: würde ich auch im map server im on init ausführen
    if (this.authService.credentials) {
      if (this.authService.credentials['userrole'] === 'patient') {
        this.mapService.toggleRoomNames();
        this.mapService.show_poi = true;
        this.mapService.setting_show_poi_freizeit  = false;
        this.mapService.setting_show_poi_pflege = false;
        this.mapService.setting_show_poi_therapie = false;
        this.mapService.setting_show_poi_eingaenge_ausgaenge = true;
        this.mapService.setting_show_poi_treppen_aufzuege = true;
      } else if (this.authService.credentials['userrole'] === 'cleaner') {
        this.mapService.toggleRoomNames();
        this.mapService.toggleRoomCleaningStatus();
      }
      this.mapService.redrawPOI();
    }
  }

  onRightClick(event: any) {
    if (this.authService.credentials) {
      if (this.authService.credentials['poiinfowindowadmin'] === true) { // better
        this.mapService.rightClickOnMap(event);
      }
    }
  }

}
