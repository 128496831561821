<div *ngIf="!isCordovaApp">
  <div class="login-container bg-light">
    <div class="login-box">
      <h1 translate>APP_NAME</h1>
      <div>
        <h6 class="d-inline-block">v{{version}}</h6>
        <!--<div class="d-inline-block ml-3" ngbDropdown>
           <button id="language-dropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>
            {{currentLanguage}}
          </button>
          <div ngbDropdownMenu aria-labelledby="language-dropdown">
            <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
              {{language}}
            </button>
          </div>
        </div> -->
      </div>
      <div class="container">
        <div class="card mx-auto">
          <div class="card-body">
            <h4 class="card-title text-center">
              <img class="img-fluid" src="assets/img/logo2.png" />
            </h4>
            <form class="loginform" (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
              <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
                Username or password incorrect.
              </div>
              <div class="group">
                <input type="text" class="w-100" formControlName="username" autocomplete="username" required/>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label><i class="fal fa-user"></i> Benutzer</label>
                <small [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                       class="text-danger" translate>
                  Username is required
                </small>
              </div>
              <div class="group">
                <input type="password" class="w-100" formControlName="password" autocomplete="current-password" required/>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label><i class="fal fa-key"></i> Passwort</label>
                <small [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                       class="text-danger" translate>
                  Password is required
                </small>
              </div>
              <!-- <div class="form-group col-form-label remember-me">
                <span class="switch switch-sm">
                  <input type="checkbox" class="switch" id="switch-sm0" name="remember" />
                  <label class="mb-0" for="switch-sm0" translate>Remember me</label>
                </span>
              </div> -->
              <button class="btn btn-primary w-100" type="submit" [disabled]="loginForm.invalid || isLoading">
                <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                <span translate>Login</span>
              </button>
              <div  class="disclaimer text-center">
                <small>
                  Mit dem Benutzen dieser Software stimmen Sie den Vertragsbedingungen der DIANA Krankenhausbetriebsgesellschaft mbH & vigsato GmbH zu.
                </small>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isCordovaApp && missingIntent">
  <div class="row height-100vh">
    <div class="col-12 my-auto text-center">
      <div class="p-1">
        <div class="">
          <h1>Hier ist etwas schief gelaufen!</h1>
          <h2>Bitten überprüfen Sie die Parameter mit denen die App gestartet wurde.</h2>
          <img src="assets/img/stop.png" />
        </div>
      </div>
    </div>
  </div>
</div>
