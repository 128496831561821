import { Component, OnInit } from '@angular/core';
import { MapService} from '@app/core/services/map.service';
import {RestService} from '@app/core/services/rest.service';
import {animate, query, stagger, style, transition, trigger, keyframes} from '@angular/animations';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('WelcomeWidgetCleanerComponent');

@Component({
  selector: 'welcome-widget-cleaner',
  templateUrl: './welcome-widget-cleaner.component.html',
  styleUrls: ['./welcome-widget-cleaner.component.scss'],
  animations: [
    trigger('componentAnim', [
      transition('* => *', [
        query('.component', style({ opacity: 0, transform: 'translateX(-100px)' })),

        query('.component', stagger('100ms', [
          animate('400ms 0.2s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ]))
      ])
    ]),
    trigger('s_background_imageAnim', [
      transition('* => *', [
        query('.sidebar-background', style({ opacity: 0, filter: 'blur(5px)'})),

        query('.sidebar-background', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, filter: 'blur(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ])
  ]
})
export class WelcomeWidgetCleanerComponent implements OnInit {

  // Todo - No more Hardcoded Districts - Get Districts from Backend other Data Source
  districts: any = [
    'ohne Reinigung',
    'Frührevier Speisesaal',
    'Frührevier Fischer',
    'Spätdienst',
    'Bettenteam',
    'Revier 01 B',
    'Revier 01 C/D',
    'Revier 02 A',
    'Revier 02 B',
    'Revier 02 C/D',
    'Revier 03 A',
    'Revier 03 B',
    'Revier 03 C',
    'Revier 04 A',
    'Revier 04 B',
    'Revier 04 C',
    'Revier 05 A',
    'Revier 05 B',
    'Revier 05 C',
    'Revier 06',
    'Revier WE 07/08',
    'Revier 09+10',
    'vorher Revier 09+10',
    'Neubau EG',
    'Neubau 1. OG',
    'Neubau 2. OG'
  ];

  cleaningStatus = ['gereinigt', 'maengel', 'unrein'];
  tasks: any;

  constructor(public Mapservice: MapService, private restService: RestService) {  }

  ngOnInit() {
    this.getTasks();
  }

  getTasks(/* userid */) {
    // request body muss gebaut werden je nach userid / userteam ; hier nur als mockup
    const requestBody = {'rb_revier': 'Revier 01 B', 'rb_status': 'gereinigt'};
    this.restService.getRoomsByDistrictOrStatus(requestBody).then((data) => {
      this.tasks = data;
    });
  }

  setDistrict(inputValue: any) {
    this.Mapservice.dropdown_selected_district = inputValue;
    if (this.Mapservice.dropdown_selected_status !== 'Status') {
      this.Mapservice.activeRoomNextFloorTable = [];
      this.Mapservice.showRoomsByDistrictOrStatus();
      this.Mapservice.cleaning_welcome_selection = true;
    }
  }

  setCleaningStatus(inputValue: any) {
    this.Mapservice.dropdown_selected_status = inputValue;
    if (this.Mapservice.dropdown_selected_district !== 'Revier wählen') {
      this.Mapservice.activeRoomNextFloorTable = [];
      this.Mapservice.setting_show_cleaning_status = true;
      this.Mapservice.cleaning_welcome_selection = true;
      this.Mapservice.showRoomsByDistrictOrStatus();
    }
  }

  resetSelection () {
    this.Mapservice.activeRoomNextFloorTable = [];
    this.Mapservice.activeRoomsTable = [];
    this.Mapservice.cleaning_welcome_selection = false;
    this.Mapservice.dropdown_selected_district = 'Revier wählen';
    this.Mapservice.dropdown_selected_status = 'Status';
    this.Mapservice.layer_cleaning_status.getSource().clear();
    if (this.Mapservice.setting_show_cleaning_status) {
      this.Mapservice.setting_show_cleaning_status = false;
      this.Mapservice.toggleRoomCleaningStatus();
    }
  }
}
