import {Component, OnDestroy, OnInit} from '@angular/core';
import { MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {RestService} from '@app/core/services/rest.service';
import Feature from 'ol/Feature';
import WFS from 'ol/format/WFS';
import {ToastrService} from 'ngx-toastr';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
const log = new Logger('PoiInfoWindowAdminComponent');

@Component({
  selector: 'poi-info-window-admin',
  templateUrl: './poi-info-window-admin.component.html',
  styleUrls: ['./poi-info-window-admin.component.scss'],
  animations: [
    trigger('roombookAnim', [
      transition('* => *', [
        query('.item', style({ opacity: 0, transform: 'translateX(-40px)' })),

        query('.item', stagger('50ms', [
          animate('0.2s ease-out', keyframes([
            style({opacity: 0, transform: 'translateX(-109px)',     offset: 0}),
            style({opacity: 1, transform: 'translateX(0px)',     offset: 0.5}),
            style({opacity: 1, transform: 'translateX(10px)', offset: 0.75}),
            style({opacity: 1, transform: 'translateX(0)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('s_background_imageAnim', [
      transition('* => *', [
        query('.header-image', style({ opacity: 0, filter: 'blur(5px)'})),

        query('.header-image', stagger('200ms', [
          animate('0.8s ease-out', keyframes([
            style({opacity: 1, filter: 'blur(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ]),
    trigger('s_background_textAnim', [
      transition('* => *', [
        query('.roomtext', style({ opacity: 0, transform: 'translateX(-500px)' })),

        query('.roomtext', stagger('300ms', [
          animate('0.4s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(20px)',  offset: 0.5}),
            style({opacity: 1, transform: 'translateX(0px)',  offset: 1.0})
          ]))
        ])),
      ])
    ])
  ]
})
export class PoiInfoWindowAdminComponent implements OnInit, OnDestroy {

  poiSubscription: any;

  poiFeature: Feature;
  poiName: string;
  poiDetails: string;
  poiCategory: string;

  showErrorMissingIcon: any = false;

  poiSelectedIconName: string;
  poiImageURLs: Object = {
    'Freizeit': 'assets/img/poi/freizeit.jpg',
    'Pflege': 'assets/img/poi/pflege.jpg',
    'Therapie': 'assets/img/poi/therapie.jpg',
    'Eingänge & Ausgänge': 'assets/img/poi/eingang.jpg',
    'Treppen & Aufzüge': 'assets/img/poi/treppe.jpg'
  };

  poiImageURL: any;

  constructor(public mapService: MapService, public restService: RestService, private toast: ToastrService) {  }

  ngOnInit() {
    log.debug('init poi info window admin');
    this.poiSubscription = this.mapService.clickedPoiFeatureSubject.subscribe((feature: Feature) => {
      if (feature != null) {
        this.poiFeature = feature;
        this.poiName = feature.get('name');
        this.poiDetails = feature.get('details');
        this.poiCategory = feature.get('cat');
        this.poiSelectedIconName = feature.get('icon');

        const poiImageKeys = Object.keys(this.poiImageURLs);
        for (let i = 0; i < poiImageKeys.length; i++) {
            if ((this.poiCategory.indexOf(poiImageKeys[i]) !== -1)) {
              this.poiImageURL = this.poiImageURLs[poiImageKeys[i]];
              return;
            }

        }
      }
    });
  }

  ngOnDestroy(): void {
    log.debug('destroy poi info window admin');
    this.poiSubscription.unsubscribe();
  }

  save() {
    log.debug('upadting poi feature', this.poiFeature);
    this.poiFeature.set('name', this.poiName);
    this.poiFeature.set('details', this.poiDetails);
    this.poiFeature.set('icon', this.poiSelectedIconName);
    this.poiFeature.set('cat', this.poiCategory);
    this.poiFeature.setGeometryName('geom');
    // this.poiFeature.set('table', this.map);
    // this.poiFeature.set('rb_ebene', this.activeFloorNumber);
    this.poiFeature.unset('type');

    log.debug('upadting poi feature2', this.poiFeature);

    const format = new WFS({});
    const node = format.writeTransaction(null, [this.poiFeature], null, {
      featurePrefix: '',
      featureNS: 'Diana',
      featureType: 'poi',
      srsName: 'EPSG:3857',
      nativeElements: []
    });

    const xmlSerializer = new XMLSerializer();
    this.restService.sendWFSTRequest(xmlSerializer.serializeToString(node)).then((data) => {
      this.mapService.reloadSearchSuggestions();
      log.info('update poi success');
      this.mapService.redrawPOI();
      this.toast.success('POI erfolgreich aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    }, (error) => {
      log.debug('upadting poi', error.message);
      this.toast.error('POI wurde nicht aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    });

  }

  close() {
    this.mapService.infowindowhidden = true;
    this.mapService.searchbaractive = false;
  }

  setCategory(category: string) {
    this.poiCategory = category;
  }

  selectIcon(name: string) {
    this.poiSelectedIconName = name;
  }

}
