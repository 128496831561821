import { Component, OnInit } from '@angular/core';
import {RestService} from '@app/core/services/rest.service';
import {MapService} from '@app/core/services/map.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'route-edit-widget',
  templateUrl: './route-edit-widget.component.html',
  styleUrls: ['./route-edit-widget.component.scss']
})
export class RouteEditWidgetComponent implements OnInit {

  constructor(public mapService: MapService, private restService: RestService, private toast: ToastrService) {
  }

  ngOnInit(): void {
  }

  route_public(event: any) {
    if (this.mapService.selected_route_segments.length === 1) { this.mapService.selected_route_segments[0].set('public', event); } else {
      this.mapService.selected_route_segments.forEach(function (feat: any) {
       feat.set('public', event.target.checked);
      });
    }
    this.updateRouteData();
  }

  route_accessible(event: any) {
    if (this.mapService.selected_route_segments.length === 1) { this.mapService.selected_route_segments[0].set('accessible', event); } else {
      this.mapService.selected_route_segments.forEach(function (feat: any) {
        feat.set('accessible', event.target.checked);
      });
    }
    this.updateRouteData();
  }


  updateRouteData() {

    const ids = [];
    this.mapService.selected_route_segments.forEach(function (feat: any) {
      ids.push(feat.get('id'));
    });

    const attributeData = {
      'public': this.mapService.selected_route_segments[0].get('public'),
      'accessible': this.mapService.selected_route_segments[0].get('accessible')
    };

    this.restService.updateRouteData(ids, attributeData).then(() => {
      this.toast.success('Route erfolgreich aktualisiert', '', {
        positionClass: 'toast-bottom-left',
      });
    });
  }

}
