import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {I18nService, AuthenticationService} from 'app/core/index';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private i18nService: I18nService,
              private authenticationService: AuthenticationService,
              private toast: ToastrService) {
  }

  ngOnInit() {
  }

  start() {
    const creds = {username: 'patient', password: 'patient'};
    this.authenticationService.login(creds, 'patient', '', 'patient').subscribe((credentials) => {
      this.toast.success('Sie wurden in DianaMaps als Benutzer ' + credentials.username + ' angemeldet.', 'Anmeldung erfolgreich:'); // toast #DK-103
    }, (error) => {
      this.toast.error('Überprüfen Sie Ihren Benutzernamen und Ihr Kennwort. Wenn Sie sich weiterhin nicht anmelden können, wenden Sie sich an einen Administrator.', 'Anmeldung fehlgeschlagen:'); // toast #DK-103
    });
  }

  login() {
    this.router.navigate(['/login']);
  }

  notActive() {
    this.toast.show(null, 'Funktion ist noch nicht aktiviert!');
  }

}
