<div class="container"  *ngIf="mapService.poiClicked || mapService.roomClicked">
  <div class="card">
    <div class="card-foto">
    </div>
    <div class="card-header">
      <h5>
        <i class="fas fa-location-arrow"></i> Wegeführung
      </h5>
    </div>
    <div class="card-body">

      <div class="row text-center navibuttonsrow " @iconSwipeAnim>

        <div *ngIf="!mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('start', null,'room')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-location-arrow"></i> ALS START FESTLEGEN
          </button>
        </div>

        <div *ngIf="mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('start', null, 'poi')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-location-arrow"></i> ALS START FESTLEGEN
          </button>
        </div>

        <div *ngIf="!mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('end', null,'room')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-flag"></i> ALS ZIEL FESTELGEN
          </button>
        </div>

        <div *ngIf="mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('end', null,'poi')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-flag"></i> ALS ZIEL FESTELGEN
          </button>
        </div>

        <div *ngIf="isCordovaApp && !mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('end', null, 'room');mapService.setNaviPoint('start', 'useroom')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-home-heart"></i> VON MEINEM ZIMMER HIERHER
          </button>
        </div>

        <div *ngIf="isCordovaApp && mapService.poiClicked" class="col-md-12" (click)="mapService.setNaviPoint('end', null, 'poi');mapService.setNaviPoint('start', 'useroom')" >
          <button  class="nav-item text-uppercase btn btn-navi">
            <i class="fas fa-home-heart"></i> VON MEINEM ZIMMER HIERHER
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="mapService.NaviStart || mapService.NaviEnd">
  <div class="container" @routeWidgetAnim>
    <div class="card-body-2 p-0">
      <div class="row">
        <div class="white-outline-container">
          <div class="row blue-outline-container">
            <div class="col-md-2 text-center routeicons">
              <i class="fal fa-route"></i>
            </div>
            <div class="col-md-8">
              <span name="NaviStart" class="naviinputbar test form-control">  {{mapService.NaviStartName}} {{mapService.NaviStartNumber}} </span>
              <span name="NaviEnd" class="naviinputbar test form-control">  {{mapService.NaviEndName}} {{mapService.NaviEndNumber}} </span>
            </div>
            <div class="col-md-2 switchbutton">
              <i class="fal fa-arrow-up"></i>
            </div>
            <div class="col-12 row toggles">
              <div class="col-6 text-center toggle-border">
                <i class="route-toggle-icon fab fa-accessible-icon"></i><br />
                <label class="col-form-label">Barrierefrei</label>
                <div class="form-group col-form-label">
                  <span class="switch switch-sm">
                    <input type="checkbox" class="switch" id="switch-sm0" [checked]="this.mapService.show_routeAccessible" (change)="this.mapService.show_routeAccessible = !this.mapService.show_routeAccessible; this.mapService.startNavi(false)"/>
                    <label class="mb-0" for="switch-sm0"></label>
                  </span>
                </div>
              </div>
              <div class="col-6 text-center">
                <button class="btn route-delete-button" (click)="this.mapService.killRoute()">
                  <i class="route-toggle-icon fa fa-trash-alt"></i>
                </button>
                <br />
                <label class="col-form-label">LÖSCHEN</label>
              </div>
              <div *ngIf="authService.credentials['userrole'] != 'patient'" class="col-6 text-center">
                <i class="route-toggle-icon fas fa-user-md"></i><br />
                <label class="col-form-label">Öffentlich</label>
                <div class="form-group col-form-label">
                  <span class="switch switch-sm">
                    <input type="checkbox" class="switch" id="switch-sm1" [checked]="this.mapService.show_routePublic" (change)="this.mapService.show_routePublic = !this.mapService.show_routePublic; this.mapService.startNavi(false)"/>
                    <label class="mb-0" for="switch-sm1"></label>
                  </span>
                </div>
              </div>
              <div class="startnavi col-12 text-center">
                <button class="btn btn-start-navi" (click)="mapService.SwitchNavigationMode()">Navigation Starten</button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="mapService.NaviRouteSegments && mapService.NaviRouteSegments.length > 1">
            <div class="row overview">
              <div class="overview-inner">
                <b>{{mapService.NaviTime}}</b><br /> {{mapService.NaviLength}} m
              </div>
            </div>
          <div class="row instructions">
            <ng-container *ngFor="let item of mapService.NaviRouteSegments; let index = index">
              <div class="col-md-2 instructionicon">
                <i [ngClass]="item.get('icon')"></i>
              </div>
              <div class="col-md-10 instruction" (click)="instructionclick(item)">
                {{item.get('instruction')}} <span class="instruction-roomnumber">{{item.get('roomnumber')}} </span>
                <hr class="divider" />
                <span class="dist">{{item.get('cost').toFixed(2)}} m</span>
              </div>
            </ng-container>
          </div>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <div class="report-route">
            <button (click)="reportRoute();" class="btn btn-report">Falsche Route Melden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
