<div class="container">
  <div class="">
    <div class="card-body">
      <div class="card-foto">
      </div>
      <div class="card-header">
        <h5> <i class="fas fa-map-marker-alt"></i> Interessante Orte</h5>
      </div>
      <div class="row poiwidget d-flex justify-content-between" [@explainerAnim] joyrideStep="poiCategorys" title="Interessante Orte" text="Hier können Sie sich interessante Orte innerhalb des Klinikgeländes ein- und ausblenden. Die Symbole erscheinen nachdem Sie angewählt wurden auf der Karte.">
          <div class="col-4 p-0 text-center">
            <button class="btn btn-freizeit rounded-circle mb-2" [ngClass]="{'active_cat': this.mapService.setting_show_poi_freizeit && this.mapService.show_poi}" (click)="togglePoiCategory('Freizeit')" ><i class="fal fa-utensils"></i></button>
            <p>Freizeit</p>
          </div>
          <div class="col-4 p-0 text-center">
            <button class="btn btn-pflege rounded-circle mb-2" [ngClass]="{'active_cat': this.mapService.setting_show_poi_pflege && this.mapService.show_poi}" (click)="togglePoiCategory('Pflege')"><i class="fal fa-user-md"></i></button>
            <p>Pflege</p>
          </div>
          <div class="col-4 p-0 text-center">
            <button class="btn btn-therapie rounded-circle mb-2" [ngClass]="{'active_cat': this.mapService.setting_show_poi_therapie && this.mapService.show_poi}" (click)="togglePoiCategory('Therapie')"><i class="fal fa-notes-medical"></i></button>
            <p>Therapie</p>
          </div>
        <div class="ml-4 row poiwidget d-flex justify-content-between" *ngIf="this.admin_role">
          <div class="col-4 p-0 text-center">
            <button class="btn btn-zugang rounded-circle mb-2" [ngClass]="{'active_cat': this.mapService.setting_show_poi_eingaenge_ausgaenge && this.mapService.show_poi}" (click)="togglePoiCategory('Eingänge & Ausgänge')"><i class="fal fa-door-closed"></i></button>
            <p>Zugänge</p>
          </div>
          <div class="col-4 pl-4 ml-2 text-center">
            <button class="btn btn-treppe rounded-circle mb-2" [ngClass]="{'active_cat': this.mapService.setting_show_poi_treppen_aufzuege && this.mapService.show_poi}" (click)="togglePoiCategory('Treppen & Aufzüge')"><i class="fal fa-sort"></i></button>
            <p>Aufzüge</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
