import { Router } from '@angular/router';
import { MapService } from '@app/core/services/map.service';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { I18nService } from '@app/core/services/i18n/i18n.service';
import { Logger } from '@app/core/services/logger/logger.service';
var log = new Logger('MapFloorLayerSwitchComponent');
var MapFloorLayerSwitchComponent = /** @class */ (function () {
    function MapFloorLayerSwitchComponent(router, authenticationService, i18nService, mapService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.i18nService = i18nService;
        this.mapService = mapService;
    }
    MapFloorLayerSwitchComponent.prototype.getRouteFloors = function (floorname) {
        return this.mapService.activeRoomFloors.find(function (element) { return element === floorname; });
    };
    MapFloorLayerSwitchComponent.prototype.setLanguage = function (language) {
        this.i18nService.language = language;
    };
    Object.defineProperty(MapFloorLayerSwitchComponent.prototype, "currentLanguage", {
        get: function () {
            return this.i18nService.language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapFloorLayerSwitchComponent.prototype, "languages", {
        get: function () {
            return this.i18nService.supportedLanguages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapFloorLayerSwitchComponent.prototype, "username", {
        get: function () {
            var credentials = this.authenticationService.credentials;
            return credentials ? credentials.username : null;
        },
        enumerable: true,
        configurable: true
    });
    return MapFloorLayerSwitchComponent;
}());
export { MapFloorLayerSwitchComponent };
