import {Component, OnInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('PoiInfoWindowComponent');

@Component({
  selector: 'poi-info-window',
  templateUrl: './poi-info-window.component.html',
  styleUrls: ['./poi-info-window.component.scss']
})
export class PoiInfoWindowComponent implements OnInit {

  poiImageURLs: Object = {
    'Freizeit': 'assets/img/poi/freizeit.jpg',
    'Pflege': 'assets/img/poi/pflege.jpg',
    'Therapie': 'assets/img/poi/therapie.jpg',
    'Eingänge & Ausgänge': 'assets/img/poi/eingang.jpg',
    'Treppen & Aufzüge': 'assets/img/poi/treppe.jpg'
  };

  poiImageURL: any;

  constructor(public mapService: MapService) { }

  ngOnInit() {
    if (this.mapService.poiCat) {
      const poiImageKeys = Object.keys(this.poiImageURLs);
      for (let i = 0; i < poiImageKeys.length; i++) {
        if ((this.mapService.poiCat.indexOf(poiImageKeys[i]) !== -1)) {
          this.poiImageURL = this.poiImageURLs[poiImageKeys[i]];
          return;
        }

      }
    }
  }
}
