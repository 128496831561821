<div class="controls-container">
  <button (click)="mapService.show_user_room('button')" class="btn btn-custom-3d"
          joyrideStep="meinraumButton" title="Meinen Patientenraum anzeigen"
          text="Tippen Sie hier, um das eigene Zimmer auf der Karte zu finden.">
    <i class="fal fa-location"></i>
  </button>

  <button (click)="mapService.zoomMap('in', 0.5)" class="btn btn-custom-zoomin" joyrideStep="zoominButton"
          title="Karte vergößern" [stepContent]="customContent">
    <i class="fal fa-plus"></i>
  </button>

  <button (click)="mapService.zoomMap('out', 0.5)" class="btn btn-custom-zoomout" joyrideStep="zoomoutButton"
          title="Karte verkleinern" [stepContent]="customContent2">
    <i class="fal fa-minus"></i>
  </button>

  <button (click)="mapService.rotateMap(2)" class="btn btn-custom-turn" joyrideStep="rotationButton"
          title="Karte drehen"
          text="Tippen Sie hier, um die Karte um 90° zu drehen. Die Karte kann auch durch eine Drehung mit zwei Fingern rotiert werden.">
    <i class="fas fa-redo"></i>
  </button>

  <button (click)="mapService.locate_me()" class="btn btn-custom-locate" joyrideStep="rotationButton"
          title="Eigene Position finden"
          text="Tippen Sie hier, um ihre eigene Position anzuzeigen.">
    <i class="fas fa-map"></i>
  </button>

  <ng-template #customContent>
    <div class="row">
      <div class="col-4">
        <img class="joyrideimage" src="assets/img/zoomin.gif"/>
      </div>
      <div class="col-8">
        Tippen Sie hier, um den Kartenausschnitt zu vergrößern. Die Karte kann auch durch das Auseinanderfahren von zwei
        Fingern vergrößert werden.
      </div>
    </div>
  </ng-template>

  <ng-template #customContent2>
    <div class="row">
      <div class="col-4">
        <img class="joyrideimage" src="assets/img/zoomout.gif"/>
      </div>
      <div class="col-8">
        Tippen Sie hier, um den Kartenausschnitt zu verkleinern. Die Karte kann auch das Zusammenfahren von zwei Fingern
        verkleinert werden.
      </div>
    </div>
  </ng-template>

  <div class="btn btn-custom-branding">
    <a href="https://www.visgato.de" target="_blank">
      <img src="assets/img/branding.png"/>
    </a>
  </div>

</div>
