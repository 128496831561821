<perfect-scrollbar #directiveRef class="sidebar-content"><div class="sidebar-pading-div"></div>
  <!--<div class="" [ngClass]="{'welcomesidebar': mapService.welcome_open && authService.credentials.welcomeaccess}">-->

  <ng-container *ngIf="mapService.search_results_hidden" >
  <welcome-widget
    *ngIf="!mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && authService.credentials.welcomeaccess"></welcome-widget>
  <welcome-widget-admin
    *ngIf="!mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && mapService.welcome_open && authService.credentials.welcomeaccess_admin"></welcome-widget-admin>
  <welcome-widget-cleaner
    *ngIf="!mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && mapService.welcome_open && authService.credentials.welcomeaccess_cleaner"></welcome-widget-cleaner>
  <poi-widget
    *ngIf="authService.credentials.poiwidgetaccess_admin && !mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && !mapService.beaconClicked"></poi-widget>
  <map-layer-setting
    *ngIf="authService.credentials.layersettingsaccess && !mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && !mapService.beaconClicked"></map-layer-setting>
  <settings-info-window-admin
      *ngIf="authService.credentials.settingsaccess && !mapService.roomClicked && !mapService.poiClicked && !mapService.routeSegmentClicked && !mapService.beaconClicked"></settings-info-window-admin>
  </ng-container>

  <!-- If POI clicked -->
  <poi-info-window
    *ngIf="mapService.poiClicked && !mapService.roomClicked && authService.credentials.poiinfowindow"></poi-info-window>
  <poi-info-window-admin
    *ngIf="mapService.poiClicked && !mapService.roomClicked && authService.credentials.poiinfowindowadmin"></poi-info-window-admin>
  <routing-widget *ngIf="mapService.poiClicked && !mapService.roomClicked && ( authService.credentials.poiinfowindow || authService.credentials.poiinfowindowadmin )"></routing-widget>

  <!-- If Room clicked -->
  <ng-container *ngIf="mapService.roomClicked">
    <room-name-info *ngIf="authService.credentials.roominfoaccess"></room-name-info>
    <routing-widget></routing-widget>
    <room-book-info-window *ngIf="authService.credentials.roombookaccess"></room-book-info-window>
    <room-book-info-window-admin *ngIf="authService.credentials.roombookadminaccess"></room-book-info-window-admin>
  </ng-container>

  <!-- If Route clicked -->
  <ng-container *ngIf="mapService.routeSegmentClicked && !mapService.roomClicked">
    <route-edit-widget></route-edit-widget>
  </ng-container >

  <ng-container *ngIf="mapService.beaconClicked">
    <beacon-info-window-admin></beacon-info-window-admin>
  </ng-container >

  <map-search-result
    *ngIf="!mapService.search_results_hidden && !mapService.roomClicked && !mapService.poiClicked"></map-search-result>
</perfect-scrollbar>
