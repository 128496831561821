import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreatePoiModalComponent} from '@app/core/pages/map/modals/create-poi-modal/create-poi-modal.component';
import {CreateBeaconModalComponent} from '@app/core/pages/map/modals/create-beacon-modal/create-beacon-modal.component';
import {Logger} from '@app/core/services/logger/logger.service';
const log = new Logger('MapPoiContextMenuComponent');

@Component({
  selector: 'map-poi-context-menu',
  templateUrl: './map-poi-context-menu.component.html',
  styleUrls: ['./map-poi-context-menu.component.scss']
})
export class MapPoiContextMenuComponent {

  createPoiModalComponentReference: any;
  createBeaconModalComponentReference: any;

  constructor(public mapService: MapService, private modalService: NgbModal) { }

  openCreatePoiModal() {
    this.mapService.contextMenuOpen = false;
    this.createPoiModalComponentReference = this.modalService.open(CreatePoiModalComponent);
  }

  openCreateBeaconModal() {
    this.mapService.contextMenuOpen = false;
    this.createBeaconModalComponentReference = this.modalService.open(CreateBeaconModalComponent);
  }

  deletePOI() {
    this.mapService.deletePOI();
  }

  deleteBeacon() {
    this.mapService.deleteBeacon();
  }

  editPOI() {
    this.mapService.contextMenuOpen = false;
    this.mapService.welcome_open = false;
    this.mapService.poiClicked = true;
    this.mapService.roomClicked = false;
    this.mapService.infowindowhidden = false;
  }

}
