import {Component} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {AuthenticationService} from '@app/core/services/authentication/authentication.service';

@Component({
  selector: 'map-layer-setting',
  templateUrl: './map-layer-setting.component.html',
  styleUrls: ['./map-layer-setting.component.scss']
})
export class MapLayerSettingComponent {

  constructor(public mapService: MapService, public authService: AuthenticationService) {
  }

}
