<div class="container">
  <div class="card">
    <div class="card-foto">
    </div>
    <div class="card-header">
      <h5><i class="fas fa-cog"></i> Raumkategorien</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="container">
          <div  *ngFor="let item of katdata;let i = index">
            <div class="row">
                <div class="col-6">
                  <input name="kategorie" type="text" class="form-control" [(ngModel)]="item.kategorie" placeholder="{{item.kategorie}}" value="{{item.kategorie}}" />
                </div>
                <div class="col-2 text-right">
                  <input [(colorPicker)]="item.farbe" class="colorpicker" [cpOutputFormat]="'hex'" [style.background]="item.farbe"/>
                </div>
                <div class="col-2 text-right">
                  <button class="btn redicon" (click)="openConfirmModal('delete',item ,i)">
                  <i class="fal fa-trash"></i>
                  </button>
                </div>
                <div class="col-2 text-right">
                  <button class="btn greenicon" (click)="openConfirmModal('save',item ,i)">
                     <i class="fal fa-save"></i>
                   </button>
                 </div>
             </div>
           </div>
         </div>
         <div class="container">
           <div class="col-12">
             <button class="btn w-100 btn-blue" (click)="addCat()">
               <i class="fal fa-plus-square"></i> Neue Kategorie hinzufügen
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
