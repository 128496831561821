import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MapService} from '@app/core/services/map.service';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {Logger} from '@app/core/services/logger/logger.service';
import {
  PerfectScrollbarComponent, PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

const log = new Logger('MapInfoWindowComponent');

@Component({
  selector: 'map-info-window',
  templateUrl: './map-info-window.component.html',
  styleUrls: ['./map-info-window.component.scss']
})
export class MapInfoWindowComponent implements AfterViewInit {

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
 //  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('directiveRef', {read: PerfectScrollbarDirective}) directiveRef: PerfectScrollbarDirective;

  constructor(public mapService: MapService, public authService: AuthenticationService) { }

  ngAfterViewInit() {
    this.mapService.directiveRef = this.componentRef.directiveRef;
    // dev test
   // this.mapService.show_user_position();
  }

}
